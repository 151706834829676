/**
 * @module SelectPaymentMethodScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useGiving from '../../../../../hooks/useGiving';
import { RadioListItem } from '../../../../RadioListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import WarningIcon from '../../../../ui/WarningIcon';
import {
  ANALYTICS,
  APPLE_PAY_PAYMENT_METHOD_OBJECT,
  GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
  PAYMENT_METHOD_TYPES,
  PAYPAL_PAYMENT_METHOD_OBJECT,
  SMART_PAY_PROVIDERS,
  STRINGS,
  calculateMonthOffset,
} from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents the HTML markup and contents for the payment method modal when set to "select" mode.
 *
 * @param {object} props - The component props object.
 * @param {object} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {Array<PaymentMethod>} [props.paymentMethods] - List of PaymentMethod objects.
 *
 * @returns {React.ReactElement} The SelectPaymentMethodScreen component.
 */
export function SelectPaymentMethodScreen({ className, paymentMethods }) {
  const { smartPayProviderData, storeUserGivingData, today, userGivingData } =
    useGiving();
  /* istanbul ignore next */
  let selectedValue = userGivingData?.paymentMethod
    ? userGivingData.paymentMethod.id
    : null;
  /* istanbul ignore next */
  if (
    !selectedValue ||
    userGivingData?.paymentMethod?.attributes.payment_method_type ===
      APPLE_PAY_PAYMENT_METHOD_OBJECT.type
  ) {
    selectedValue = APPLE_PAY_PAYMENT_METHOD_OBJECT.type;
  }
  /* istanbul ignore next */
  if (
    !selectedValue ||
    userGivingData?.paymentMethod?.attributes.payment_method_type ===
      GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type
  ) {
    selectedValue = GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type;
  }
  /* istanbul ignore next */
  if (!selectedValue && paymentMethods?.length) {
    paymentMethods?.forEach((method) => {
      if (method?.attributes?.is_default) {
        selectedValue = method?.id;
      }
    });
  }

  /**
   * Handler function for list item select of payment method.
   *
   * @param {PaymentMethod} paymentMethod - The PaymentMethod data object.
   */
  function handleExistingPaymentMethodSelect(paymentMethod) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.givingValueUpdated,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.paymentMethod,
        value: Object.keys(SMART_PAY_PROVIDERS).includes(
          paymentMethod?.attributes?.payment_method_type,
        )
          ? SMART_PAY_PROVIDERS[paymentMethod?.attributes?.payment_method_type]
              .attributes?.display_label
          : paymentMethod?.attributes?.payment_type,
      },
    });

    /* istanbul ignore next */
    storeUserGivingData({ ...userGivingData, paymentMethod });
  }

  return (
    <div className={className}>
      <div className="list">
        {
          /* istanbul ignore next */ smartPayProviderData &&
          Object.keys(smartPayProviderData).length ? (
            <>
              {smartPayProviderData.applePay ? (
                <RadioListItem
                  className="mb-16"
                  icon={
                    <PaymentIcon
                      height={42}
                      paymentMethodType={PAYMENT_METHOD_TYPES.apple_pay}
                    />
                  }
                  inputName={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  key={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  onChange={() => {
                    /* istanbul ignore next */
                    handleExistingPaymentMethodSelect(
                      APPLE_PAY_PAYMENT_METHOD_OBJECT,
                    );
                  }}
                  selectedValue={selectedValue}
                  title={
                    APPLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label
                  }
                  titleProps={{ primary: true }}
                  type="radio"
                  value={APPLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                />
              ) : null}
              {smartPayProviderData.googlePay ? (
                <RadioListItem
                  className="mb-16"
                  icon={
                    <PaymentIcon
                      height={42}
                      paymentMethodType={PAYMENT_METHOD_TYPES.google_pay}
                    />
                  }
                  inputName={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  key={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                  onChange={() => {
                    /* istanbul ignore next */
                    handleExistingPaymentMethodSelect(
                      GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
                    );
                  }}
                  selectedValue={selectedValue}
                  title={
                    GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label
                  }
                  titleProps={{ primary: true }}
                  type="radio"
                  value={GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.id}
                />
              ) : null}
            </>
          ) : null
        }
        {
          /* istanbul ignore next */ paymentMethods?.length
            ? paymentMethods.map((paymentMethod) => {
                const {
                  display_label: displayLabel,
                  expiration_label: expirationLabel,
                } = paymentMethod.attributes;
                const {
                  attributes: paymentMethodAttributes,
                  id: paymentMethodId,
                } = paymentMethod;
                const { exp_month: expMonth, exp_year: expYear } =
                  paymentMethodAttributes;
                const monthOffset =
                  expMonth !== null && expYear !== null
                    ? calculateMonthOffset({
                        endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
                        startDate: today,
                      })
                    : null;
                const expirePrefix =
                  paymentMethodStrings.select[
                    `${
                      monthOffset !== null && monthOffset < 0
                        ? 'expired'
                        : 'expires'
                    }`
                  ];
                let labelExpireClass = '';
                if (monthOffset < 0) {
                  labelExpireClass = 'error';
                } else if (monthOffset < 2) {
                  labelExpireClass = 'warning';
                }
                const icon = monthOffset < 0 ? <WarningIcon /> : null;

                return (
                  <RadioListItem
                    className={`${labelExpireClass} mb-16`}
                    description={
                      expirationLabel ? (
                        <>
                          {icon} {expirePrefix} {expirationLabel}
                        </>
                      ) : null
                    }
                    icon={
                      <PaymentIcon
                        height={42}
                        paymentMethodData={paymentMethod}
                      />
                    }
                    inputName={paymentMethodId}
                    key={paymentMethodId}
                    onChange={() => {
                      /* istanbul ignore next */
                      handleExistingPaymentMethodSelect(paymentMethod);
                    }}
                    selectedValue={selectedValue}
                    title={displayLabel}
                    titleProps={{ primary: true }}
                    type="radio"
                    value={paymentMethodId}
                  />
                );
              })
            : null
        }
        {/* If PayPal selected for new payment method, add as list item. */}
        {
          /* istanbul ignore next */ userGivingData?.paymentMethod?.attributes
            ?.display_label ===
          PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.display_label ? (
            <RadioListItem
              className="mb-16"
              icon={
                <PaymentIcon
                  height={42}
                  paymentMethodType={PAYMENT_METHOD_TYPES?.PayPal}
                />
              }
              inputName={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
              key={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
              onChange={() => {
                /* istanbul ignore next */
                handleExistingPaymentMethodSelect(PAYPAL_PAYMENT_METHOD_OBJECT);
              }}
              selectedValue={selectedValue}
              title={PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.display_label}
              titleProps={{ primary: true }}
              type="radio"
              value={PAYPAL_PAYMENT_METHOD_OBJECT?.id}
            />
          ) : null
        }
      </div>
    </div>
  );
}
