/**
 * @module ErrorBoundary
 */
import React from 'react';
import { MaintenanceOutage } from '../../views/MaintenanceOutage';
import { logError } from '../../utils';

/**
 * Represents a custom ErrorBoundary to use across the project as a way to catch
 * errors in the component tree and display <MaintenanceOutage /> as the UI.
 */
export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // Update state so the next render will show fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this, no-unused-vars
  componentDidCatch(error, info) {
    logError(error);
  }

  render() {
    // Return MaintenanceOutage component with error mode.
    if (this.state.hasError) {
      return <MaintenanceOutage mode="error" />;
    }

    // eslint-disable-next-line consistent-return
    return this.props.children;
  }
}
