/**
 * @module FundsModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useGiving from '../../../hooks/useGiving';
import { ANALYTICS, STRINGS } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { RadioListItem } from '../../RadioListItem';
import '../Modal.scss';

/**
 * Represents the modal to show a list of Funds for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {Function} props.onClose - Handler function for modal close event.
 *
 * @returns {React.ReactElement} The FundsModal component.
 */
export function FundsModal({ isOpen, onClose }) {
  const { funds: fundsStrings } = STRINGS.modals;
  const { funds, storeUserGivingData, userGivingData } = useGiving();
  const titheFunds = funds.filter((fund) => fund?.attributes['tithe?']);
  const beyondTitheFunds = funds.filter((fund) => !fund?.attributes['tithe?']);
  const selectedValue = userGivingData?.fund?.id || null;

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingFund,
        value: userGivingData?.fund?.attributes?.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Represents a radio list item populated with the specified Fund data.
   *
   * @param {object} props - The component props object.
   * @param {Fund} props.fund - The Fund data object.
   *
   * @returns {React.ReactElement} The FundListItem component.
   */
  const FundListItem = ({ fund }) => {
    return (
      <RadioListItem
        description={fund?.attributes?.description_text}
        icon={
          <img
            alt={fund?.attributes?.name}
            className="start-icon"
            src={fund?.attributes?.icon}
          />
        }
        inputName={fund?.attributes?.name?.toLowerCase().replace(' ', '-')}
        key={`${fund?.attributes?.name?.toLowerCase()?.replace(' ', '-')}`}
        onChange={(e) => {
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.givingValueUpdated,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              value: fund?.attributes.name,
            },
          });

          /* istanbul ignore next */
          e.preventDefault();
          /* istanbul ignore next */
          storeUserGivingData({ ...userGivingData, fund });
        }}
        selectedValue={selectedValue}
        title={fund?.attributes?.name}
        titleProps={{ primary: true }}
        value={fund?.id}
      />
    );
  }; // NOSONAR

  return (
    <BaseModal
      content={
        <div className="list mb-16" data-testid="funds-modal">
          {titheFunds?.map((fundItem) => {
            return <FundListItem fund={fundItem} key={fundItem?.id} />;
          })}
          <p className={['gray100', 'uppercase'].join(' ')}>
            {fundsStrings.offerings}
          </p>
          <div className="list-container">
            {beyondTitheFunds?.map((fundItem) => {
              return <FundListItem fund={fundItem} key={fundItem?.id} />;
            })}
          </div>
        </div>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={() => {
            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.done,
                screen: ANALYTICS.screens.names.givingFund,
              },
            });

            onClose();
          }}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={
            /* istanbul ignore next */
            () => {
              /* istanbul ignore next */
              callSegmentTrack({
                event: ANALYTICS.events.buttonTap,
                properties: {
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  screen: ANALYTICS.screens.names.givingFund,
                },
              });
              onClose();
            }
          }
          title={fundsStrings.title}
        />
      }
      isOpen={isOpen}
      onClose={
        /* istanbul ignore next */
        () => {
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.buttonTap,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              screen: ANALYTICS.screens.names.givingFund,
            },
          });
          onClose();
        }
      }
    />
  );
}
