/**
 * @module AppIndex
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { GivingProvider } from './context/GivingContext';
import { AuthProvider } from './context/AuthContext';
import packageJson from '../package.json';
import { CALLBACKS, loadNewRelicBrowserMonitor, loadSegment } from './utils';
import Callback from './views/Callback';
import App from './App';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

if (!Bugsnag.isStarted()) {
  Bugsnag.start({
    apiKey: process.env.BUGSNAG_API_KEY,
    appVersion: packageJson.version,
    plugins: [new BugsnagPluginReact()],
    releaseStage: process.env.NODE_ENV,
  });
}
if (process.env.SEGMENT_KEY && process.env.SEGMENT_URL) {
  /**
   * Fetch user from local storage to pass along to Segment for analytics.
   */
  const user = JSON.parse(window?.localStorage?.getItem('user_profile'));
  const pagePayload = {
    category: document?.title?.split('|')?.[0]?.trim() || '',
    name: document?.title || '',
    properties: {
      logged_in: !!user,
      path: window?.location?.pathname,
      preferred_campus: null, // User preferred campus not presently available without specific call to API to get user-specific data (such as with Web Giving).
      referrer: document?.referrer || null,
      title: document?.title || '',
      url: window?.location?.href,
      user_id: user?.['https://www.life.church/rock_person_alias_id'],
    },
  };
  loadSegment({ autoLoad: true, pagePayload });
}

const nrMonitoringScript = document.querySelector('script[data-monitoring-nr]');
if (
  Boolean(process.env.NEW_RELIC_BROWSER_MONITOR_ENABLE) &&
  !nrMonitoringScript?.length
) {
  nrMonitoringScript.innerHTML = loadNewRelicBrowserMonitor();
}

const router = createBrowserRouter([
  {
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    path: '/',
  },
  {
    element: (
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    ),
    path: '/give',
  },
  {
    element: (
      <ErrorBoundary>
        <Callback />
      </ErrorBoundary>
    ),
    path: CALLBACKS.payPal.path,
  },
  {
    element: (
      <ErrorBoundary>
        <Callback />
      </ErrorBoundary>
    ),
    path: CALLBACKS.auth0.path,
  },
]);

root.render(
  <AuthProvider>
    <GivingProvider>
      <RouterProvider router={router}></RouterProvider>
    </GivingProvider>
  </AuthProvider>,
);
