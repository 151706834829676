/**
 * @module Strings
 * @description Contains string value constant values for strings used across the project. This helps avoid hard-coding strings in each component file.
 */
import { APP_CONFIG } from './constants';

export const STRINGS = {
  auth: {
    cookies: {
      accessToken: 'access_token',
      expiresAt: 'expires_at',
    },
    errors: {
      accountDisabled:
        'Sorry, but this account has been disabled. Please contact us at help@life.church for assistance.',
      loginError:
        'Sorry, but there was an error logging you in. Please contact us at help@life.church for assistance.',
    },
    localStorageKeys: {
      accessToken: 'access_token',
      authorizeState: 'auth0-authorize-state',
      errorAlert: 'error-alert',
      expiresAt: 'expires_at',
      givingEntrance: 'giving_entrance',
      userProfile: 'user_profile',
    },
    profile: {
      avatarImgAlt: 'My Profile',
      userMetadataUrl: 'https://www.life.church/user_metadata',
    },
  },
  bibleVerses: [
    {
      reference: 'Proverbs 11:25',
      text: '"A generous person will prosper; whoever refreshes others will be refreshed."',
    },
  ],
  confirmation: {
    dismiss: {
      cta: {
        target: '_self',
      },
    },
    fallbackData: {
      onetime: {
        cta: {
          cta_action_url:
            'https://www.life.church/give?amount=42&fund=Tithe&campus=EDM&frequency=Monthly&date=1684083600',
          cta_button_title: 'Set Up Now',
          cta_card_title: 'Make your gift recurring',
          cta_dismiss_button_text: 'Done',
          cta_image_url: {
            mobile:
              'https://interactive-images.life.church/giving/Confirmation-OneTime-Mobile.jpeg',
            tablet:
              'https://interactive-images.life.church/giving/Confirmation-OneTime-Tablet.jpeg',
          },
        },
      },
      recurring: {
        cta: {
          cta_action_url:
            'https://www.life.church/webview-app/giving-confirmation-thankyou',
          cta_button_title: 'Learn More',
          cta_card_title: 'Thank you for giving!',
          cta_dismiss_button_text: 'Done',
          cta_image_url: {
            mobile:
              'https://interactive-images.life.church/giving/Confirmation-Recurring-Mobile.jpeg',
            tablet:
              'https://interactive-images.life.church/giving/Confirmation-Recurring-Tablet.jpeg',
          },
        },
      },
    },
    recurringGift: {
      body: "When you give, you're part of the story God's telling.",
      cta: {
        target: '_blank',
      },
    },
    recurringPrompt: {
      body: 'Place God first in your finances by making your giving recurring.',
      cta: {
        target: '_self',
      },
    },
  },
  error: {
    body: 'Something went wrong on loading the giving form. Please try to give later today or contact Giving Support.',
    ctas: [
      {
        href: 'mailto:giving@life.church',
        label: 'Contact Giving Support',
        target: '_self',
        variant: 'primary',
      },
      {
        href: `${APP_CONFIG.baseUrl}/giving/`,
        label: 'Back to Life.Church Site',
        target: '_self',
        variant: 'secondary',
      },
    ],
    title: 'Something Went Wrong',
  },
  footer: {
    links: {
      backToLifeChurchSite: {
        href: `${APP_CONFIG.baseUrl}/giving/`,
        label: 'Back to Life.Church site',
        title: 'Back to Life.Church site',
      },
      contactHelpTeam: {
        href: '#',
        label: 'Questions about giving?',
        title: 'Contact our Help Team',
      },
      osanoConsent: {
        href: `#`,
        label: 'Cookie Preferences',
        title: 'Cookie Preferences',
      },
      privacyPolicy: {
        href: `${APP_CONFIG.baseUrl}/privacy/`,
        label: 'Privacy Policy',
        title: 'Life.Church Privacy Policy',
      },
      termsOfUse: {
        href: `${APP_CONFIG.baseUrl}/terms/`,
        label: 'Terms of Use',
        title: 'Life.Church Terms of Use',
      },
      vulnerabilityDisclosureProgram: {
        href: `${APP_CONFIG.baseUrl}/vulnerability-disclosure-program/`,
        label: 'Vulnerability Disclosure Program',
        title: 'Life.Church Vulnerability Disclosure Program',
      },
    },
    privacyAndSecurity: 'We value your privacy and security.',
  },
  general: {
    lifeChurch: 'Life.Church',
    logOut: 'Log Out',
    signIn: 'Log In',
  },
  giveButton: {
    authenticated: {
      amountFilled: {
        fund: {
          className: 'black',
          label: 'Next: Select Fund',
          onClick: {
            modal: 'funds',
          },
        },
        location: {
          className: 'black',
          label: 'Next: Select Location',
          onClick: {
            modal: 'location',
          },
        },
        paymentMethod: {
          className: 'black',
          label: 'Next: Add Payment Method',
          onClick: {
            modal: 'paymentMethod',
          },
        },
        valid: {
          oneTime: {
            future: {
              className: 'green',
              label: 'Schedule {X} Gift',
              onClick: {
                submit: true,
              },
            },
            today: {
              className: 'green',
              label: 'Give {X} Now',
              onClick: {
                submit: true,
              },
            },
          },
          recurring: {
            future: {
              className: 'green',
              label: 'Make {X} Gift Recurring',
              onClick: {
                submit: true,
              },
            },
            today: {
              className: 'green',
              label: 'Give {X} Now & Make Recurring',
              onClick: {
                submit: true,
              },
            },
          },
        },
      },
      amountMissing: {
        className: 'black',
        label: 'To Give, Enter Amount',
        onClick: {
          fn: 'setAmountInputFocus',
        },
      },
    },
    unauthenticated: {
      amountFilled: {
        fund: {
          className: 'black',
          label: 'Next: Select Fund',
          onClick: {
            modal: 'funds',
          },
        },
        location: {
          className: 'black',
          label: 'Next: Select Location',
          onClick: {
            modal: 'location',
          },
        },
        unauthenticated: {
          className: 'black',
          label: 'Next: Log In',
          onClick: {
            fn: 'logIn',
          },
        },
      },
      amountMissing: {
        className: 'black',
        label: 'To Give, Enter Amount',
        onClick: {
          fn: 'setAmountInputFocus',
        },
      },
    },
  },
  giveForm: {
    labels: {
      frequency: 'Frequency',
      processDate: 'Process Date',
      recurring: 'Make Gift Recurring',
      today: 'Today',
    },
  },
  inputs: {
    amount: {
      className: 'giving-amount',
      currency: 'USD',
      inputMode: 'decimal',
      label: {
        className: 'currency-sign',
        value: '$',
      },
      locale: 'en',
      max: '9999999.99',
      min: '1',
      name: 'amount',
      pattern: '^\\d*(\\.\\d{0,2})?$',
      placeholder: '0',
      step: '.01',
      type: 'text',
    },
  },
  labels: {
    currentLocation: 'Current Location',
    defaultLocation: 'Select a Location',
    done: 'Done',
    givingHistory: 'Giving History',
    learnMore: 'Learn More',
    loading: 'Loading',
    myLocation: 'My Location',
    processing: 'Processing...',
    selectPaymentMethod: 'Select Payment Method',
  },
  maintenance: {
    body: "We're building the church and improving this page for you. We'll be back up as soon as possible. So check back in a few minutes.",
    cta: {
      href: `${APP_CONFIG.baseUrl}/apphelp/`,
      label: 'Get Help',
      target: '_blank',
    },
    title: "It's not you, it's us!",
  },
  modals: {
    frequency: {
      title: 'Frequency',
    },
    funds: {
      offerings: 'Offerings (Beyond the Tithe)',
      title: 'Fund',
    },
    givingHistory: {
      detail: {
        empty: {
          body: 'There was a problem retrieving the gift details.  If the problem persists, please contact us at help@life.church for assistance.',
          title: 'Gift Details not Found',
        },
        labels: {
          amount: 'Amount',
          fund: 'Fund',
          help: 'Help',
          location: 'Location',
          paymentMethod: 'Payment Method',
          processDate: 'Process Date',
        },
        title: 'Gift Details',
      },
      empty: {
        body: 'Your giving history will appear here once a gift has processed.',
        cta: {
          label: 'Give Now',
        },
        title: 'You have no Giving History',
      },
      errors: {
        downloadUrlMissing:
          'Sorry, there was a problem retrieving the download for the selected year. If the problem persists, please contact us at help@life.church for assistance.',
      },
      main: {
        labels: {
          download: 'Download',
        },
        title: 'Giving History',
      },
      'year-select': {
        title: 'Choose a Year',
      },
    },
    help: {
      listItems: [
        {
          inputName: 'giving-faqs',
          label: 'Giving FAQs',
          link: `${APP_CONFIG.baseUrl}/giving/faq/`,
          target: '_blank',
          windowAction: 'open',
        },
        {
          inputName: 'email',
          label: 'Email Giving Support',
          link: 'mailto:giving@life.church',
          target: '_self',
          windowAction: 'location',
        },
      ],
      title: 'Help',
    },
    location: {
      global: 'Global',
      lcoDescription:
        'Life.Church Online meets anywhere you are with more than 130 services each week.',
      suggested: 'Suggested',
      title: 'Location',
    },
    paymentMethod: {
      add: {
        errorHints: {
          accountNumber: 'Please enter a valid account number.',
          invalid:
            'Sorry, there was a problem submitting your form. If the problem persists, please contact us at help@life.church for assistance.',
          routingNumber: 'Please enter a valid routing number.',
        },
        financialConnectionError:
          'Sorry, there was a problem connecting and syncing your financial accounts. If the problem persists, please contact us at help@life.church for assistance.',
        instructions: {
          continueWith: 'Or continue with',
          redirectedToOtherSite: 'You will be redirected to another site',
        },
        labels: {
          accountNumber: 'Account Number',
          accountNumberExample: '000123456789',
          accountRoutingDisclaimer:
            'Find your account and routing numbers from your bank app',
          add: 'Add',
          bankAccountManual: 'Manually Add Bank Account',
          bankAccountSignIn: 'Sign in to Bank Account',
          card: 'Credit/Debit Card',
          cardNumber: 'Card Number',
          cvc: 'CVC',
          expirationDate: 'Expiration Date',
          payPal: 'PayPal',
          routingNumber: 'Routing Number',
          routingNumberExample: '123456789',
        },
        placeholders: {
          accountNumber: 'Account Number',
          cardNumber: '1212 1212 1212 1212',
          cvc: 'CVC',
          expirationDate: 'MM / YY',
          routingNumber: 'Routing Number',
        },
        title: 'Add Payment Method',
      },
      missingMethod: {
        button: {
          className: 'btn btn-tertiary black bold ml-0 mr-0',
          label: 'Missing Payment Method Option?',
          target: '_blank',
          url: 'https://lifechurch.formstack.com/forms/missing_payment_method',
        },
      },
      'new-bank': {
        title: 'Bank Account',
      },
      'new-card': {
        title: 'Add Card',
      },
      select: {
        expired: 'Expired',
        expires: 'Expires',
        title: 'Payment Method',
      },
    },
    processDate: {
      labels: {
        process: 'Process Date',
      },
      title: 'Process Date',
    },
  },
  outage: {
    body: "We are currently experiencing some issues with the giving experience. We'll be back up as soon as possible. So check back in a few minutes.",
    cta: {
      href: `${APP_CONFIG.baseUrl}/apphelp/`,
      label: 'Get Help',
      target: '_blank',
    },
    title: "It's not you, it's us!",
  },
  payPal: {
    errors: {
      checkoutUrlRetrieval:
        'Sorry, but there was an error connecting to the PayPal checkout URL. Please contact us at help@life.church for assistance.',
      createPaymentMethod:
        'Sorry, but there was an error processing PayPal for payment. Please contact us at help@life.church for assistance.',
    },
  },
  smartPay: {
    errors: {
      createPaymentMethod:
        'Sorry, but there was an error processing the payment. Please contact us at help@life.church for assistance.',
    },
  },
  validator: {
    errorMessages: {
      amount: 'Amount must be at least 1 dollar.',
      campus: 'Please make sure a campus is selected.',
      frequency: 'Please make sure a frequency is selected.',
      fund: 'Please make sure a fund is selected.',
      general:
        'We are having a problem with your donation. Please log out and try again or come back at a later time.',
      paymentDate: 'Please make sure a payment date is selected.',
      paymentMethod:
        'Please make sure your payment method is selected before proceeding.',
    },
  },
};
