/**
 * @module FrequencyModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useGiving from '../../../hooks/useGiving';
import { ANALYTICS, STRINGS, closestTwiceMonthlyDate } from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { RadioListItem } from '../../RadioListItem';
import '../Modal.scss';

/**
 * Represents the modal to show a list of Frequencies for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {Function} props.onChange - Handler function for input change event.
 * @param {Function} props.onClose - Handler function for modal close event.
 *
 * @returns {React.ReactElement} The FrequencyModal component.
 */
export function FrequencyModal({ isOpen, onChange, onClose }) {
  const { frequencies, storeUserGivingData, userGivingData } = useGiving();
  const selectedValue = userGivingData?.frequency?.id || null;
  const frequencyList = frequencies?.filter(
    (frequency) =>
      frequency?.attributes?.code
        .toLowerCase()
        .replace(' ', '')
        .replace('-', '') !== 'onetime',
  );

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingFrequency,
        value: userGivingData?.frequency?.attributes?.name,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Represents a radio list item populated with the specified Frequency data.
   *
   * @param {object} props - The component props object.
   * @param {Frequency} props.frequency - The Frequency data object.
   *
   * @returns {React.ReactElement} FrequencyListItem component.
   */
  const FrequencyListItem = ({ frequency }) => {
    return (
      <RadioListItem
        inputName={frequency?.attributes?.name.toLowerCase().replace(' ', '-')}
        key={`${frequency?.attributes?.name.toLowerCase().replace(' ', '-')}`}
        label={frequency?.attributes?.name}
        onChange={
          /* istanbul ignore next */ (e) => {
            e.preventDefault();

            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.givingValueUpdated,
              properties: {
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.frequency,
                value: frequency?.attributes?.name,
              },
            });

            const isTwiceMonthly = frequency?.id === '134';
            const storedPaymentDate = new Date(
              userGivingData.paymentDate
                ? userGivingData.paymentDate * 1000
                : new Date().getTime() / 1000,
            );
            const updatedPaymentDate =
              closestTwiceMonthlyDate(storedPaymentDate);
            storeUserGivingData({
              ...userGivingData,
              frequency,
              paymentDate: isTwiceMonthly
                ? updatedPaymentDate.getTime() / 1000
                : storedPaymentDate.getTime() / 1000,
            });

            /* istanbul ignore next */
            if (onChange && typeof onChange === 'function') {
              onChange(frequency);
            }
          }
        }
        selectedValue={selectedValue}
        value={frequency?.id}
      />
    );
  }; // NOSONAR

  return (
    <BaseModal
      content={
        <div className="list grouped mb-16" data-testid="frequency-modal">
          {frequencyList?.map((frequencyItem) => {
            return (
              <FrequencyListItem
                frequency={frequencyItem}
                key={frequencyItem?.id}
              />
            );
          })}
        </div>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={() => {
            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.done,
                screen: ANALYTICS.screens.names.givingFrequency,
              },
            });
            onClose();
          }}
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={
            /* istanbul ignore next */
            () => {
              /* istanbul ignore next */
              callSegmentTrack({
                event: ANALYTICS.events.buttonTap,
                properties: {
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  screen: ANALYTICS.screens.names.givingFrequency,
                },
              });
              onClose();
            }
          }
          title={STRINGS.modals.frequency.title}
        />
      }
      isOpen={isOpen}
      onClose={
        /* istanbul ignore next */
        () => {
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.buttonTap,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              screen: ANALYTICS.screens.names.givingFrequency,
            },
          });
          onClose();
        }
      }
    />
  );
}
