/**
 * @module AddPaymentMethodScreen
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { ListItem } from '../../../../ListItem';
import { PaymentIcon } from '../../../../PaymentIcon';
import { APP_CONFIG, STRINGS } from '../../../../../utils';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents the HTML markup and contents for the payment method modal when set to "add" mode.
 *
 * @param {object} props - The component props object.
 * @param {string} [props.className] - Optional class name to attribute to the component wrapper div element.
 * @param {boolean} [props.includePayPal] - Boolean flag denoting whether or not to include PayPal button and logic (default: true).
 * @param {Function} [props.onAddBankAccountManualClick] - Handler function for manually add bank account list item click event.
 * @param {Function} [props.onAddBankAccountSignInClick] - Handler function for sign in to add bank account list item click event.
 * @param {Function} [props.onAddCardClick] - Handler function for add card list item click event.
 * @param {Function} [props.onPayPalClick] - Handler function for PayPal button click event.
 *
 * @returns {React.ReactElement} The AddPaymentMethodScreen component.
 */
export function AddPaymentMethodScreen({
  className,
  includePayPal = true,
  onAddBankAccountManualClick,
  onAddBankAccountSignInClick,
  onAddCardClick,
  onPayPalClick,
}) {
  return (
    <div className={className}>
      <div className="list add-payment-method">
        {
          /* istanbul ignore next */ APP_CONFIG.includeFinancialConnections ? (
            <ListItem
              className="mb-16"
              data-testid="add-bank-account-payment-method"
              onClick={onAddBankAccountSignInClick}
              startIcon={<PaymentIcon height={42} paymentMethodType="ACH" />}
              title={paymentMethodStrings.add.labels.bankAccountSignIn}
              titleProps={{ primary: true }}
            />
          ) : null
        }
        <ListItem
          className="mb-16"
          onClick={onAddCardClick}
          startIcon={
            <PaymentIcon height={42} paymentMethodType="credit card" />
          }
          title={paymentMethodStrings.add.labels.card}
          titleProps={{ primary: true }}
        />
        <p className="inline-list-instructions mb-8">
          {paymentMethodStrings.add.instructions.continueWith}
        </p>
      </div>
      <div className="list grouped add-payment-method">
        {
          /* istanbul ignore next */ includePayPal ? (
            <ListItem
              onClick={onPayPalClick}
              startIcon={<PaymentIcon height={42} paymentMethodType="PayPal" />}
              title={paymentMethodStrings.add.labels.payPal}
              titleProps={{ primary: true }}
            />
          ) : null
        }
        <ListItem
          data-testid="add-bank-account-payment-method"
          onClick={onAddBankAccountManualClick}
          title={paymentMethodStrings.add.labels.bankAccountManual}
          titleProps={{ primary: true }}
        />
      </div>
    </div>
  );
}
