/**
 * @module GivingHistoryModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import { GivingDetailScreen } from './Screens/GivingDetail';
import { GivingHistoryListScreen } from './Screens/GivingHistoryList';
import { RadioList } from '../../RadioList';
import { Loading } from '../../../views/Loading';
import useGiving from '../../../hooks/useGiving';
import {
  ANALYTICS,
  APP_CONFIG,
  STRINGS,
  logError,
  sortGivingHistory,
} from '../../../utils';
import '../Modal.scss';
import './GivingHistory.scss';

/**
 * Convenience variables to store and track strings, years, and defaults.
 */
const givingHistoryStrings = STRINGS.modals.givingHistory;
const { givingHistoryStartYear } = APP_CONFIG;
const currentYear = new Date().getFullYear();
const allYears = Array.from(
  new Array(currentYear - givingHistoryStartYear + 1),
  (x, i) => parseInt(i, 10) + parseInt(givingHistoryStartYear, 10),
).reverse();
const defaultHistoryState = {};
allYears.forEach((year) => {
  defaultHistoryState[year] = {
    downloadUrl: null,
    entries: null,
    isDownloadRetrieving: false,
    isRetrieved: false,
    retrievedTimestamp: 0,
    year,
  };
});

/**
 * Represents the modal to show user Giving History content for Life.Church Web Giving.
 *
 * Note: For inner-component states which are altered via callbacks and
 * handlers from component props, Jest ignores are used as their functionality
 * have been covered in tests for the modal.
 *
 * @param {object} props - The component props object.
 * @param {History} [props.initHistoryData] - Optional object of initial history data with `data` and `year` key/value pairs.
 * @param {boolean} [props.isOpen] - Boolean flag denoting the visibility of the modal.
 * @param {('detail'|'main'|'year-select')} [props.mode] - The mode (state) of the modal (Default: 'main').
 * @param {Function} [props.onClose] - Handler function for modal close event.
 * @param {Function} [props.onHelpClick] - Handler function for help button click event.
 *
 * @returns {React.ReactElement} The GivingHistoryModal component.
 */
export function GivingHistoryModal({
  initHistoryData,
  isOpen,
  mode,
  onClose,
  onHelpClick,
}) {
  /**
   * If initial history data is legitimate, set the default history state with
   * the appropriate history data for the year, prior to using this in history
   * state for initial component render. This also sets the `initYear` variable
   * that is used to initially set state for `selectedYear` to ensure parity
   * between init data and auto-year calculation in case they differ.
   */
  let initYear;
  if (
    initHistoryData?.attributes &&
    initHistoryData?.id &&
    initHistoryData?.relationships &&
    initHistoryData?.type &&
    allYears.includes(parseInt(initHistoryData.id, 10))
  ) {
    initYear = parseInt(initHistoryData.id, 10);
    defaultHistoryState[initYear] = {
      downloadUrl: null,
      entries: sortGivingHistory(
        initHistoryData?.relationships?.donation?.data,
      ),
      isDownloadRetrieving: false,
      isRetrieved: true,
      retrievedTimestamp: new Date().getTime(),
      year: initYear,
    };
  }

  const { fetchGivingHistory, fetchGivingHistoryDownloads } = useGiving();
  const screen1ContentRef = React.useRef(null);
  const screen2ContentRef = React.useRef(null);
  const [historyAttributes, setHistoryAttributes] =
    React.useState(defaultHistoryState);
  const [historyDetailData, setHistoryDetailData] = React.useState(null);
  const [modalMode, setModalMode] = React.useState(
    ['detail', 'main', 'year-select'].includes(mode) ? mode : 'main',
  );
  const [scrollPosition, setScrollPosition] = React.useState({
    screen1: 0,
    screen2: 0,
  });
  const [selectedYear, setSelectedYear] = React.useState(
    initYear || currentYear,
  );

  /**
   * Convenience function to fetch giving history data.
   *
   * Note: Jest ignore intentional due to functions being covered in tests for
   * API and other component implementations.
   *
   * @param {number|string} year - The year of which to retrieve history.
   */
  /* istanbul ignore next */
  async function getHistoryForYear(year) {
    setHistoryAttributes((prevHistory) => {
      return {
        ...prevHistory,
        [selectedYear]: {
          ...prevHistory[selectedYear],
          isRetrieved: false,
        },
      };
    });

    try {
      const historyResult = await fetchGivingHistory({ year });
      const historyEntries = historyResult?.relationships?.donation?.data || [];
      const sortedHistory = sortGivingHistory(historyEntries);

      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [selectedYear]: {
            ...prevHistory[selectedYear],
            entries: sortedHistory || null,
            isRetrieved: true,
            retrievedTimestamp: new Date().getTime(),
            year: historyResult?.id,
          },
        };
      });
    } catch (error) {
      logError(error);
    }
  }

  /**
   * Handler function for download button click event.
   *
   * Note: Jest coverage intentionally ignored due to this being called as the
   * result of a handler, which is covered in the test main mode > handles
   * download click.
   */
  /* istanbul ignore next */
  async function handleDownloadClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.download,
        screen: ANALYTICS.screens.names.givingHistoryList,
      },
    });

    if (historyAttributes[selectedYear]?.downloadUrl) {
      return window.open(historyAttributes[selectedYear].downloadUrl, '_blank');
    }

    // Update history attributes state with truthy value while downloading.
    setHistoryAttributes((prevHistory) => {
      return {
        ...prevHistory,
        [selectedYear]: {
          ...prevHistory[selectedYear],
          isDownloadRetrieving: true,
        },
      };
    });

    try {
      const historyDownloadResult = await fetchGivingHistoryDownloads({
        year: selectedYear,
      });
      const download_url =
        historyDownloadResult?.attributes?.download_url ?? null;

      // Update state with returned URL and falsy download retrieving status.
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [selectedYear]: {
            ...prevHistory[selectedYear],
            downloadUrl: download_url,
            isDownloadRetrieving: false,
          },
        };
      });

      // If there's a download URL, open in a new window. Otherwise, log error!
      if (download_url) {
        return window.open(download_url, '_blank');
      }
      // No need to send user-intended error to Bugsnag.
      return logError(
        new Error(givingHistoryStrings.errors.downloadUrlMissing),
        {
          bugsnag: false,
          windowAlert: true,
        },
      );
    } catch (error) {
      // Update state with returned URL and falsy download retrieving status.
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [selectedYear]: {
            ...prevHistory[selectedYear],
            isDownloadRetrieving: false,
          },
        };
      });
      logError(error);
      // Call separate one for showing user-facing error.
      return logError(error, {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });
    }
  }

  /**
   * Handler function for Give Now button click event.
   *
   * Note: Jest coverage intentionally ignored due to this being called from a
   * no-giving-history scenario, and the handler invoking onClose, which is well
   * covered in other tests for the modal. Confident to say this works.
   */
  function handleGiveNowClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.giveNow,
        screen: ANALYTICS.screens.names.givingHistoryList,
      },
    });

    /* istanbul ignore next */
    onClose();
  }

  /**
   * Handler function for giving history list item click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for main mode > list item click,
   * and due to setting state var within it.
   *
   * @param {History} data - The selected history data object.
   */
  /* istanbul ignore next */
  function handleGivingHistoryListItemClick(data) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.giftDetail,
        screen: ANALYTICS.screens.names.givingHistoryList,
        value: data?.id,
      },
    });

    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
    setHistoryDetailData(data);
    setModalMode('detail');
  }

  /**
   * Handler function for modal close/navigate back event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for year-select mode > button
   * clicks > back button, and due to setting state var within it.
   */
  /* istanbul ignore next */
  function handleModalClose() {
    switch (modalMode) {
      case 'detail':
      case 'year-select':
        /* istanbul ignore next */
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.back,
            screen:
              modalMode === 'detail'
                ? ANALYTICS.screens.names.givingHistoryDetail
                : ANALYTICS.screens.names.givingHistoryFilter,
          },
        });
        setModalMode('main');
        break;
      default:
        /* istanbul ignore next */
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.close,
            screen: ANALYTICS.screens.names.givingHistoryList,
          },
        });
        onClose();
        break;
    }
  }

  /**
   * Handler function for modal footer click event.
   *
   * Note: Jest test coverage intentionally ignored due this being called from
   * the result of what is covered in the test for year-select mode > button
   * clicks > done button, and due to setting state var within it.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleModalFooterButton(event) {
    event.preventDefault();
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.done,
        screen: ANALYTICS.screens.names.givingHistoryFilter,
      },
    });
    setModalMode('main');
  }

  /**
   * Handler function for screen 1 content scroll event, which sets the scroll
   * position for the screen to be stored for ensuring accurate placement kept.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  /* istanbul ignore next */
  function handleScreen1ContentScroll() {
    const activeScrollTop = screen1ContentRef?.current?.scrollTop;
    setScrollPosition({
      screen1: activeScrollTop,
    });
  }

  /**
   * Handler function for year list select change event.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   *
   * @param {number|string} selectedValue - The selected value.
   */
  function handleYearSelectChange(selectedValue) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: selectedValue,
        screen: ANALYTICS.screens.names.givingHistoryFilter,
      },
    });

    /* istanbul ignore next */
    setSelectedYear(selectedValue);
  }

  /**
   * Handler function for year select click event.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main handler function exists.
   */
  function handleYearSelectClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.filter,
        screen: ANALYTICS.screens.names.givingHistoryList,
      },
    });

    /* istanbul ignore next */
    setModalMode('year-select');
  }

  /**
   * Represents the secondary screen to display in the modal, either the
   * GivingDetailScreen or YearSelectScreen, depending on the modal's mode.
   *
   * @returns {GivingDetailScreen|YearSelectScreen} The secondary screen component, based on the modal's mode.
   */
  const SecondaryScreen = () => {
    if (['detail', 'main'].includes(modalMode)) {
      return <GivingDetailScreen data={historyDetailData} />;
    }
    return (
      <RadioList
        className="list-container grouped"
        onChange={handleYearSelectChange}
        selectedValue={selectedYear}
        values={allYears}
      />
    );
  }; // NOSONAR

  /**
   * Single-run convenience effect to set and store provided giving history.
   *
   * Note: Jest test coverage intentionally ignored due to setting state var
   * within the component. Sufficient coverage for main effect/logic exists.
   */
  React.useEffect(() => {
    if (
      initHistoryData?.id &&
      initHistoryData?.relationships?.donation?.data &&
      allYears?.includes(parseInt(initHistoryData?.id, 10))
    ) {
      const initYearFromData = parseInt(initHistoryData.id, 10);
      const sortedHistory = sortGivingHistory(
        initHistoryData.relationships.donation.data,
      );
      /* istanbul ignore next */
      setHistoryAttributes((prevHistory) => {
        return {
          ...prevHistory,
          [initYearFromData]: {
            ...prevHistory[initYearFromData],
            entries: sortedHistory || null,
            isRetrieved: true,
            retrievedTimestamp: new Date().getTime(),
            year: initYearFromData,
          },
        };
      });
    } else if (!historyAttributes[selectedYear]?.entries) {
      getHistoryForYear(selectedYear); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect triggered with selectedYear change to fetch history, if
   * the elapsed time since initial/last retrieval for year is over configured
   * duration value. This is a total convenience integration to ensure that if
   * the user views their history, then makes a donation, then comes back to
   * look at the history, it will have been re-fetched and updated.
   */
  React.useEffect(() => {
    const nowTime = new Date().getTime();
    const selectedYearRetrievedTime =
      historyAttributes[selectedYear]?.retrievedTimestamp;
    const timeDiff = Math.floor(
      Math.abs(nowTime - selectedYearRetrievedTime) / 1000,
    );
    if (
      !historyAttributes[selectedYear]?.isRetrieved ||
      timeDiff > APP_CONFIG.givingHistoryCacheDuration
    ) {
      getHistoryForYear(selectedYear); // NOSONAR
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  /**
   * Convenience effect to set the scroll listener for the first screen.
   */
  React.useEffect(() => {
    if (screen1ContentRef.current) {
      screen1ContentRef?.current?.addEventListener(
        'scroll',
        handleScreen1ContentScroll,
      );
    }
  }, [screen1ContentRef]);

  /**
   * Convenience effect to ensure the scroll position is kept for the first
   * screen, as potential re-renders may occur with transition to screen 2.
   * While this looks nearly identical to the effect above, its dependency
   * array ensures it fires off when modal mode or scroll position changes, not
   * just when the content ref gets set (as is the case above).
   */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = scrollPosition?.screen1;
    }
  }, [modalMode, scrollPosition.screen1]);

  /**
   * Convenience effect to set scroll top to 0 when selected year changes.
   */
  React.useEffect(() => {
    if (screen1ContentRef?.current) {
      screen1ContentRef.current.scrollTop = 0;
    }
  }, [selectedYear]);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingHistory,
        value: selectedYear,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content giving-history';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName = '';

  if (['detail', 'year-select'].includes(modalMode)) {
    contentPlacementClass = 'active-2';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      content={
        <div
          className={[contentClassName, contentPlacementClass].join(' ')}
          data-testid="giving-history-modal"
        >
          {historyAttributes[selectedYear]?.isRetrieved ? (
            <div className="screen-1">
              <GivingHistoryListScreen
                contentRef={screen1ContentRef}
                history={historyAttributes}
                isDownloadRetrieving={
                  historyAttributes[selectedYear]?.isDownloadRetrieving
                }
                onDownloadClick={handleDownloadClick}
                onEntryListItemClick={handleGivingHistoryListItemClick}
                onGiveNowClick={handleGiveNowClick}
                onYearSelectClick={handleYearSelectClick}
                selectedYear={selectedYear}
              />
            </div>
          ) : (
            <Loading />
          )}

          <div className="screen-2">
            <div className="screen-content" ref={screen2ContentRef}>
              <SecondaryScreen />
            </div>
          </div>
        </div>
      }
      contentClassName={['pt-none', 'animatable', 'multi-grid'].join(' ')}
      footer={
        modalMode === 'year-select' ? (
          <StyledButton
            className="full-width ml-0 mr-0"
            onClick={handleModalFooterButton}
            variant={ButtonVariants.primary}
          >
            {STRINGS.labels.done}
          </StyledButton>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            modalMode === 'detail' ? (
              <button
                data-testid="giving-history-help-button"
                onClick={onHelpClick}
              >
                {givingHistoryStrings.detail.labels.help}
              </button>
            ) : null
          }
          onCloseClick={handleModalClose}
          title={givingHistoryStrings[modalMode].title}
        />
      }
      isOpen={isOpen}
      onClose={handleModalClose}
    />
  );
}
