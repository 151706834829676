/**
 * @module Analytics
 */

export const ANALYTICS = {
  actions: {
    addPaymentMethod: 'Add Payment Method',
    enterAmount: 'Enter Amount',
    give: 'Give',
    selectFrequency: 'Select Frequency',
    selectFund: 'Select Fund',
    selectLocation: 'Select Location',
    showKeyboard: 'Show Keyboard',
    signIn: 'Log In',
  },
  contexts: {
    guestGiving: 'Guest Giving',
    oneScreen: 'One Screen',
    oneTime: 'One Time',
    recurring: 'Recurring',
  },
  events: {
    buttonTap: 'Button Tap',
    currentLocationFound: 'Current Location Found',
    givingError: 'Giving Error',
    givingGave: 'Giving Gave',
    givingReady: 'Giving Ready',
    givingScheduled: 'Giving Scheduled',
    givingStarted: 'Giving Started',
    givingValueUpdated: 'Giving Value Updated',
    locationPermission: 'Location Permission',
    paymentMethodCreated: 'Payment Method Created',
    paymentMethodError: 'Payment Method Error',
    selectorDismissed: 'Selector Dismissed',
    selectorPresented: 'Selector Presented',
  },
  labels: {
    accountField: 'Account Field',
    add: 'Add',
    amount: 'Amount',
    approved: 'Approved',
    back: 'Back',
    bankAccountManual: 'Manually Add Bank Account',
    bankAccountSignIn: 'Sign in to Bank Account',
    close: 'Close',
    cookiePreferences: 'Cookie Preferences',
    creditDebitCard: 'Credit / Debit Card',
    date: 'Date',
    denied: 'Denied',
    done: 'Done',
    download: 'Download',
    filter: 'Filter',
    frequency: 'Frequency',
    fund: 'Fund',
    getHelp: 'Get Help',
    giftDetail: 'Gift Detail',
    giveNow: 'Give Now',
    givingDate: 'Giving Date',
    givingDynamicButton: 'Giving Dynamic Button',
    givingFrequency: 'Giving Frequency',
    givingFund: 'Giving Fund',
    givingHelp: 'Giving Help',
    givingHistory: 'Giving History',
    givingLocation: 'Giving Location',
    givingPaymentMethod: 'Giving Payment Method',
    help: 'Help',
    learnMore: 'Learn More',
    location: 'Location',
    missingPaymentMethod: 'Missing Payment Method Option?',
    paymentMethod: 'Payment Method',
    payPal: 'PayPal',
    privacyPolicy: 'Privacy Policy',
    processDate: 'Process Date',
    profile: 'Profile',
    recurringToggle: 'Recurring Toggle',
    routingNumberField: 'Routing Number Field',
    setUpRecurringGift: 'Set Up Recurring Gift',
    signIn: 'Log In',
    termsOfUse: 'Terms of Use',
    unavailable: 'Unavailable',
    vulnerabilityDisclosureProgram: 'Vulnerability Disclosure Program',
  },
  pages: {
    error: 'Error',
    givingError: 'Giving Error',
    givingForm: 'Giving Form',
    givingGave: 'Giving Gave',
    givingHelp: 'Giving Help',
    givingProcessing: 'Giving Processing',
    givingResult: 'Giving Result',
    givingScheduled: 'Giving Scheduled',
    maintenance: 'Maintenance',
    outage: 'Outage',
  },
  screens: {
    classes: {
      error: 'Error',
      maintenance: 'Maintenance',
      oneScreen: 'One Screen',
      oneTime: 'One Time',
      outage: 'Outage',
      recurring: 'Recurring',
    },
    names: {
      addBankAccount: 'Add Bank Account',
      addCreditCard: 'Add Credit Card',
      addPaymentMethod: 'Add Payment Method',
      error: 'Error',
      givingDate: 'Giving Date',
      givingForm: 'Giving Form',
      givingFrequency: 'Giving Frequency',
      givingFund: 'Giving Fund',
      givingHeader: 'Giving Header',
      givingHelp: 'Giving Help',
      givingHistoryDetail: 'Giving History Detail',
      givingHistoryFilter: 'Giving History Filter',
      givingHistoryList: 'Giving History List',
      givingLocation: 'Giving Location',
      givingPaymentMethod: 'Giving Payment Method',
      givingResult: 'Giving Result',
      maintenance: 'Maintenance',
      outage: 'Outage',
    },
  },
};
