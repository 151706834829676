/**
 * @module ListItem
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './ListItem.scss';

/**
 * Represents a List Item form control element.
 *
 * @param {object} props - The component props object.
 * @param {*} [props.actionIcon] - The action icon to include, positioned on the far right side of the component (Default: Right-facing caret arrow icon).
 * @param {string} [props.description] - Optional description (sub-text) for the component.
 * @param {endIcon} [props.endIcon] - Optional icon that will render on the right of the component, in addition to, and to the left of, the actionIcon (if visible).
 * @param {string} props.inputName - The name of the input, used as <input> element `name` attribute.
 * @param {string} [props.label] - Optional label to display next to the title.
 * @param {Function} [props.onClick] - Handler function for click events.
 * @param {showActionIcon} [props.showActionIcon] - Boolean flag denoting whether or not to show the action icon (Default: true).
 * @param {*} [props.startIcon] - Optional icon to render on the left side of the component.
 * @param {string} [props.title] - The main text title content for the component.
 * @param {TitleProps} [props.titleProps] - Optional title props to apply to the title element.
 *
 * @returns {React.ReactElement} The ListItem component.
 */
export function ListItem({
  actionIcon,
  description,
  endIcon,
  inputName,
  label,
  onClick = () => {},
  showActionIcon = true,
  startIcon,
  title,
  titleProps = { primary: false },
  ...passThroughProps
}) {
  /**
   * Note: With the addition of passThroughProps (i.e. `rest`), it allows the
   * component to get and use additional props, such as `className`. As such,
   * use a dynamically-built list of class names, based on the standard for the
   * component, but adding in additionally-provided values as well.
   */
  const wrapperClass = [
    'form-control',
    'list-item',
    passThroughProps?.className || '',
  ].join(' ');

  return (
    <div
      className={wrapperClass}
      data-testid="list-item"
      id={passThroughProps.id || null}
    >
      <div className="label-wrap">
        {startIcon || null}
        {label ? <div className="label-text">{label}</div> : null}
        <div className="text text-container">
          {title ? (
            <div
              className={[
                titleProps?.primary ? 'primary' : '',
                'title-text',
              ].join(' ')}
            >
              {title}
            </div>
          ) : null}
          {description ? (
            <div className="description-text">{description}</div>
          ) : null}
        </div>
        {endIcon || null}
        {showActionIcon ? (
          <div className="icon-wrapper">
            {actionIcon || <div className="action-icon"></div>}
          </div>
        ) : null}
      </div>
      <button
        className="action-button"
        name={inputName}
        onClick={onClick}
      ></button>
    </div>
  );
}
export default ListItem;
