/* eslint-disable react/jsx-boolean-value */
/**
 * @module ProcessDateModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import DatePicker from 'react-datepicker';
import {
  ANALYTICS,
  STRINGS,
  calculateDaysOffset,
  closestTwiceMonthlyDate,
  relativeDateLabel,
} from '../../../utils';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import useGiving from '../../../hooks/useGiving';
import 'react-datepicker/dist/react-datepicker.css';
import '../Modal.scss';
import './ProcessDate.scss';

/**
 * Represents the modal to show Process Date and calendar picker content for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {Function} props.onClose - Handler function for modal close event.
 *
 * @returns {React.ReactElement} The ProcessDateModal component.
 */
export function ProcessDateModal({ isOpen, onClose }) {
  const { processDate: processDateStrings } = STRINGS.modals;
  const { giveForm: giveFormStrings } = STRINGS;
  const { storeUserGivingData, today, userGivingData } = useGiving();

  // Confirm stored payment date is not in the past. If it is, it needs to be
  // adjusted and re-set to today.
  /* istanbul ignore next */
  if (
    userGivingData?.paymentDate &&
    today.getTime() > userGivingData.paymentDate * 1000
  ) {
    storeUserGivingData({
      ...userGivingData,
      paymentDate: today.getTime() / 1000,
    });
  }

  const twiceMonthly = userGivingData?.frequency?.id === '134';
  const day = new Date(
    /* istanbul ignore next */
    userGivingData.paymentDate ? userGivingData.paymentDate * 1000 : new Date(),
  );
  const firstOrFifteenth = closestTwiceMonthlyDate(day);

  /**
   * Convenience effect to check and store user data when twice monthly set.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    if (twiceMonthly && ![1, 15].includes(day.getDate())) {
      storeUserGivingData({
        ...userGivingData,
        paymentDate: firstOrFifteenth.getTime() / 1000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    const formattedDate = day.toISOString().split('T')[0]; // Outputs to YYYY-MM-DD format.
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.givingDate,
        value: formattedDate,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BaseModal
      content={
        <>
          <div className="list process-date" data-testid="process-date-modal">
            <DatePicker
              inline
              minDate={new Date()}
              onChange={
                /* istanbul ignore next */
                (newDate) => {
                  let updatedProcessDate = newDate;
                  if (twiceMonthly && ![1, 15].includes(newDate.getDate())) {
                    // Calculate next 1st or 15th and set to that date.
                    updatedProcessDate = closestTwiceMonthlyDate(newDate);
                  }
                  const formattedDate = updatedProcessDate
                    .toISOString()
                    .split('T')[0]; // Outputs to YYYY-MM-DD format.
                  const offset = calculateDaysOffset({
                    endDate: updatedProcessDate,
                    startDate: today,
                  });
                  /* istanbul ignore next */
                  callSegmentTrack({
                    event: ANALYTICS.events.givingValueUpdated,
                    properties: {
                      context: ANALYTICS.contexts.oneScreen,
                      label: ANALYTICS.labels.date,
                      offset: Math.abs(offset),
                      value: formattedDate,
                    },
                  });

                  /* istanbul ignore next */
                  storeUserGivingData({
                    ...userGivingData,
                    paymentDate: updatedProcessDate.getTime() / 1000,
                  });
                }
              }
              selected={
                /* istanbul ignore next */
                day
              }
              useWeekdaysShort={true}
            />
          </div>
          <div className="process">
            <p className="process-text">
              {processDateStrings.labels.process}
              {': '}
              {
                /* istanbul ignore next */
                !today
                  ? giveFormStrings.labels.today
                  : relativeDateLabel({
                      date: userGivingData ? day : today,
                      relativeDate: today,
                    })
              }
            </p>
          </div>
        </>
      }
      contentClassName="pt-none"
      footer={
        <StyledButton
          className="full-width"
          onClick={
            /* istanbul ignore next */
            () => {
              /* istanbul ignore next */
              callSegmentTrack({
                event: ANALYTICS.events.buttonTap,
                properties: {
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.done,
                  screen: ANALYTICS.screens.names.givingDate,
                },
              });
              onClose();
            }
          }
          variant={ButtonVariants.primary}
        >
          {STRINGS.labels.done}
        </StyledButton>
      }
      header={
        <ModalHeader
          onCloseClick={
            /* istanbul ignore next */
            () => {
              /* istanbul ignore next */
              callSegmentTrack({
                event: ANALYTICS.events.buttonTap,
                properties: {
                  context: ANALYTICS.contexts.oneScreen,
                  label: ANALYTICS.labels.close,
                  screen: ANALYTICS.screens.names.givingDate,
                },
              });
              onClose();
            }
          }
          title={processDateStrings.title}
        />
      }
      isOpen={isOpen}
      onClose={
        /* istanbul ignore next */
        () => {
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.buttonTap,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              screen: ANALYTICS.screens.names.givingDate,
            },
          });
          onClose();
        }
      }
    />
  );
}
