/**
 * @module App
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { getSearchParamsFrom } from '@io/web-tools-io/dist/utils/helpers/utmParams';
import {
  ButtonSizes,
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';

import {
  callSegmentPage,
  callSegmentTrack,
} from '@io/web-tools-io/dist/utils/helpers/analytics';
import ToggleSwitch from '@io/web-tools-io/dist/components/global/ToggleSwitch';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SmoothCollapse from 'react-smooth-collapse';
import LifeChurchLogoHorizontal from './assets/svg/lc-logo-horizontal.svg';
import Loading from './assets/svg/loading.svg';
import {
  createPaymentMethod,
  getPayPalCheckoutUrl,
  postDonation,
  postLoggedOutDonation,
} from './api/giving';
import { Amount } from './components/Amount';
import { Footer } from './components/Footer';
import Header from './components/Header';
import { ListItem } from './components/ListItem';
import { PaymentIcon } from './components/PaymentIcon';
import { FrequencyModal } from './components/Modals/FrequencyModal';
import { FundsModal } from './components/Modals/FundsModal';
import { GivingHistoryModal } from './components/Modals/GivingHistoryModal';
import { HelpModal } from './components/Modals/HelpModal';
import { LocationModal } from './components/Modals/LocationModal';
import { PaymentMethodModal } from './components/Modals/PaymentMethodModal';
import { ProcessDateModal } from './components/Modals/ProcessDateModal';
import { Confirmation } from './views/Confirmation';
import { MaintenanceOutage } from './views/MaintenanceOutage';
import { Processing } from './views/Processing';
import useAuth from './hooks/useAuth';
import useGiving from './hooks/useGiving';
import RecurringPaymentIcon from './components/ui/RecurringPaymentIcon';
import WarningIcon from './components/ui/WarningIcon';
import {
  ANALYTICS,
  APPLE_PAY_PAYMENT_METHOD_OBJECT,
  APP_CONFIG,
  GOOGLE_PAY_PAYMENT_METHOD_OBJECT,
  LOG_CONFIG,
  MODAL_MODES,
  PAYMENT_METHOD_TYPES,
  PAYPAL_MODES,
  PAYPAL_PAYMENT_METHOD_OBJECT,
  SMART_PAY_PROVIDERS,
  STRINGS,
  STRIPE_EVENTS,
  TOKENIZATION_METHODS,
  TYPES,
  calculateDaysOffset,
  calculateMonthOffset,
  formatNumberAsCurrency,
  logError,
  relativeDateLabel,
  validateGivingFormData,
} from './utils';
import './styles/main.scss';
import './components/Modals/Modal.scss';

/**
 * The App view component.
 *
 * @returns {React.ReactElement} - The App view component.
 */
const App = () => {
  const {
    getAccessToken,
    isAuthenticated,
    isUserChecked,
    logIn,
    logOut,
    user,
  } = useAuth();
  const {
    addFormError,
    apiStatus,
    campuses,
    calculateGiveButtonData,
    checkPaymentDate,
    defaultFrequency,
    fetchGivingData,
    frequencies,
    funds,
    geolocationData,
    getPreferredCampus,
    getProcessingBibleVerse,
    giveButtonData,
    isAndroid,
    isApiDataRetrieved,
    isRecurringFrequency,
    paymentMethods,
    resetFormErrors,
    resetFormStatus,
    resetUserGivingData,
    smartPayProviderData,
    storeSmartPayProviderData,
    storeSmartPayUserData,
    storeUserGivingData,
    today,
    updateFormStatus,
    userGivingData,
    userPosition,
  } = useGiving();
  const modalBackdropRef = React.useRef();
  const defaultModalTiming = { dismissed: 0, presented: 0 };
  const [isDonationComplete, setIsDonationComplete] = React.useState(false);
  const [isGivingReadyCalled, setIsGivingReadyCalled] = React.useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = React.useState(false);
  const { giveButton: giveBtnStrings, giveForm: giveFormStrings } = STRINGS;
  const [isLocationCurrentLocation, setIsLocationCurrentLocation] =
    React.useState(false);
  const [isParamsParsed, setIsParamsParsed] = React.useState(false);
  const [isRedirecting, setIsRedirecting] = React.useState(false);
  const [isPayPalRedirect, setIsPayPalRedirect] = React.useState(false);
  const [isCanMakePaymentChecked, setIsCanMakePaymentChecked] =
    React.useState(false);
  const [isSmartPayAvailabilityChecked, setIsSmartPayAvailabilityChecked] =
    React.useState(false);
  const [analyticsSession, setAnalyticsSession] = React.useState({
    ga: { isComplete: false, isStarted: false, user: null },
    segment: { isComplete: false, isStarted: false, user: null },
  });
  const [frequencyLabel, setFrequencyLabel] = React.useState(
    userGivingData?.frequency?.attributes?.name ||
      defaultFrequency.attributes.name,
  );
  const [paymentMethodDescription, setPaymentMethodDescription] =
    React.useState(null);
  const [paymentMethodTitle, setPaymentMethodTitle] = React.useState(
    userGivingData?.paymentMethod?.attributes?.display_label ||
      STRINGS.labels.selectPaymentMethod,
  );
  const [
    paymentMethodListItemExpireStyle,
    setPaymentMethodListItemExpireStyle,
  ] = React.useState('default');
  const [, setStripeObject] = React.useState(null);
  const [stripePaymentRequest, setStripePaymentRequest] = React.useState(null);
  const [stripePromise, setStripePromise] = React.useState(null);
  const bibleVerse = getProcessingBibleVerse();

  const [modalParams, setModalParams] = React.useState({});
  const [, setQueryParams] = React.useState({});
  const [, setUtmParams] = React.useState({});

  const includeOsanoConsentManager =
    process.env.OSANO_CONSENT_MANAGER_ACCOUNT_ID &&
    process.env.OSANO_CONSENT_MANAGER_CONFIG_ID &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER &&
    process.env.OSANO_INCLUDE_CONSENT_MANAGER.toString() === 'true';

  // PayPal integration status.
  const includePayPal =
    process.env.INCLUDE_PAYPAL &&
    process.env.INCLUDE_PAYPAL.toString() === 'true';
  const payPalMode = process.env.PAYPAL_MODE || PAYPAL_MODES.expressCheckout;

  /**
   * Convenience variable to provide modal id values.
   * Note: modal queryParam values are all-lowercase, character-only values so
   * logic can better handle varying cases, space/hyphen/underscore usage, etc.
   */
  const modals = Object.freeze({
    frequency: {
      id: 'frequency',
      isAuthRequired: false,
      queryParams: ['frequency', 'frequencies'],
    },
    funds: {
      id: 'funds',
      isAuthRequired: false,
      queryParams: ['fund', 'funds'],
    },
    givingHistory: {
      id: 'givingHistory',
      isAuthRequired: true,
      queryParams: ['givinghistory'],
    },
    help: {
      id: 'help',
      isAuthRequired: false,
      queryParams: ['help'],
    },
    location: {
      id: 'location',
      isAuthRequired: false,
      queryParams: ['campus', 'campuses', 'location', 'locations'],
    },
    paymentMethod: {
      id: 'paymentMethod',
      isAuthRequired: true,
      queryParams: ['addpaymentmethod', 'paymentmethod', 'paymentmethods'],
    },
    processDate: {
      id: 'processDate',
      isAuthRequired: false,
      queryParams: ['paymentdate', 'processdate'],
    },
  });

  /**
   * Convenience variable to provide supported query params (not including UTM).
   */
  const supportedQueryParams = Object.freeze({
    amount: {
      type: 'string',
    },
    campus: {
      key: 'code',
      source: campuses,
      type: 'object',
    },
    frequency: {
      key: 'code',
      source: frequencies,
      type: 'object',
    },
    fund: {
      key: 'code',
      source: funds,
      type: 'object',
    },
    modal: {
      key: 'modal',
      source: modals,
      type: 'object',
    },
    token: {
      type: 'string',
    },
    year: {
      type: 'string',
    },
  });

  /**
   * Convenience state to track visibility of modals, set as object containing
   * data for modals, including eventual `include` and `isOpen` booleans.
   */
  const [modalStateData, setModalStateData] = React.useState({
    frequency: modals.frequency,
    funds: modals.funds,
    givingHistory: modals.givingHistory,
    help: modals.help,
    location: modals.location,
    paymentMethod: modals.paymentMethod,
    processDate: modals.processDate,
  });

  /**
   * Convenience state to store and track modal timing, used for analytics.
   * When a modal is presented, it will be added/updated here as a key, set to
   * an object for `presented` and `dismissed`, which are ultimately used to
   * calculate the total time a user had the modal open.
   */
  const [modalTiming, setModalTiming] = React.useState({
    frequency: defaultModalTiming,
    funds: defaultModalTiming,
    givingHistory: defaultModalTiming,
    help: defaultModalTiming,
    location: defaultModalTiming,
    paymentMethod: defaultModalTiming,
    processDate: defaultModalTiming,
  });

  /**
   * Convenience effect to fetch giving data when user is authenticated, to
   * allow up-to-date user-based data.
   */
  React.useEffect(() => {
    if (isAuthenticated && user && apiStatus.isAvailable) {
      fetchGivingData({
        callback: () => {
          checkPaymentDate();
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiStatus.isAvailable, isAuthenticated, user]);

  /**
   * Single-run convenience effect to configure BrowserLogger.
   */
  React.useEffect(() => {
    Log.configure(LOG_CONFIG);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience function to handle modal visibility events.
   *
   * @param {string} id - Unique modal ID value.
   */
  function handleModalVisibility({ isOpen, modalId }) {
    if (modalId) {
      setModalStateData((prevData) => {
        return {
          ...prevData,
          [modalId]: {
            ...prevData[modalId],
            isOpen,
          },
        };
      });
    }

    if (modalId) {
      setModalTiming((prevModalTiming) => {
        return {
          ...prevModalTiming,
          [modalId]: {
            dismissed: null,
            presented: new Date().getTime(),
          },
        };
      });
    }
    if (!isOpen) {
      setTimeout(() => {
        setModalStateData((prevData) => {
          return {
            ...prevData,
            [modalId]: {
              ...prevData[modalId],
              include: isOpen,
            },
          };
        });
      }, 175); // Slightly shorter than SCSS-defined animation duration to ensure state updates w/o flicker.
    } else {
      setModalStateData((prevData) => {
        return {
          ...prevData,
          [modalId]: {
            ...prevData[modalId],
            include: isOpen,
          },
        };
      });
    }
  }

  /**
   * Handler function for modal close events. Note that this function requires
   * and uses a modal `id` value to set the modal timing `dismissed` property,
   * and calculate total modal visible time from it compared with the modal's
   * property for `presented` and tracks the analytics event accordingly. It
   * also triggers the `handleModalVisibility` function with no argument, which
   * effectively closes the modal.
   *
   * @param {string} id - Unique modal ID value.
   */
  function handleModalClose(id) {
    const dismissedTime = new Date().getTime();
    let modalLabel = null;
    let modalValue = null;
    switch (id) {
      case modals.frequency.id:
        modalLabel = ANALYTICS.labels.givingFrequency;
        modalValue = userGivingData?.frequency?.attributes?.name;
        break;
      case modals.funds.id:
        modalLabel = ANALYTICS.labels.givingFund;
        modalValue = userGivingData?.fund?.attributes?.name;
        break;
      case modals.givingHistory.id:
        modalLabel = ANALYTICS.labels.givingHistory;
        break;
      case modals.help.id:
        modalLabel = ANALYTICS.labels.givingHelp;
        break;
      case modals.location.id:
        modalLabel = ANALYTICS.labels.givingLocation;
        modalValue = userGivingData?.campus?.attributes?.name;
        break;
      case modals.paymentMethod.id:
        modalLabel = ANALYTICS.labels.givingPaymentMethod;
        modalValue = Object.keys(SMART_PAY_PROVIDERS).includes(
          userGivingData?.paymentMethod?.attributes?.payment_method_type,
        )
          ? SMART_PAY_PROVIDERS[
              userGivingData?.paymentMethod?.attributes?.payment_method_type
            ].attributes?.display_label
          : userGivingData?.paymentMethod?.attributes?.payment_type;
        break;
      case modals.processDate.id:
        modalLabel = ANALYTICS.labels.givingDate;
        [modalValue] = new Date(userGivingData.paymentDate * 1000 || null)
          .toISOString()
          .split('T'); // Outputs to YYYY-MM-DD format.
        break;
      default:
        break;
    }

    setModalTiming((prevModalTiming) => {
      return {
        ...prevModalTiming,
        [id]: {
          ...prevModalTiming[id],
          dismissed: dismissedTime,
        },
      };
    });

    const segmentProps = {
      context: ANALYTICS.contexts.oneScreen,
      Duration: Math.abs(
        dismissedTime - (modalTiming[id] ? modalTiming[id].presented : 0),
      ),
      label: modalLabel,
      value: modalValue,
    };
    if (id === modals.paymentMethod.id) {
      segmentProps.Count = paymentMethods?.length;
    }

    callSegmentTrack({
      event: ANALYTICS.events.selectorDismissed,
      properties: segmentProps,
    });

    handleModalVisibility({ isOpen: false, modalId: id });
  }

  /**
   * Handler function for Smart Pay payment request cancel event.
   */
  const handleSmartPayCancel = React.useCallback(() => {
    setIsFormSubmitting(false);
    // eslint-disable-next-line no-use-before-define
    stripePaymentRequest.on('token', () => {});
    // eslint-disable-next-line no-use-before-define
    stripePaymentRequest.on('cancel', () => {});
  }, [stripePaymentRequest]);

  /**
   * Handler function for Smart Pay payment request token creation event.
   */
  const handleSmartPayTokenCreated = React.useCallback(
    async (event) => {
      if (event?.token) {
        const { methodName, payerEmail, payerName, payerPhone, token } = event;
        const { card, id: tokenId } = token;
        const {
          address_city,
          address_country,
          address_line1,
          address_state,
          address_zip,
          last4,
          name: cardPayerName,
        } = card;
        let formattedPhone = payerPhone ? payerPhone.replace(/\D/g, '') : null;
        if (formattedPhone && formattedPhone.length >= 10) {
          formattedPhone = formattedPhone.substring(formattedPhone.length - 10);
        }
        if (
          !isAuthenticated &&
          APP_CONFIG.includeLoggedOutDonation &&
          !geolocationData.cpa
        ) {
          let tokenizationMethod = methodName
            ? methodName.replace('-', '_')
            : null;
          /**
           * Note: In testing and upgrading Giving API from v2 to v3, some of
           * the tests resulted in Google Pay tokenization method coming in as
           * full URL (https://google.com/pay) rather than `google-pay`. As such
           * the above, already-in-place replace of `-` with `_` does nothing,
           * and there is need to search for key word of smart pay provider and
           * set tokenizationMethod manually.
           */
          if (
            !Object.values(TOKENIZATION_METHODS).includes(tokenizationMethod)
          ) {
            if (tokenizationMethod.indexOf('google') >= 0) {
              tokenizationMethod = TOKENIZATION_METHODS.googlePay;
            } else if (tokenizationMethod.indexOf('apple') >= 0) {
              tokenizationMethod = TOKENIZATION_METHODS.applePay;
            }
          }

          /**
           * Note: For some reason, using `street` or `street1` for both of
           * the Smart Pay providers doesn't work, yet using just `street` for
           * Apple Pay, while using `street1` for Google Pay does seem to work.
           * While it is unknown exactly why this is, the present solution is to
           * use the different keys until a time (if ever) when they are equal.
           */
          const streetKey =
            tokenizationMethod === 'apple_pay' ? 'street' : 'street1';
          const finalData = {
            address: {
              city: address_city ?? '',
              country: address_country ?? '',
              postal_code: address_zip ?? '',
              state: address_state ?? '',
              [streetKey]: address_line1 ?? '',
            },
            amount: userGivingData?.amount,
            campus: userGivingData?.campus?.attributes?.code,
            currency: 'USD',
            email: payerEmail ?? '',
            frequency: userGivingData?.frequency?.attributes?.code,
            fund: userGivingData?.fund?.attributes?.code,
            last4: last4 ?? '',
            name: cardPayerName ?? payerName ?? '',
            paymentDate: userGivingData?.paymentDate,
            paymentMethod: null,
            paymentToken: tokenId,
            smsPhoneNumber: formattedPhone ?? '',
            tokenizationMethod,
          };

          storeSmartPayUserData(finalData);
          event.complete(STRIPE_EVENTS.complete.success);

          // Simulate button click, but with submit and paymentMethod overrides.
          // eslint-disable-next-line no-use-before-define
          handleGiveButtonClick(null, {
            event,
            smartPayUserData: finalData,
            submit: true,
          });
        } else {
          try {
            const result = await createPaymentMethod({
              accessToken: getAccessToken(),
              paymentToken: tokenId,
            });
            const paymentMethod = result.data;
            storeUserGivingData({
              paymentMethod,
            });
            event.complete(STRIPE_EVENTS.complete.success);

            // Simulate button click, but with submit and paymentMethod overrides.
            // eslint-disable-next-line no-use-before-define
            handleGiveButtonClick(null, { paymentMethod, submit: true });
          } catch (error) {
            event.complete(STRIPE_EVENTS.complete.fail);
            logError(error);
            logError(new Error(STRINGS.smartPay.errors.createPaymentMethod), {
              browserConsole: true,
              bugsnag: false,
              windowAlert: true,
            });
          }
        }
      }
    },
    [
      geolocationData,
      getAccessToken,
      // eslint-disable-next-line no-use-before-define
      handleGiveButtonClick,
      isAuthenticated,
      storeSmartPayUserData,
      storeUserGivingData,
      userGivingData,
    ],
  ); // NOSONAR

  /**
   * Handler function for Give Button click event.
   *
   * @param {Event} event - The Event object associated with the button click.
   * @param {Event} overrides - Optional boolean override flags that will attempt to submit, force payment method, etc.
   */
  const handleGiveButtonClick = React.useCallback(
    async (event, overrides = {}) => {
      if (event) {
        event.preventDefault();
      }

      // Retrieve data from giveButtonData and check for action to perform.
      const { fn, modal, submit } = giveButtonData.onClick;
      if (submit || overrides?.submit) {
        // Reset form errors prior to (re-)validation.
        resetFormErrors();
        // Trigger data validation and inspect/handle results.
        const userGivingDataErrors = validateGivingFormData(userGivingData);
        if (userGivingDataErrors?.length) {
          setIsFormSubmitting(false);
          userGivingDataErrors.forEach((formError) => {
            addFormError(formError.field, new Error(formError.message));
          });
          updateFormStatus({
            hasSubmitted: true,
            submitSuccess: false,
          });

          callSegmentTrack({
            event: ANALYTICS.events.givingError,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              error: userGivingDataErrors[0].message,
              screen: ANALYTICS.screens.names.givingForm,
            },
          });

          // No need to send user-intended error to Bugsnag.
          logError(new Error(userGivingDataErrors[0].message), {
            bugsnag: false,
            windowAlert: true,
          });
        } else if (
          [
            PAYMENT_METHOD_TYPES.apple_pay,
            PAYMENT_METHOD_TYPES.google_pay,
          ].includes(userGivingData?.paymentMethod?.type) &&
          !overrides?.submit
        ) {
          if (stripePaymentRequest && !stripePaymentRequest.isShowing()) {
            const amountValue = !Number.isNaN(
              parseInt(
                Math.round(parseFloat(userGivingData?.amount || 0) * 100),
                10,
              ),
            )
              ? parseInt(
                  Math.round(parseFloat(userGivingData?.amount || 0) * 100),
                  10,
                )
              : 0;
            stripePaymentRequest.update({
              total: {
                amount: amountValue, // Amount in cents.
                label: `${
                  formatNumberAsCurrency({
                    number: userGivingData?.amount || '0',
                  }).display || '0'
                }`,
              },
            });
            stripePaymentRequest.on('token', handleSmartPayTokenCreated);
            stripePaymentRequest.on('cancel', handleSmartPayCancel);
            stripePaymentRequest.show();
          }
        } else if (
          userGivingData.paymentMethod.type === PAYMENT_METHOD_TYPES.PayPal &&
          !overrides?.submit
        ) {
          setIsFormSubmitting(true);
          try {
            /**
             * Note: With PayPal, a successful retrieval of Checkout URL will auto
             * trigger redirect to it, and the return and cancel URLs will give
             * the necessary redirects back to this app. The <Callback /> view is
             * set to be the handler for return redirect after agreeing and paying
             * and includes logic to create payment method and trigger the API
             * call to post donation.
             */
            const payPalUrlResponse = await getPayPalCheckoutUrl({
              accessToken: getAccessToken(),
              amount: userGivingData?.amount,
              campus: userGivingData?.campus?.attributes?.code,
              fund: userGivingData?.fund?.attributes?.code,
              // eslint-disable-next-line no-restricted-globals
              payPalCancelUrl: `${parent.location.origin}${parent.location.pathname}`,
              // eslint-disable-next-line no-restricted-globals
              payPalReturnUrl: `${parent.location.origin}${process.env.PAYPAL_RETURN_URL}`,
            });
            const checkoutUrl = payPalUrlResponse?.result;
            if (!checkoutUrl) {
              // Calling with explicit error message in absence of error object.
              logError(new Error(STRINGS.payPal.errors.checkoutUrlRetrieval), {
                browserConsole: false,
                bugsnag: false,
                windowAlert: true,
              });
            } else {
              window.open(checkoutUrl, '_self');
            }
          } catch (error) {
            setIsFormSubmitting(false);
            logError(error);

            // Separately calling logError for error to ONLY show to user.
            logError(new Error(STRINGS.payPal.errors.checkoutUrlRetrieval), {
              browserConsole: false,
              bugsnag: false,
              windowAlert: true,
            });
            updateFormStatus({
              hasSubmitted: true,
              submitSuccess: false,
            });
          }
        } else {
          setIsFormSubmitting(true);
          try {
            const finalPaymentMethod =
              overrides?.paymentMethod || userGivingData?.paymentMethod;
            let result;
            if (
              !isAuthenticated &&
              overrides?.smartPayUserData &&
              APP_CONFIG.includeLoggedOutDonation &&
              !geolocationData.cpa
            ) {
              result = await postLoggedOutDonation({
                ...overrides?.smartPayUserData,
                allowNull: true,
              });
            } else {
              result = await postDonation({
                accessToken: getAccessToken(),
                amount: userGivingData?.amount,
                campus: userGivingData?.campus?.attributes?.code,
                frequency: userGivingData?.frequency?.attributes?.code,
                fund: userGivingData?.fund?.attributes?.code,
                paymentDate: userGivingData?.paymentDate,
                paymentMethod: finalPaymentMethod?.id,
              });
            }
            if (result?.data) {
              const donationDate = new Date(
                userGivingData.paymentDate * 1000 || null,
              );
              const donationDateOffset = calculateDaysOffset({
                endDate: donationDate,
                startDate: today,
              });
              const isDonationRecurring =
                userGivingData?.frequency?.attributes?.code
                  .toLowerCase()
                  .replace(' ', '')
                  .replace('-', '') !== 'onetime';
              const isDonationScheduled = donationDateOffset > 0;
              const preferredCampus = getPreferredCampus();

              if (overrides?.event?.complete) {
                overrides?.event.complete(STRIPE_EVENTS.complete.success);
              }

              const userPaymentMethods = [
                ...new Set(
                  Array.from(
                    paymentMethods,
                    (paymentMethod) =>
                      paymentMethod.attributes.payment_method_type,
                  ),
                ),
              ];
              if (smartPayProviderData?.applePay) {
                userPaymentMethods.push(
                  SMART_PAY_PROVIDERS.apple_pay.attributes.display_label,
                );
              }
              if (smartPayProviderData?.googlePay) {
                userPaymentMethods.push(
                  SMART_PAY_PROVIDERS.google_pay.attributes.display_label,
                );
              }

              callSegmentTrack({
                event: ANALYTICS.events.givingGave,
                properties: {
                  amount: userGivingData?.amount,
                  campus: userGivingData?.campus?.attributes?.code,
                  context: ANALYTICS.contexts.oneScreen,
                  currency: 'USD',
                  frequency: userGivingData?.frequency?.attributes?.name,
                  fund: userGivingData?.fund?.attributes?.name,
                  logged_in: isAuthenticated,
                  payment_method: Object.keys(SMART_PAY_PROVIDERS).includes(
                    finalPaymentMethod?.attributes?.payment_method_type,
                  )
                    ? SMART_PAY_PROVIDERS[
                        finalPaymentMethod?.attributes?.payment_method_type
                      ].attributes?.display_label
                    : finalPaymentMethod?.attributes?.payment_method_type,
                  payment_method_detail: Object.keys(
                    SMART_PAY_PROVIDERS,
                  ).includes(
                    finalPaymentMethod?.attributes?.payment_method_type,
                  )
                    ? SMART_PAY_PROVIDERS[
                        finalPaymentMethod?.attributes?.payment_method_type
                      ].attributes?.display_label
                    : finalPaymentMethod?.attributes?.display_label,
                  payment_method_id: finalPaymentMethod?.id,
                  payment_methods: userPaymentMethods?.length
                    ? userPaymentMethods.toString()
                    : null,
                  preferred_campus: preferredCampus?.attributes?.code,
                  referrer: document?.referrer || null,
                  schedule_start_date: donationDate.toISOString().split('T')[0], // Outputs to YYYY-MM-DD format.
                  schedule_start_date_offset: donationDateOffset,
                  scheduled: isDonationRecurring || isDonationScheduled,
                  title: document?.title || '',
                  transaction_id: result?.data?.attributes?.transaction_id,
                  url: window?.location?.href,
                  user_id: user?.[TYPES.user.lcRockPersonAliasId],
                },
              });

              if (isDonationRecurring || isDonationScheduled) {
                callSegmentTrack({
                  event: ANALYTICS.events.givingScheduled,
                  properties: {
                    amount: userGivingData?.amount,
                    campus: userGivingData?.campus?.attributes?.code,
                    context: ANALYTICS.contexts.oneScreen,
                    currency: 'USD',
                    frequency: userGivingData?.frequency?.attributes?.name,
                    fund: userGivingData?.fund?.attributes?.name,
                    logged_in: isAuthenticated,
                    payment_method: Object.keys(SMART_PAY_PROVIDERS).includes(
                      finalPaymentMethod?.attributes?.payment_method_type,
                    )
                      ? SMART_PAY_PROVIDERS[
                          finalPaymentMethod?.attributes?.payment_method_type
                        ].attributes?.display_label
                      : finalPaymentMethod?.attributes?.payment_method_type,
                    payment_method_detail: Object.keys(
                      SMART_PAY_PROVIDERS,
                    ).includes(
                      finalPaymentMethod?.attributes?.payment_method_type,
                    )
                      ? SMART_PAY_PROVIDERS[
                          finalPaymentMethod?.attributes?.payment_method_type
                        ].attributes?.display_label
                      : finalPaymentMethod?.attributes?.display_label,
                    payment_method_id: finalPaymentMethod?.id,
                    payment_methods: userPaymentMethods?.length
                      ? userPaymentMethods.toString()
                      : null,
                    preferred_campus: preferredCampus?.attributes?.code,
                    referrer: document?.referrer || null,
                    schedule_start_date: donationDate
                      .toISOString()
                      .split('T')[0], // Outputs to YYYY-MM-DD format.
                    schedule_start_date_offset: donationDateOffset,
                    scheduled: true,
                    title: document?.title || '',
                    transaction_id: result?.data?.attributes?.transaction_id,
                    url: window?.location?.href,
                    user_id: user?.[TYPES.user.lcRockPersonAliasId],
                  },
                });
              }

              // Handle and store data and set state to show confirmation screen.
              storeUserGivingData({
                amount: userGivingData.amount,
                campus: userGivingData.campus,
                donation: result.data,
                frequency: userGivingData.frequency,
                fund: userGivingData.fund,
                paymentDate: userGivingData.paymentDate,
                paymentMethod: finalPaymentMethod,
              });
              setIsDonationComplete(true);
              setIsFormSubmitting(false);
              setIsPayPalRedirect(false);
              updateFormStatus({
                hasSubmitted: true,
                submitSuccess: true,
              });
            } else if (result?.errors) {
              const { detail } = result.errors[0];
              setIsFormSubmitting(false);
              setIsPayPalRedirect(false);

              if (overrides?.event?.complete) {
                overrides?.event.complete(STRIPE_EVENTS.complete.fail);
              }

              callSegmentTrack({
                event: ANALYTICS.events.givingError,
                properties: {
                  context: ANALYTICS.contexts.oneScreen,
                  error: detail,
                  screen: ANALYTICS.screens.names.givingForm,
                },
              });

              // No need to send user-intended error to Bugsnag.
              logError(new Error(detail), {
                bugsnag: false,
                windowAlert: true,
              });
              updateFormStatus({
                hasSubmitted: true,
                submitSuccess: false,
              });
            }
          } catch (error) {
            setIsFormSubmitting(false);
            setIsPayPalRedirect(false);

            if (overrides?.event?.complete) {
              overrides?.event.complete(STRIPE_EVENTS.complete.fail);
            }

            // No need to send user-intended error to Bugsnag.
            logError(error, { bugsnag: false, windowAlert: true });
            updateFormStatus({
              hasSubmitted: true,
              submitSuccess: false,
            });
          }
        }
      } else if (modal) {
        let segmentAction;
        switch (modal) {
          case giveBtnStrings.authenticated.amountFilled.fund.onClick.modal:
          case giveBtnStrings.unauthenticated.amountFilled.fund.onClick.modal:
            segmentAction = ANALYTICS.actions.selectFund;
            break;
          case giveBtnStrings.authenticated.amountFilled.location.onClick.modal:
          case giveBtnStrings.unauthenticated.amountFilled.location.onClick
            .modal:
            segmentAction = ANALYTICS.actions.selectLocation;
            break;
          case giveBtnStrings.authenticated.amountFilled.paymentMethod.onClick
            .modal:
            segmentAction = ANALYTICS.actions.selectPaymentMethod;
            break;
          default:
            segmentAction = ANALYTICS.actions.give;
            break;
        }
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            action: segmentAction,
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.givingDynamicButton,
            screen: ANALYTICS.screens.names.givingForm,
          },
        });
        handleModalVisibility({ isOpen: true, modalId: modal });
      } else if (fn) {
        switch (fn) {
          case 'logIn':
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                action: ANALYTICS.actions.signIn,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.givingDynamicButton,
                screen: ANALYTICS.screens.names.givingForm,
              },
            });
            logIn();
            break;
          case 'setAmountInputFocus':
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                action: ANALYTICS.actions.enterAmount,
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.givingDynamicButton,
                screen: ANALYTICS.screens.names.givingForm,
              },
            });
            document.getElementById('give-amount-input').focus();
            break;
          default:
            break;
        }
      }
    },
    [
      addFormError,
      geolocationData,
      getAccessToken,
      getPreferredCampus,
      giveBtnStrings.authenticated.amountFilled,
      giveBtnStrings.unauthenticated.amountFilled,
      giveButtonData.onClick,
      handleSmartPayCancel,
      handleSmartPayTokenCreated,
      isAuthenticated,
      logIn,
      paymentMethods,
      resetFormErrors,
      smartPayProviderData,
      storeUserGivingData,
      stripePaymentRequest,
      today,
      updateFormStatus,
      user,
      userGivingData,
    ],
  ); // NOSONAR

  /**
   * Handler function for Confirmation screen Done button click.
   *
   * @param {object} params - The function params object.
   * @param {string} params.label - The label of the button clicked (e.g. 'Learn More').
   */
  function handleDoneButtonClick({ label }) {
    let segmentContext = userGivingData?.attributes?.subscription
      ? ANALYTICS.contexts.recurring
      : ANALYTICS.contexts.oneTime;
    if (
      !isAuthenticated &&
      APP_CONFIG.includeLoggedOutDonation &&
      !geolocationData.cpa
    ) {
      segmentContext = ANALYTICS.contexts.guestGiving;
    }

    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: segmentContext,
        label,
        screen: ANALYTICS.screens.names.givingResult,
      },
    });

    fetchGivingData({
      callback: () => {
        resetUserGivingData();

        // If user's payment method is "New PayPal", it shouldn't be stored
        // or remembered after giving, so by resetting the payment method,
        // the GivingContext will auto-reset it when data is re-fetched.
        if (
          userGivingData?.paymentMethod?.attributes?.display_label ===
          PAYPAL_PAYMENT_METHOD_OBJECT?.attributes?.display_label
        ) {
          storeUserGivingData({
            paymentMethod: null,
          });
        }

        resetFormErrors();
        resetFormStatus();
        setIsRedirecting(true);
        setIsDonationComplete(false);

        window.open(`${APP_CONFIG.baseUrl}/giving/`, '_top');
      },
    });
  }

  /**
   * Handler function for Confirmation screen Recurring Donation CTA button click.
   *
   * @param {object} params - The function params object.
   * @param {string} params.label - The label of the button clicked (e.g. 'Learn More').
   * @param {string} params.target - The window target (e.g. '_blank').
   * @param {url} params.url - The URL to which to navigate.
   */
  function handleRecurringDonationButtonClick({ label, target, url }) {
    const isGuest =
      !isAuthenticated &&
      APP_CONFIG.includeLoggedOutDonation &&
      !geolocationData.cpa;
    let segmentContext = userGivingData?.attributes?.subscription
      ? ANALYTICS.contexts.recurring
      : ANALYTICS.contexts.oneTime;
    if (isGuest) {
      segmentContext = ANALYTICS.contexts.guestGiving;
    }
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: segmentContext,
        label,
        screen: ANALYTICS.screens.names.givingResult,
      },
    });

    if (isGuest) {
      logIn();
    } else {
      window.open(url, target);
    }
  }

  /**
   * Handler function for Confirmation screen One Time Donation CTA button click.
   *
   * @param {object} params - The function params object.
   * @param {string} params.label - The label of the button clicked (e.g. 'Learn More').
   */
  function handleOneTimeDonationButtonClick({ label }) {
    const isGuest =
      !isAuthenticated &&
      APP_CONFIG.includeLoggedOutDonation &&
      !geolocationData.cpa;
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: isGuest
          ? ANALYTICS.contexts.guestGiving
          : ANALYTICS.contexts.oneTime,
        label,
        screen: ANALYTICS.screens.names.givingResult,
      },
    });

    if (isGuest) {
      logIn();
    } else {
      // Reset analytics session to allow the effect to re-trigger for analytics.
      setAnalyticsSession((prevSession) => {
        return {
          ...prevSession,
          segment: {
            ...prevSession.segment,
            isStarted: false,
          },
        };
      });

      // Set new payment date from one month from today.
      const oneMonthFromToday = new Date(userGivingData.paymentDate * 1000);
      oneMonthFromToday.setMonth(oneMonthFromToday.getMonth() + 1);

      // Get monthly frequency value to use for label and userGivingData.
      const monthlyFrequency =
        frequencies.filter((freq) => {
          return freq.attributes.name.toLowerCase() === 'monthly';
        })[0] || userGivingData.frequency;

      // Handle set up recurring click.
      fetchGivingData({
        callback: () => {
          resetFormErrors();
          resetFormStatus();
          setIsDonationComplete(false);
          setFrequencyLabel(monthlyFrequency?.attributes?.name);
          storeUserGivingData({
            frequency: monthlyFrequency,
            paymentDate: oneMonthFromToday.getTime() / 1000,
          });
        },
      });
    }
  }

  /**
   * Handler function for recurring list item element click.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  function handleRecurringListItemClick(event) {
    event.preventDefault();
    const newFrequencyName =
      userGivingData?.frequency?.attributes?.name.toLowerCase() === 'one time'
        ? 'monthly'
        : 'one time';
    const filteredFrequency =
      frequencies.filter((freq) => {
        return freq.attributes.name.toLowerCase() === newFrequencyName;
      })[0] || null;

    // If changing to one-time (i.e. if recurring is turned off), use setTimeout
    // to wait for animation to complete before updating frequency label.
    if (filteredFrequency?.attributes?.name.toLowerCase() !== 'one time') {
      setFrequencyLabel(filteredFrequency?.attributes?.name);
    }
    storeUserGivingData({
      frequency: filteredFrequency,
    });

    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.recurringToggle,
        screen: ANALYTICS.screens.names.givingForm,
        value: !isRecurringFrequency ? 'on' : 'off',
      },
    });

    callSegmentTrack({
      event: ANALYTICS.events.givingValueUpdated,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.frequency,
        value: filteredFrequency?.attributes?.name,
      },
    });
  }

  /**
   * Handler function for log out event.
   *
   * @param {Event} event - The Event data object.
   */
  function handleLogOut(event) {
    if (event) {
      event.preventDefault();
    }
    logOut();
  }

  /**
   * Represents a container with Life.Church logo and loading spinner to display
   * when application is in loading state.
   *
   * @returns {React.ReactElement} The CallbackLoader component.
   */
  const CallbackLoader = () => {
    return (
      <div className="callback flex-center">
        <img
          alt={STRINGS.general.lifeChurch}
          className="lc-logo"
          src={LifeChurchLogoHorizontal}
        />
        <img alt={STRINGS.labels.loading} src={Loading} />
      </div>
    );
  }; // NOSONAR

  /**
   * Represents a list item with side-by-side label and title, and down-facing caret arrow.
   *
   * @param {object} props - The component props object.
   * @param {string} props.label - The label text value.
   * @param {Function} props.onClick - Handler function for click event.
   * @param {string} props.title - The title text value.
   *
   * @returns {React.ReactElement} The DropDownButtonListItem component.
   */
  const DropDownButtonListItem = ({
    label,
    onClick,
    title,
    ...passThroughProps
  }) => {
    const listItemClass = [
      'dropdown-button',
      passThroughProps?.className || '',
    ].join(' ');

    return (
      <ListItem
        className={listItemClass}
        description={label}
        onClick={onClick}
        title={title}
        titleProps={{ primary: true }}
      />
    );
  }; // NOSONAR

  /**
   * Represents a list item for recurring giving toggle, including a ToggleSwitch component.
   *
   * @returns {React.ReactElement} The RecurringListItem component.
   */
  const RecurringListItem = () => {
    return (
      <ListItem
        actionIcon={<></>}
        className="flat-bottom"
        data-testid="recurring-toggle-switch"
        name="recurring-toggle-switch"
        onClick={handleRecurringListItemClick}
        showActionIcon={true}
        startIcon={<RecurringPaymentIcon color="#09c1a1" />}
        title={giveFormStrings.labels.recurring}
      />
    );
  }; // NOSONAR

  /**
   * Convenience function to trigger creating PayPal as payment method and post donation.
   *
   * @param {string} token - The PayPal token used to create the payment method.
   */
  async function createPayPalPaymentMethodAndPostDonation(token) {
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: token,
      });
      const paymentMethod = result.data;
      storeUserGivingData({
        paymentMethod,
      });

      // Simulate button click, but with submit and paymentMethod overrides.
      handleGiveButtonClick(null, { paymentMethod, submit: true });
    } catch (error) {
      logError(error);
      logError(new Error(STRINGS.payPal.errors.createPaymentMethod), {
        browserConsole: false,
        bugsnag: false,
        windowAlert: true,
      });
    }
  }

  /**
   * Handler function for the Frequency modal change event. This function serves
   * to update the frequency label, which is used in place of hard-coded value
   * that auto-updates for userGivingData.frequency to allow for smooth UI
   * transitions without the label changing mid-animation of the frequency
   * component animating in/out.
   *
   * @param {Frequency} frequency - The selected Frequency data object.
   */
  function handleFrequencyChange(frequency) {
    const { name } = frequency.attributes;
    if (name && name.toLowerCase() !== 'one time') {
      setFrequencyLabel(name);
    }
  }

  /**
   * Convenience effect to trigger analytics event when giving is ready (which
   * happens when the button turns "valid" and form can be submitted).
   *
   * Note: The dependency array intentionally has the eslint disable comment,
   * since the only triggers that should cause this effect to run are related to
   * the give button data, not all of the user data being updated.
   */
  React.useEffect(() => {
    if (giveButtonData.onClick.submit && giveButtonData.className === 'green') {
      /**
       * Ensure giving ready event not triggered every time, only when form is
       * ready to submit after not having been ready.
       */
      if (!isGivingReadyCalled) {
        const donationDate = new Date(
          userGivingData.paymentDate * 1000 || null,
        );
        const donationDateOffset = calculateDaysOffset({
          endDate: donationDate,
          startDate: today,
        });
        const isDonationRecurring =
          userGivingData?.frequency?.attributes?.code
            .toLowerCase()
            .replace(' ', '')
            .replace('-', '') !== 'onetime';
        const isDonationScheduled = donationDateOffset > 0;

        const userPaymentMethods = [
          ...new Set(
            Array.from(
              paymentMethods,
              (paymentMethod) => paymentMethod.attributes.payment_method_type,
            ),
          ),
        ];
        if (smartPayProviderData?.applePay) {
          userPaymentMethods.push(
            SMART_PAY_PROVIDERS.apple_pay.attributes.display_label,
          );
        }
        if (smartPayProviderData?.googlePay) {
          userPaymentMethods.push(
            SMART_PAY_PROVIDERS.google_pay.attributes.display_label,
          );
        }

        callSegmentTrack({
          event: ANALYTICS.events.givingReady,
          properties: {
            amount: userGivingData?.amount || '0',
            campus: userGivingData?.campus?.attributes?.code,
            context: ANALYTICS.contexts.oneScreen,
            frequency: userGivingData?.frequency?.attributes?.name,
            fund: userGivingData?.fund?.attributes?.name,
            payment_method: Object.keys(SMART_PAY_PROVIDERS).includes(
              userGivingData?.paymentMethod?.attributes?.payment_method_type,
            )
              ? SMART_PAY_PROVIDERS[
                  userGivingData?.paymentMethod?.attributes?.payment_method_type
                ].attributes?.display_label
              : userGivingData?.paymentMethod?.attributes?.payment_method_type,
            payment_methods: paymentMethods?.length
              ? userPaymentMethods.toString()
              : null,
            person_alias_id: user?.[TYPES.user.lcRockPersonAliasId],
            scheduled: isDonationRecurring || isDonationScheduled,
            start_date: donationDate.toISOString().split('T')[0], // Outputs to YYYY-MM-DD format.
            start_date_offset: donationDateOffset,
          },
        });
        setIsGivingReadyCalled(true);
      }
    } else if (
      !giveButtonData.onClick.submit ||
      !giveButtonData.className === 'green' // NOSONAR
    ) {
      setIsGivingReadyCalled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [giveButtonData.className, giveButtonData.label, giveButtonData.onClick]);

  /**
   * Convenience effect to find supported URL params and UTM params.
   */
  React.useEffect(() => {
    if (isApiDataRetrieved && !isParamsParsed) {
      const windowLocationSearchParams = getSearchParamsFrom(
        window?.location?.href,
      )?.split('&');

      /**
       * Note: Have two separate objects for query params: one for raw values,
       * which will be stored in userGivingData, another for cleansed and object
       * based values to store individually in userGivingData to ensure proper
       * setting of form values.
       */
      const rawQueryParams = {};
      const mParams = {};
      const qParams = {};
      const uParams = {};
      if (windowLocationSearchParams?.length) {
        windowLocationSearchParams.forEach((param) => {
          const [key, value] = param.split('=');
          if (Object.keys(supportedQueryParams).includes(key.toLowerCase())) {
            rawQueryParams[key] = decodeURI(value);
            qParams[key] = decodeURI(value);
          } else if (key.startsWith('utm_')) {
            uParams[key] = decodeURI(value);
          }
        });
      }

      /**
       * Iterate over all query params. If it has type of object for which to
       * match up (e.g. For funds, frequencies), map over its source from the
       * GivingContext to find its corresponding object to use to store and set.
       * If it has the key for modal, give it different treatment and logic, due
       * to the fact that its source is an object, and not an array.
       */
      Object.entries(qParams).forEach(([key, value]) => {
        const separatorRegExp = /[+\-_ ]/g;
        if (supportedQueryParams[key].type === 'object') {
          if (key !== 'modal') {
            const storedObject =
              supportedQueryParams[key].source.filter((item) => {
                return (
                  item.attributes.code
                    .toLowerCase()
                    .replace('%20', '')
                    .replace('&', '')
                    .replace(separatorRegExp, '') ===
                  value
                    .toLowerCase()
                    .replace('%20', '')
                    .replace('%26', '')
                    .replace(separatorRegExp, '')
                );
              })[0] || null;
            if (storedObject) {
              qParams[key] = storedObject;
              if (key === 'frequency') {
                setFrequencyLabel(storedObject?.attributes?.name);
              }
            } else {
              delete qParams[key];
            }
          }
          // Preemptively delete any existing key/value for modal before filter.
          const allEntries = Object.entries(supportedQueryParams[key].source);
          delete mParams[key];
          allEntries.forEach(([k, v]) => {
            if (v?.queryParams) {
              const matchedObject =
                v.queryParams.filter((item) => {
                  return (
                    item.toLowerCase() ===
                    value
                      .toLowerCase()
                      .replace('%20', '')
                      .replace(separatorRegExp, '')
                  );
                })[0] || null;
              if (matchedObject) {
                mParams[key] = modals[k];
              }
            }
          });
        }
      });

      // Store user giving data from query params, to populate form values.
      storeUserGivingData({
        ...qParams,
        queryParams: rawQueryParams,
        utmParams: uParams,
      });

      // Set state values for modal, query, and UTM params.
      setModalParams((prevParams) => {
        return {
          ...prevParams,
          ...mParams,
        };
      });
      setQueryParams((prevParams) => {
        return {
          ...prevParams,
          ...qParams,
        };
      });
      setUtmParams((prevParams) => {
        return {
          ...prevParams,
          ...uParams,
        };
      });

      setIsParamsParsed(true);
    }
  }, [
    isApiDataRetrieved,
    isParamsParsed,
    modals,
    storeUserGivingData,
    supportedQueryParams,
  ]); // NOSONAR

  /**
   * Single-run convenience effect to load Stripe.
   */
  React.useEffect(() => {
    setStripePromise(loadStripe(process.env.STRIPE_PUBLISHABLE_KEY));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Convenience effect to calculate and set payment method title.
   */
  React.useEffect(() => {
    let title =
      userGivingData?.paymentMethod?.attributes.display_label ||
      STRINGS.labels.selectPaymentMethod;
    const userPaymentMethodType =
      userGivingData?.paymentMethod?.attributes?.payment_method_type ?? null;
    if (userPaymentMethodType === APPLE_PAY_PAYMENT_METHOD_OBJECT.type) {
      title = APPLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label;
    } else if (
      userPaymentMethodType === GOOGLE_PAY_PAYMENT_METHOD_OBJECT.type
    ) {
      title = GOOGLE_PAY_PAYMENT_METHOD_OBJECT?.attributes?.display_label;
    }
    setPaymentMethodTitle(title);
  }, [userGivingData?.paymentMethod?.attributes]);

  /**
   * Convenience effect to calculate Give button data.
   */
  React.useEffect(() => {
    calculateGiveButtonData();
  }, [calculateGiveButtonData, smartPayProviderData]);

  /**
   * Convenience effect to check payment availability.
   */
  React.useEffect(() => {
    if (stripePaymentRequest && !isCanMakePaymentChecked) {
      stripePaymentRequest.canMakePayment().then((result) => {
        storeSmartPayProviderData({
          ...result,
          applePay: APP_CONFIG.includeApplePay ? result?.applePay : false,
        });
        if (result && (result.applePay || result.googlePay)) {
          // Apple and/or Google Pay is supported in this browser.
        }
      });
      setIsCanMakePaymentChecked(true);
    }
  }, [
    isCanMakePaymentChecked,
    storeSmartPayProviderData,
    stripePaymentRequest,
  ]);

  /**
   * Convenience effect to generate description value for payment method item.
   */
  React.useEffect(() => {
    if (userGivingData?.paymentMethod) {
      const paymentMethod = userGivingData?.paymentMethod;
      const { expiration_label: expirationLabel } = paymentMethod.attributes;
      const { attributes: paymentMethodAttributes } = paymentMethod;
      const { exp_month: expMonth, exp_year: expYear } =
        paymentMethodAttributes;
      const monthOffset =
        expMonth !== null && expYear !== null
          ? calculateMonthOffset({
              endDate: new Date(expYear, expMonth - 1), // Note: exp month is 1-based, not 0-based like JS Date object.
              startDate: today,
            })
          : null;
      const expirePrefix =
        STRINGS.modals.paymentMethod.select[
          `${monthOffset !== null && monthOffset < 0 ? 'expired' : 'expires'}`
        ];
      let labelExpireClass = 'default';
      if (monthOffset < 0) {
        labelExpireClass = 'error';
      } else if (monthOffset < 2) {
        labelExpireClass = 'warning';
      }
      setPaymentMethodListItemExpireStyle(labelExpireClass);
      const icon = monthOffset < 0 ? <WarningIcon /> : null;

      setPaymentMethodDescription(
        expirationLabel && labelExpireClass === 'error' ? (
          <>
            {icon} {expirePrefix} {expirationLabel}
          </>
        ) : null,
      );
    }
  }, [userGivingData?.paymentMethod, today]);

  /**
   * Convenience effect to update payment method to Smart Pay option, when
   * available, and when no paymentMethod set in userGivingData.
   */
  React.useEffect(() => {
    if (
      !userGivingData?.paymentMethod &&
      !Object.values(smartPayProviderData).every((item) => item === false)
    ) {
      let smartPayPaymentMethod = null;
      if (smartPayProviderData.applePay) {
        smartPayPaymentMethod = APPLE_PAY_PAYMENT_METHOD_OBJECT;
      }
      if (smartPayProviderData.googlePay) {
        smartPayPaymentMethod = GOOGLE_PAY_PAYMENT_METHOD_OBJECT;
      }
      if (smartPayPaymentMethod) {
        storeUserGivingData({
          paymentMethod: smartPayPaymentMethod,
        });
      }
    }
  }, [
    smartPayProviderData,
    storeUserGivingData,
    userGivingData?.paymentMethod,
  ]);

  /**
   * Convenience function to resolve and set the Stripe object.
   *
   * @param {object} params - The function params object.
   * @param {Promise} [params.callback] - Optional callback function.
   * @param {Promise} params.promise - The Stripe Promise.
   */
  const resolveStripe = React.useCallback(async ({ callback, promise }) => {
    const stripe = await promise;
    if (callback && typeof callback === 'function') {
      callback(stripe);
    }
  }, []);

  /**
   * Convenience effect to check for Smart Pay availability and store in the
   * GivingContext, as well as add event listeners for payment request.
   */
  React.useEffect(() => {
    if (stripePromise && !isSmartPayAvailabilityChecked) {
      const amountValue = !Number.isNaN(
        parseInt(Math.round(parseFloat(userGivingData?.amount || 0) * 100), 10),
      )
        ? parseInt(
            Math.round(parseFloat(userGivingData?.amount || 0) * 100),
            10,
          )
        : 0;
      resolveStripe({
        callback: (stripe) => {
          setStripeObject(stripe);
          setStripePaymentRequest(
            stripe.paymentRequest({
              country: 'US',
              currency: 'usd',
              requestPayerEmail: true,
              requestPayerName: true,
              requestPayerPhone: true,
              total: {
                amount: amountValue, // Amount in cents.
                label: giveButtonData?.label.replace(
                  /{X}/g,
                  `$${
                    formatNumberAsCurrency({
                      number: userGivingData?.amount || '0',
                    }).display || '0'
                  }`,
                ),
              },
            }),
          );
          setIsSmartPayAvailabilityChecked(true);
        },
        promise: stripePromise,
      });
    }
  }, [
    giveButtonData?.label,
    isSmartPayAvailabilityChecked,
    resolveStripe,
    stripePromise,
    userGivingData?.amount,
  ]);

  /**
   * Convenience effect to trigger Segment Page View event on load, and after
   * the user has been checked (value derived from AuthContext) and Giving API
   * data retrieved (value derived from GivingContext).
   */
  React.useEffect(() => {
    if (
      !analyticsSession.segment.isStarted &&
      isUserChecked &&
      isApiDataRetrieved &&
      apiStatus.isAvailable
    ) {
      setAnalyticsSession((prevSession) => {
        return {
          ...prevSession,
          segment: {
            ...prevSession.segment,
            isStarted: true,
          },
        };
      });

      callSegmentPage({
        category: '',
        name: ANALYTICS.pages.givingForm,
        properties: {
          screen_class: ANALYTICS.screens.classes.oneScreen,
          url: window.location.href,
        },
      });

      const userPaymentMethods = [
        ...new Set(
          Array.from(
            paymentMethods,
            (paymentMethod) => paymentMethod.attributes.payment_method_type,
          ),
        ),
      ];
      if (smartPayProviderData?.applePay) {
        userPaymentMethods.push(
          SMART_PAY_PROVIDERS.apple_pay.attributes.display_label,
        );
      }
      if (smartPayProviderData?.googlePay) {
        userPaymentMethods.push(
          SMART_PAY_PROVIDERS.google_pay.attributes.display_label,
        );
      }

      callSegmentTrack({
        event: ANALYTICS.events.givingStarted,
        properties: {
          amount: userGivingData?.amount || '0',
          campus: userGivingData?.campus?.attributes?.code,
          context: ANALYTICS.contexts.oneScreen,
          frequency: userGivingData?.frequency?.attributes?.name,
          fund: userGivingData?.fund?.attributes?.name,
          payment_method: Object.keys(SMART_PAY_PROVIDERS).includes(
            userGivingData?.paymentMethod?.attributes?.payment_method_type,
          )
            ? SMART_PAY_PROVIDERS[
                userGivingData?.paymentMethod?.attributes?.payment_method_type
              ].attributes?.display_label
            : userGivingData?.paymentMethod?.attributes?.payment_method_type,
          payment_method_id: Object.keys(SMART_PAY_PROVIDERS).includes(
            userGivingData?.paymentMethod?.attributes?.payment_method_type,
          )
            ? SMART_PAY_PROVIDERS[
                userGivingData?.paymentMethod?.attributes?.payment_method_type
              ].attributes?.display_label
            : userGivingData?.paymentMethod?.id,
          payment_methods: userPaymentMethods?.length
            ? userPaymentMethods.toString()
            : null,
          person_alias_id: user?.[TYPES.user.lcRockPersonAliasId],
          screen: ANALYTICS.screens.names.givingForm,
        },
      });
    }
  }, [
    analyticsSession.segment.isStarted,
    apiStatus.isAvailable,
    isApiDataRetrieved,
    isUserChecked,
    paymentMethods,
    smartPayProviderData,
    user,
    userGivingData,
  ]);

  /**
   * Convenience effect to find current location campus.
   */
  React.useEffect(() => {
    if (userPosition?.campusProximity) {
      const currentLocationCampus = Object.entries(
        userPosition?.campusProximity,
      ).find(([, data]) => {
        return data.isWithinRange === true;
      });
      if (currentLocationCampus?.length) {
        const filteredCurrentLocationCampus = campuses.find(
          (campus) => campus?.id === currentLocationCampus[0],
        );
        setIsLocationCurrentLocation(
          userGivingData?.campus?.id === filteredCurrentLocationCampus?.id,
        );
      } else {
        setIsLocationCurrentLocation(false);
      }
    }
  }, [campuses, userGivingData?.campus, userPosition]);

  /**
   * Single-run convenience effect to check query param value for modal and,
   * after app status is set appropriately and fully, calculate if the value is
   * a supported and handled modal value, and trigger it to auto-open. This also
   * checks to see if there are any stored modal actions in userGivingData, and
   * sets modal visibilities accordingly.
   */
  React.useEffect(() => {
    const storedModalActions = {
      addPaymentMethod: {
        value: userGivingData?.actionAddPaymentMethod,
      },
    };
    if (modalParams?.modal && !modalParams?.modal.isAuthRequired) {
      handleModalVisibility({ isOpen: true, modalId: modalParams.modal.id });
    } else if (
      modalParams?.modal &&
      modalParams?.modal.isAuthRequired &&
      isAuthenticated
    ) {
      handleModalVisibility({ isOpen: true, modalId: modalParams.modal.id });
    } else if (storedModalActions.addPaymentMethod.value) {
      handleModalVisibility({ isOpen: true, modalId: modals.paymentMethod.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, modalParams]);

  /**
   * Convenience effect to add appropriate visibility style to backdrop element
   * to sync up with and match when a modal is open. Note that setTimeout is
   * used when hiding to allow for the opacity animation to complete.
   */
  React.useEffect(() => {
    if (modalBackdropRef.current) {
      if (!Object.values(modalStateData).every((value) => !value.isOpen)) {
        modalBackdropRef.current.style.visibility = 'visible';
      } else {
        setTimeout(() => {
          modalBackdropRef.current.style.visibility = 'hidden';
        }, 175);
      }
    }
  }, [modalStateData]);

  /**
   * Single-run convenience effect to calculate if PayPal redirect with token.
   */
  React.useEffect(() => {
    let payPalToken = null;
    let isPayPal = false;
    const windowLocationSearchParams = getSearchParamsFrom(
      window?.location?.href,
    )?.split('&');

    // Confirm token exists in query params before handling PayPal.
    if (windowLocationSearchParams?.length) {
      windowLocationSearchParams.forEach((param) => {
        const [key, value] = param.split('=');
        if (key.toLowerCase() === 'token') {
          payPalToken = decodeURI(value);
        }
        if (key.toLowerCase() === 'paypal') {
          isPayPal = true;
        }
      });
      if (payPalToken && isPayPal) {
        storeUserGivingData({
          token: payPalToken,
        });
        // Reset URL without query params.
        window.history.replaceState(null, null, window.location.pathname);
        setIsFormSubmitting(true);
        setIsPayPalRedirect(true);
        createPayPalPaymentMethodAndPostDonation(payPalToken); // NOSONAR
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Important: If in maintenance or outage mode, return a different view.
   */
  if (apiStatus.isMaintenance || apiStatus.isOutage) {
    return (
      <div className="container">
        <div className="content-center">
          <MaintenanceOutage
            mode={apiStatus.isOutage ? 'outage' : 'maintenance'}
          />
        </div>
      </div>
    );
  }

  if (isPayPalRedirect) {
    return (
      <div className="container">
        <div className="content-center">
          {isFormSubmitting ? (
            <Processing
              includeQuote={false}
              quote={bibleVerse.text}
              reference={`- ${bibleVerse.reference}`}
              status={STRINGS.labels.processing}
            />
          ) : (
            <CallbackLoader />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="content-center">
        {isDonationComplete && userGivingData?.donation ? (
          <Confirmation
            donationData={userGivingData.donation}
            onDoneClick={handleDoneButtonClick}
            onOneTimeDonationCtaClick={handleOneTimeDonationButtonClick}
            onRecurringDonationCtaClick={handleRecurringDonationButtonClick}
          />
        ) : (
          <>
            {apiStatus.isAvailable &&
            isParamsParsed &&
            stripePromise &&
            !isRedirecting ? (
              <Elements stripe={stripePromise}>
                <Header
                  campusName={userGivingData?.campus?.attributes?.name}
                  includeLocation={
                    userPosition &&
                    userGivingData?.campus &&
                    userGivingData?.campus?.attributes?.code.toLowerCase() !==
                      'int'
                  }
                  isLocationCurrentLocation={isLocationCurrentLocation}
                  onGivingHistoryClick={() => {
                    handleModalVisibility({
                      isOpen: true,
                      modalId: modals.givingHistory.id,
                    });
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.givingHistory,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                  }}
                  onHelpClick={() => {
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.help,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                    handleModalVisibility({
                      isOpen: true,
                      modalId: modals.help.id,
                    });
                  }}
                  onLocationSelectClick={() => {
                    handleModalVisibility({
                      isOpen: true,
                      modalId: modals.location.id,
                    });
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.location,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                  }}
                />
                <Amount
                  isModalOpen={modalStateData[modals.funds.id]?.include}
                  onFundSelectClick={() => {
                    handleModalVisibility({
                      isOpen: true,
                      modalId: modals.funds.id,
                    });
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.fund,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                  }}
                  onSubmit={(event) => {
                    if (!isAndroid) {
                      handleGiveButtonClick(event);
                    }
                  }}
                />

                <div className="grouped">
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="recurring-gift-wrapper"
                    onClick={handleRecurringListItemClick}
                  >
                    <RecurringListItem />
                    <ToggleSwitch
                      checked={isRecurringFrequency}
                      className="toggle-switch"
                    />
                  </div>
                  <SmoothCollapse expanded={isRecurringFrequency}>
                    <DropDownButtonListItem
                      className={
                        modalStateData[modals.frequency.id]?.include
                          ? 'open'
                          : 'closed'
                      }
                      label={giveFormStrings.labels.frequency}
                      onClick={() => {
                        handleModalVisibility({
                          isOpen: true,
                          modalId: modals.frequency.id,
                        });
                        callSegmentTrack({
                          event: ANALYTICS.events.buttonTap,
                          properties: {
                            context: ANALYTICS.contexts.oneScreen,
                            label: ANALYTICS.labels.frequency,
                            screen: ANALYTICS.screens.names.givingForm,
                          },
                        });
                      }}
                      title={frequencyLabel}
                    />
                  </SmoothCollapse>
                  <DropDownButtonListItem
                    className={
                      modalStateData[modals.processDate.id]?.include
                        ? 'open'
                        : 'closed'
                    }
                    label={giveFormStrings.labels.processDate}
                    onClick={() => {
                      handleModalVisibility({
                        isOpen: true,
                        modalId: modals.processDate.id,
                      });
                      callSegmentTrack({
                        event: ANALYTICS.events.buttonTap,
                        properties: {
                          context: ANALYTICS.contexts.oneScreen,
                          label: ANALYTICS.labels.processDate,
                          screen: ANALYTICS.screens.names.givingForm,
                        },
                      });
                    }}
                    title={relativeDateLabel({
                      date: userGivingData
                        ? new Date(userGivingData.paymentDate * 1000 || today)
                        : today,
                      relativeDate: today,
                    })}
                  />
                </div>

                {(APP_CONFIG.includeLoggedOutDonation &&
                  !geolocationData.cpa &&
                  userGivingData?.paymentMethod) ||
                isAuthenticated ? (
                  <ListItem
                    className={`${[
                      'dropdown-button',
                      'mt-24',
                      paymentMethodListItemExpireStyle,
                      modalStateData[modals.paymentMethod.id]?.include
                        ? 'open'
                        : 'closed',
                    ].join(' ')}`}
                    description={paymentMethodDescription}
                    onClick={() => {
                      handleModalVisibility({
                        isOpen: true,
                        modalId: modals.paymentMethod.id,
                      });
                      callSegmentTrack({
                        event: ANALYTICS.events.buttonTap,
                        properties: {
                          context: ANALYTICS.contexts.oneScreen,
                          label: ANALYTICS.labels.paymentMethod,
                          screen: ANALYTICS.screens.names.givingForm,
                        },
                      });
                    }}
                    startIcon={
                      <PaymentIcon
                        height={21}
                        paymentMethodType={
                          userGivingData?.paymentMethod?.attributes
                            .payment_method_type === 'Credit Card'
                            ? userGivingData?.paymentMethod?.attributes
                                .payment_type
                            : userGivingData?.paymentMethod?.attributes
                                .payment_method_type || 'Credit Card'
                        }
                      />
                    }
                    title={paymentMethodTitle}
                    titleProps={{ primary: true }}
                  />
                ) : null}

                <StyledButton
                  className={[
                    'give-button',
                    giveButtonData?.className || '',
                  ].join(' ')}
                  data-testid="give-btn"
                  disabled={isFormSubmitting}
                  onClick={handleGiveButtonClick}
                  size={ButtonSizes.large}
                  variant={ButtonVariants.primary}
                >
                  {isFormSubmitting ? (
                    <div className="circular loader"></div>
                  ) : (
                    <>
                      {giveButtonData?.label.replace(
                        /{X}/g,
                        `$${
                          formatNumberAsCurrency({
                            number: userGivingData?.amount || '0',
                          }).display || '0'
                        }`,
                      )}
                    </>
                  )}
                </StyledButton>
                <div className="modal-wrapper">
                  <div
                    className={`${[
                      'backdrop',
                      !Object.values(modalStateData).every(
                        (value) => !value.isOpen,
                      )
                        ? 'open'
                        : 'close',
                    ].join(' ')}`}
                    ref={modalBackdropRef}
                  ></div>

                  {modalStateData[modals.location.id].include ? (
                    <LocationModal
                      isOpen={modalStateData[modals.location.id].isOpen}
                      onClose={() => {
                        handleModalClose(modals.location.id);
                      }}
                    />
                  ) : null}
                  {modalStateData[modals.paymentMethod.id].include ? (
                    <PaymentMethodModal
                      includePayPal={includePayPal}
                      isOpen={modalStateData[modals.paymentMethod.id].isOpen}
                      mode={
                        paymentMethods ? MODAL_MODES.select : MODAL_MODES.add
                      }
                      onClose={() => {
                        handleModalClose(modals.paymentMethod.id);
                      }}
                      payPalMode={payPalMode}
                    />
                  ) : null}
                  {modalStateData[modals.frequency.id].include ? (
                    <FrequencyModal
                      isOpen={modalStateData[modals.frequency.id].isOpen}
                      onChange={handleFrequencyChange}
                      onClose={() => {
                        handleModalClose(modals.frequency.id);
                      }}
                    />
                  ) : null}
                  {modalStateData[modals.funds.id].include ? (
                    <FundsModal
                      isOpen={modalStateData[modals.funds.id].isOpen}
                      onClose={() => {
                        handleModalClose(modals.funds.id);
                      }}
                    />
                  ) : null}
                  {modalStateData[modals.givingHistory.id].include ? (
                    <GivingHistoryModal
                      isOpen={modalStateData[modals.givingHistory.id].isOpen}
                      onClose={() => {
                        handleModalClose(modals.givingHistory.id);
                      }}
                      onHelpClick={() => {
                        callSegmentTrack({
                          event: ANALYTICS.events.buttonTap,
                          properties: {
                            context: ANALYTICS.contexts.oneScreen,
                            label: ANALYTICS.labels.help,
                            screen: ANALYTICS.screens.names.givingHistoryDetail,
                          },
                        });
                        handleModalVisibility({
                          isOpen: true,
                          modalId: modals.help.id,
                        });
                      }}
                    />
                  ) : null}
                  {modalStateData[modals.processDate.id].include ? (
                    <ProcessDateModal
                      isOpen={modalStateData[modals.processDate.id].isOpen}
                      onClose={() => {
                        handleModalClose(modals.processDate.id);
                      }}
                    />
                  ) : null}
                  {modalStateData[modals.help.id].include ? (
                    <HelpModal
                      isOpen={modalStateData[modals.help.id].isOpen}
                      onClose={() => {
                        handleModalClose(modals.help.id);
                      }}
                    />
                  ) : null}
                </div>
                <Footer
                  includeConsent={includeOsanoConsentManager}
                  includeHelp={false}
                  includeLogOut={APP_CONFIG.includeLogOut && isAuthenticated}
                  onConsentLinkClick={(event) => {
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.cookiePreferences,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                    event.preventDefault();
                    event.stopPropagation();
                    window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
                  }}
                  onHelpClick={() => {
                    callSegmentTrack({
                      event: ANALYTICS.events.buttonTap,
                      properties: {
                        context: ANALYTICS.contexts.oneScreen,
                        label: ANALYTICS.labels.help,
                        screen: ANALYTICS.screens.names.givingForm,
                      },
                    });
                    handleModalVisibility({
                      isOpen: true,
                      modalId: modals.help.id,
                    });
                  }}
                  onLogOut={APP_CONFIG.includeLogOut ? handleLogOut : null}
                />

                {isFormSubmitting ? (
                  <Processing
                    includeQuote={false}
                    quote={bibleVerse.text}
                    reference={`- ${bibleVerse.reference}`}
                    status={STRINGS.labels.processing}
                  />
                ) : null}
              </Elements>
            ) : (
              <CallbackLoader />
            )}
          </>
        )}
      </div>
    </div>
  );
}; // NOSONAR

export default App;
