/**
 * @module AmountInput
 */
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import useGiving from '../../../hooks/useGiving';
import {
  ANALYTICS,
  STRINGS,
  SUPPORTED_CHARACTERS,
  cleanseInput,
  formatWithCommas,
} from '../../../utils';
import './AmountInput.scss';

/**
 * Represents an input field for giving amount.
 *
 * @param {object} props - The component props object.
 * @param {Function} [props.onBlur] - Handler function for input blur event.
 * @param {Function} [props.onChange] - Handler function for input change event.
 * @param {Function} [props.onFocus] - Handler function for input focus event.
 * @param {Function} [props.onSubmit] - Handler function for input to trigger parent form submit.
 * @param {Function} [props.onValidCheck] - Handler function for input validation check event.
 * @param {object} props.sizingData - Object of sizing data used for calculating element styles and sizes.
 *
 * @returns {React.ReactElement} The AmountInput component.
 */
export function AmountInput({
  onBlur,
  onChange,
  onFocus,
  onSubmit,
  onValidCheck,
  sizingData,
  ...passThroughProps
}) {
  const { storeUserGivingData, userGivingData } = useGiving();
  const { amount: amountStrings } = STRINGS.inputs;
  const [amount, setAmount] = React.useState(
    formatWithCommas(userGivingData?.amount) || '',
  );
  const isError =
    (amount < amountStrings.min || amount > amountStrings.max) &&
    ![''].includes(amount);
  const [isInputFocus, setIsInputFocus] = React.useState(false);
  const amountInputRef = React.useRef();
  const amountInputLabelRef = React.useRef();
  const amountInputParentRef = React.useRef();
  const amountInputSizerRef = React.useRef();
  const cursorPosition = React.useRef(amount.length);

  /**
   * Handler function for amount input field change. Note that this utilizes the
   * formatting for JavaScript's Intl.NumberFormat to normalize and format the
   * user-provided amount to a valid 2-decimal USD amount.
   *
   * @param {Event} event - The Event object associated with the change event.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat}.
   */
  /* istanbul ignore next */
  const handleAmountChanged = (event) => {
    if (event) {
      event.preventDefault();
    }

    // Store original and updated values for use in calculating/formatting.
    const originalValue = cleanseInput(amount);
    const updatedValue = cleanseInput(event?.target?.value);

    // If amount larger than max threshold, don't update; simply return.
    if (parseFloat(updatedValue) > parseFloat(amountStrings.max)) {
      return;
    }

    // Format number with commas, update refs, calculate decimals/commas count.
    const amountFormattedWithCommas = formatWithCommas(updatedValue);
    const numCommas = {
      new: (formatWithCommas(updatedValue).match(/,/g) || []).length,
      original: (formatWithCommas(originalValue).match(/,/g) || []).length,
    };
    const numDecimals = {
      new: updatedValue?.indexOf('.') >= 0 ? 1 : 0,
      original: originalValue?.indexOf('.') >= 0 ? 1 : 0,
    };

    // Set cursor position, based on scenarios and count of decimals/commas.
    if (
      numCommas.new < numCommas.original &&
      numDecimals.new > numDecimals.original
    ) {
      // Less commas, added decimal.
      // Example: 1,234 -> 12.34.
      cursorPosition.current = amountInputRef.current.selectionStart - 1;
    } else if (
      numCommas.new > numCommas.original &&
      numDecimals.new < numDecimals.original
    ) {
      // More commas, removed decimal.
      // Example: 12,345.67 -> 1,234,567.
      cursorPosition.current = amountInputRef.current.selectionStart + 1;
    } else if (
      numCommas.new > numCommas.original &&
      numDecimals.new > 0 &&
      numDecimals.original > 0
    ) {
      // More commas, existing decimal.
      // Example: 123,456.78 -> 1,234,569.78.
      cursorPosition.current = amountInputRef.current.selectionStart + 1;
    } else if (
      numCommas.new > numCommas.original &&
      numDecimals.new === 0 &&
      numDecimals.original === 0
    ) {
      // More commas, no new or existing decimal.
      // Example: 123,456 -> 1,234,567.
      cursorPosition.current = amountInputRef.current.selectionStart + 1;
    } else if (
      numCommas.new < numCommas.original &&
      numDecimals.new > 0 &&
      numDecimals.original > 0
    ) {
      // Less commas, existing decimal.
      // Example: 1,234.56 -> 123.56.
      cursorPosition.current = amountInputRef.current.selectionStart - 1;
    } else if (
      numCommas.new < numCommas.original &&
      numDecimals.new === 0 &&
      numDecimals.original === 0
    ) {
      // Less commas, no new or existing decimal.
      // Example: 1,234 -> 123.
      cursorPosition.current = amountInputRef.current.selectionStart - 1;
    } else {
      // Fallback. Same number of commas and decimals.
      cursorPosition.current = amountInputRef.current.selectionStart;
    }

    // Set state value, formatted with commas for display.
    setAmount(amountFormattedWithCommas);
    amountInputSizerRef.current.innerText = amountFormattedWithCommas;

    // Store unformatted value as amount in userGivingData object.
    storeUserGivingData({
      ...userGivingData,
      amount: updatedValue,
    });

    if (onChange && typeof onChange === 'function') {
      onChange();
    }
  }; // NOSONAR

  /**
   * Handler function for Amount input field key press event.
   *
   * @param {Event} event - The Event object associated with the key press.
   *
   * @returns {boolean} Boolean flag denoting whether or not the key is supported.
   */
  function handleAmountKeyPress(event) {
    if (event?.key?.toLowerCase() === 'enter') {
      amountInputRef?.current?.blur();
      if (onSubmit && typeof onSubmit === 'function') {
        onSubmit();
      }
    }
    return SUPPORTED_CHARACTERS.amount.includes(event?.key);
  }

  /**
   * Handler function to prevent default event action from taking place.
   *
   * @param {Event} event - The Event object associated with the input event.
   */
  /* istanbul ignore next */
  function handlePrevent(event) {
    if (event) {
      event.preventDefault();
    }
  }

  /**
   * Handler function for amount input field blur.
   */
  function handleAmountBlur() {
    setIsInputFocus(false);

    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.givingValueUpdated,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.amount,
        screen: ANALYTICS.screens.names.givingForm,
        value: amount,
      },
    });

    // Trigger prop function for blur event.
    if (onBlur && typeof onBlur === 'function') {
      onBlur();
    }
  }

  /**
   * Handler function for amount input field focus.
   */
  function handleAmountFocus() {
    setIsInputFocus(true);

    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.amount,
        screen: ANALYTICS.screens.names.givingForm,
        value: amount,
      },
    });

    // Trigger prop function for focus event.
    if (onFocus && typeof onFocus === 'function') {
      onFocus();
    }
  }

  /**
   * Handler function for filler area click, to allow focus to be set with click
   * outside of actual input field.
   */
  /* istanbul ignore next */
  function handleFillerClick() {
    setIsInputFocus(true);
    amountInputRef?.current?.focus();
  }

  /**
   * Convenience effect to trigger validation check handler.
   */
  React.useEffect(() => {
    if (onValidCheck && typeof onValidCheck === 'function') {
      onValidCheck(!isError);
    }
  }, [amount, isError, onValidCheck]);

  /**
   * Convenience function to update the cursor position when amount updated.
   *
   * Note: If currently-active element is NOT the same as the active element
   * after setting selection range (as is the case on page load), auto-blur the
   * document activeElement to avoid unwanted autofocus.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (amountInputRef?.current) {
      const currentActiveElement = document.activeElement;
      amountInputRef?.current.setSelectionRange(
        cursorPosition?.current,
        cursorPosition?.current,
      );
      const updatedActiveElement = document.activeElement;
      if (currentActiveElement !== updatedActiveElement) {
        document.activeElement.blur();
      }
    }
  }, [amount]);

  /**
   * Convenience effect to trigger onChange with change of input sizer.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (amountInputSizerRef?.current) {
      if (onChange && typeof onChange === 'function') {
        onChange();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountInputSizerRef?.current]);

  /**
   * Note: With the use of passThroughProps (i.e. `rest`), it allows the
   * component to get and use additional props, such as `className`. As such,
   * use a dynamically-built list of class names, based on the standard for the
   * component, but adding in additionally-provided values as well.
   */
  const wrapperClass = [
    'form-control',
    'amount-field',
    passThroughProps?.className || '',
  ].join(' ');

  /* istanbul ignore next */
  const amountWrapStyles = {
    display: 'flex',
    left: `${sizingData?.sizes[sizingData?.layout]?.labelAndInput?.left}`,
    marginLeft:
      sizingData?.sizes[sizingData?.layout]?.labelAndInput?.marginLeft,
    maxWidth: sizingData?.sizes[sizingData?.layout]?.labelAndInput?.maxWidth,
    overflow: 'hidden',
    // position: 'absolute',
    transform: `translateX(${
      sizingData?.sizes[sizingData?.layout]?.labelAndInput?.left
    })`,
    transition:
      'all 0.3s ease-in-out, width 0s ease-in, margin 0s ease-in, left 0s ease-in',
    width: sizingData?.sizes[sizingData?.layout]?.labelAndInput?.width,
  };

  return (
    <div
      className={[wrapperClass, isError ? 'error' : ''].join(' ')}
      data-testid="amount-input-wrapper"
    >
      <button
        className="full-and-absolute"
        onClick={handleFillerClick}
      ></button>
      <div
        data-layout={sizingData?.layout}
        ref={amountInputParentRef}
        style={amountWrapStyles}
      >
        <label
          className={[
            amountStrings.label.className,
            isError ? 'error' : '',
            isInputFocus ? 'green' : '',
          ].join(' ')}
          data-testid="amount-input-label"
          id="amount-input-label"
          ref={amountInputLabelRef}
          style={{
            fontSize: sizingData?.sizes[sizingData?.layout]?.label?.fontSize,
            marginTop: sizingData?.sizes[sizingData?.layout]?.label?.marginTop,
          }}
        >
          {amountStrings.label.value}
        </label>
        <input
          autoComplete="off"
          className={[amountStrings.className, isError ? 'error' : ''].join(
            ' ',
          )}
          data-1p-ignore={true}
          data-testid="amount-input"
          id={passThroughProps?.id || 'give-amount-input'}
          inputMode={amountStrings.inputMode}
          max={amountStrings.max}
          min={amountStrings.min}
          name={amountStrings.name}
          onBlur={handleAmountBlur}
          onDrop={handlePrevent}
          onFocus={handleAmountFocus}
          onInput={handleAmountChanged}
          onKeyDown={handleAmountKeyPress}
          onPaste={handlePrevent}
          placeholder={amountStrings.placeholder}
          ref={amountInputRef}
          step={amountStrings.step}
          style={{
            fontSize: sizingData?.sizes[sizingData?.layout]?.input?.fontSize,
            height: sizingData?.sizes[sizingData?.layout]?.input?.height,
            width: sizingData?.sizes[sizingData?.layout]?.input?.width,
          }}
          type={amountStrings.type}
          value={amount}
        />
      </div>
      <div
        className={[amountStrings.className, isError ? 'error' : ''].join(' ')}
        id="give-amount-input-sizer"
        ref={amountInputSizerRef}
      >
        {amount || '0'}
      </div>
    </div>
  );
}
