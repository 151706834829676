/* eslint-disable no-param-reassign */
/**
 * @module GivingContext
 */
import React from 'react';
import {
  getCampuses,
  getFrequencies,
  getFunds,
  getGeolocation,
  getHistory,
  getHistoryDownloads,
  getPaymentMethods,
  getStatus,
  preflight,
} from '../api/giving';
import defaultCampuses from '../assets/json/campuses.json';
import defaultFrequencies from '../assets/json/frequencies.json';
import defaultFunds from '../assets/json/funds.json';
import defaultGeolocationData from '../assets/json/geolocation.json';
import defaultSmartPayProviders from '../assets/json/smart-pay-providers.json';
import defaultSmartPayUserData from '../assets/json/smart-pay-user-data.json';
import useAuth from '../hooks/useAuth';
import {
  APP_CONFIG,
  CALCULATION_METHODS,
  LOCAL_STORAGE_KEYS,
  STATUS_TYPES,
  STRINGS,
  calculateDaysOffset,
  calculateDistanceFromGeolocationCoordinates,
  logError,
} from '../utils';

/**
 * @typedef {object} GivingContext
 * @property {Function} addFormError - Function to add a field-specific form error.
 * @property {ApiStatus} apiStatus - Api Status data object with keys for `error`, and boolean values `isAvailable`, `isMaintenance`, and `isOutage`.
 * @property {Function} calculateGiveButtonData - Function to calculate and set give button data.
 * @property {Function} calculateUserCampusProximity - Function to calculate and set proximity of user to each campus location.
 * @property {Array<Campus>} campuses - List of Campus objects.
 * @property {Function} checkPaymentDate - Convenience function to check the stored payment date, and update to the current time if stored value is in the past.
 * @property {Frequency} defaultFrequency - Default Frequency object.
 * @property {Fund} defaultFund - Default Fund object.
 * @property {string} defaultPaymentDate - Default payment date.
 * @property {Function} fetchGivingData - Function to retrieve giving data from the Life.Church Giving API. When run for an unauthenticated user, only publicly-available endpoints and data will be used and set.
 * @property {Function} fetchGivingHistory - Function to retrieve giving history data.
 * @property {Function} fetchGivingHistoryDownloads - Function to retrieve giving history downloads.
 * @property {string} financialConnectionsSessionToken - Client token for financial connections session.
 * @property {Object<FormError>} formErrors - Object of field-attributed Form error objects.
 * @property {FormStatus} formStatus - Object of form status values to help track submission status.
 * @property {Array<Frequency>} frequencies - List of Frequency objects.
 * @property {Array<Fund>} funds - List of Fund objects.
 * @property {LcGeolocationData} geolocationData - Geolocation data object from Life.Church geolocation service.
 * @property {Function} getDefaultPaymentMethod - Function to retrieve the default payment method.
 * @property {Function} getFund - Function to retrieve the specified fund by fund name.
 * @property {Function} getPreferredCampus - Function to retrieve the preferred campus location.
 * @property {Function} getProcessingBibleVerse - Function to retrieve and return a Bible verse to display for the Processing screen.
 * @property {GiveButtonData} giveButtonData - Data object to populate and style the Give button.
 * @property {History} history - History object for the specified year (Default: Current year).
 * @property {History} historyDownloads - History downloads data for the specified year (Default: Current year).
 * @property {boolean} isAndroid - Boolean flag denoting whether or not navigator userAgent is Android device.
 * @property {boolean} isApiDataRetrieved - Boolean flag denoting status of API data retrieval.
 * @property {boolean} isError - Boolean flag denoting error status of giving data integration.
 * @property {boolean} isIdle - Boolean flag denoting idle status of giving data integration.
 * @property {boolean} isLoading - Boolean flag denoting loading status of giving data integration.
 * @property {boolean} isRecurringFrequency - Boolean flag denoting gift frequency recurring status.
 * @property {boolean} isSuccess - Boolean flag denoting success status of giving data integration.
 * @property {Array<PaymentMethod>} paymentMethods - List of PaymentMethod objects.
 * @property {Function} redirectToMainPage - Function to redirect to main Giving app.
 * @property {Function} removeFormError - Function to remove field-specific form error.
 * @property {Function} resetFormErrors - Function to reset form errors.
 * @property {Function} resetFormStatus - Function to reset all form status information.
 * @property {Function} resetUserGivingData - Function to reset user-selected giving data.
 * @property {SmartPayProvider} smartPayProviderData - Data object of Smart Pay provider availability.
 * @property {SmartPayUser} smartPayUserData - Data object of Smart Pay user data.
 * @property {Function} storeFinancialConnectionsSessionToken - Function to store financial connections session token.
 * @property {Function} storeSmartPayProviderData - Function to store Smart Pay provider data.
 * @property {Function} storeSmartPayUserData - Function to store Smart Pay user data for logged out giving.
 * @property {Function} storeUserGivingData - Function to store user-selected giving data.
 * @property {Function} storeUserPosition - Convenience function to store user location position.
 * @property {Date} today - Date object for current date.
 * @property {Function} updateFormStatus - Function to update form status.
 * @property {UserGivingData} userGivingData - Object of user giving data.
 * @property {GeolocationPosition} userPosition - The Position object attributed to the user.
 */

export const GivingContext = React.createContext({
  addFormError: null,
  apiStatus: null,
  calculateGiveButtonData: null,
  calculateUserCampusProximity: null,
  campuses: null,
  checkPaymentDate: null,
  defaultFrequency: null,
  defaultFund: null,
  defaultPaymentDate: null,
  fetchGivingData: null,
  fetchGivingHistory: null,
  fetchGivingHistoryDownloads: null,
  financialConnectionsSessionToken: null,
  formErrors: null,
  formStatus: null,
  frequencies: null,
  funds: null,
  geolocationData: null,
  getDefaultPaymentMethod: null,
  getFund: null,
  getPreferredCampus: null,
  getProcessingBibleVerse: null,
  giveButtonData: null,
  history: null,
  historyDownloads: null,
  isAndroid: false,
  isApiDataRetrieved: false,
  isError: false,
  isIdle: true,
  isLoading: false,
  isRecurringFrequency: false,
  isSuccess: true,
  paymentMethods: null,
  redirectToMainPage: null,
  removeFormError: null,
  resetFormErrors: null,
  resetFormStatus: null,
  resetUserGivingData: null,
  smartPayProviderData: null,
  smartPayUserData: null,
  storeFinancialConnectionsSessionToken: null,
  storeSmartPayProviderData: null,
  storeSmartPayUserData: null,
  storeUserGivingData: null,
  storeUserPosition: null,
  today: null,
  updateFormStatus: null,
  userGivingData: null,
  userPosition: null,
});
GivingContext.displayName = 'GivingContext';

/**
 * React Context Provider for Life.Church Web Giving.
 *
 * @param {object} props - The component props object.
 * @param {React.ReactNode} props.children - The React children.
 *
 * @returns {React.ReactElement} The Giving Context provider.
 */
export function GivingProvider({ children, ...props }) {
  const { getAccessToken } = useAuth();

  const { giveButton: giveButtonStrings } = STRINGS;

  const [apiStatus, setApiStatus] = React.useState({
    error: null,
    isAvailable: true,
    isMaintenance: false,
    isOutage: false,
  });
  const [isApiDataRetrieved, setIsApiDataRetrieved] = React.useState(false);
  const [campuses, setCampuses] = React.useState(defaultCampuses.data);
  const [defaultFrequency, setDefaultFrequency] = React.useState(
    defaultFrequencies.data[0],
  );
  const [defaultFund, setDefaultFund] = React.useState(defaultFunds.data[0]);
  const [defaultPaymentDate, setDefaultPaymentDate] = React.useState(
    Math.floor(new Date().getTime() / 1000),
  );
  const [
    financialConnectionsSessionToken,
    setFinancialConnectionsSessionToken,
  ] = React.useState(null);
  const [formErrors, setFormErrors] = React.useState({});
  const [formStatus, setFormStatus] = React.useState({});
  const [frequencies, setFrequencies] = React.useState(defaultFrequencies.data);
  const [funds, setFunds] = React.useState(defaultFunds.data);
  const [geolocationData, setGeolocationData] = React.useState(
    defaultGeolocationData,
  );
  const [giveButtonData, setGiveButtonData] = React.useState(
    giveButtonStrings.unauthenticated.amountMissing,
  );
  const [history, setHistory] = React.useState(null);
  const [historyDownloads, setHistoryDownloads] = React.useState(null);
  const [isRecurringFrequency, setIsRecurringFrequency] = React.useState(false);
  const [paymentMethods, setPaymentMethods] = React.useState([]);
  const [smartPayProviderData, setSmartPayProviderData] = React.useState(
    defaultSmartPayProviders,
  );
  const [smartPayUserData, setSmartPayUserData] = React.useState(
    defaultSmartPayUserData,
  );
  const [status, setStatus] = React.useState(STATUS_TYPES.idle);
  const [today, setToday] = React.useState(new Date());
  const [isAndroid, setIsAndroid] = React.useState(
    navigator.userAgent.toLowerCase().indexOf('android') > -1,
  );

  const [userGivingData, setUserGivingData] = React.useState({
    paymentDate: new Date().getTime() / 1000,
    ...JSON.parse(
      window.localStorage.getItem(LOCAL_STORAGE_KEYS.userGivingData),
    ),
  });

  const [userPosition, setUserPosition] = React.useState(null);

  const isLoading = status === STATUS_TYPES.pending;
  const isIdle = status === STATUS_TYPES.idle;
  const isSuccess = status === STATUS_TYPES.resolved;
  const isError = status === STATUS_TYPES.rejected;

  /**
   * Convenience function to redirect to main Giving app.
   *
   * @param path - Optional window location path, including any and all query params, hash values, etc., applied to the base URL.
   *
   * @returns {Function} Invocation of window.open() method.
   */
  const redirectToMainPage = React.useCallback((path) => {
    return window.open(`${window.location.origin}${path || '/give/'}`, '_self');
  }, []);

  /**
   * Convenience function to fetch the giving history. If no year is specified,
   * the current year is used.
   *
   * @param {object} params - The function params object.
   * @param {number|string} [params.year] - Optional year for which to retrieve history. If omitted, the most recent year's data is returned.
   *
   * @returns {History} History data object.
   */
  const fetchGivingHistory = React.useCallback(
    async ({ year } = {}) => {
      const accessToken = getAccessToken();
      if (!accessToken) {
        return null;
      }
      try {
        const givingHistoryResponse = await getHistory({ accessToken, year });
        setHistory(givingHistoryResponse?.data || null);
        return givingHistoryResponse?.data || null;
      } catch (error) {
        logError(error);
        return null;
      }
    },
    [getAccessToken],
  );

  /**
   * Convenience function to fetch the giving history downloads, which returns a
   * link to the specified year's history PDF. If no year is specified in the
   * function params object, the current year is used.
   *
   * @param {object} params - The function params object.
   * @param {number|string} [params.year] - Optional year for which to retrieve history. If omitted, the most recent year's data is returned.
   *
   * @returns {History} List of History data objects.
   */
  const fetchGivingHistoryDownloads = React.useCallback(
    async ({ year } = {}) => {
      const accessToken = getAccessToken();
      if (!accessToken) {
        return null;
      }
      try {
        const givingHistoryDownloadsResponse = await getHistoryDownloads({
          accessToken,
          year,
        });
        setHistoryDownloads(givingHistoryDownloadsResponse?.data || null);
        return givingHistoryDownloadsResponse?.data || null;
      } catch (error) {
        logError(error);
        return null;
      }
    },
    [getAccessToken],
  );

  /**
   * Convenience function to set the financial connections session token.
   *
   * @param {string} token - The token value to store in the giving context.
   */
  const storeFinancialConnectionsSessionToken = React.useCallback((token) => {
    setFinancialConnectionsSessionToken(token);
  }, []);

  /**
   * Convenience function to set Smart Pay user data to state.
   *
   * @param {SmartPayUser} data - Data object of Smart Pay provider availability.
   */
  const storeSmartPayUserData = React.useCallback((data) => {
    setSmartPayUserData(data);
  }, []);

  /**
   * Convenience function to set Smart Pay provider data to state.
   *
   * @param {SmartPayProvider} data - Data object of Smart Pay provider availability.
   */
  const storeSmartPayProviderData = React.useCallback((data) => {
    setSmartPayProviderData(data);
  }, []);

  /**
   * Convenience function to calculate and set give button data. The button data
   * object is based on rules and scenarios of stored user giving data, and the
   * logical conditional flow is derived from project design and documentation.
   */
  const calculateGiveButtonData = React.useCallback(() => {
    const accessToken = getAccessToken();
    let storedUserData = {};
    if (window.localStorage.getItem(LOCAL_STORAGE_KEYS.userGivingData)) {
      storedUserData = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.userGivingData),
      );
    }
    const updatedCleansedData = {
      ...userGivingData,
      ...storedUserData,
    };
    let buttonData =
      giveButtonStrings.unauthenticated.amountFilled.unauthenticated;
    let useAuthStrings = false;
    if (
      (APP_CONFIG.includeLoggedOutDonation && userGivingData?.paymentMethod) ||
      accessToken
    ) {
      useAuthStrings = true;
    }
    if (useAuthStrings) {
      const { authenticated: authBtnStrings } = giveButtonStrings;
      const donationDate = new Date(
        updatedCleansedData?.paymentDate
          ? updatedCleansedData.paymentDate * 1000
          : null,
      );
      const donationDateOffset = calculateDaysOffset({
        endDate: donationDate,
        startDate: today,
      });
      if (
        !updatedCleansedData?.amount ||
        parseInt(updatedCleansedData?.amount, 10) <= 0 ||
        Number.isNaN(parseInt(updatedCleansedData?.amount, 10))
      ) {
        buttonData = authBtnStrings.amountMissing;
      } else if (!updatedCleansedData?.campus) {
        buttonData = authBtnStrings.amountFilled.location;
      } else if (!updatedCleansedData?.fund) {
        buttonData = authBtnStrings.amountFilled.fund;
      } else if (!updatedCleansedData?.paymentMethod) {
        buttonData = authBtnStrings.amountFilled.paymentMethod;
      } else if (
        !updatedCleansedData?.frequency ||
        updatedCleansedData?.frequency?.attributes?.name
          .toLowerCase()
          .replace(' ', '') === 'onetime'
      ) {
        if (donationDateOffset > 0) {
          buttonData = authBtnStrings.amountFilled.valid.oneTime.future;
        } else {
          buttonData = authBtnStrings.amountFilled.valid.oneTime.today;
        }
      } else if (
        updatedCleansedData?.frequency?.attributes?.name
          .toLowerCase()
          .replace(' ', '') !== 'onetime'
      ) {
        if (donationDateOffset > 0) {
          buttonData = authBtnStrings.amountFilled.valid.recurring.future;
        } else {
          buttonData = authBtnStrings.amountFilled.valid.recurring.today;
        }
      }
    } else {
      const { unauthenticated: unAuthBtnStrings } = giveButtonStrings;
      if (
        !updatedCleansedData?.amount ||
        parseInt(updatedCleansedData?.amount, 10) <= 0 ||
        Number.isNaN(parseInt(updatedCleansedData?.amount, 10))
      ) {
        buttonData = unAuthBtnStrings.amountMissing;
      } else if (!updatedCleansedData?.campus) {
        buttonData = unAuthBtnStrings.amountFilled.location;
      } else if (!updatedCleansedData?.fund) {
        buttonData = unAuthBtnStrings.amountFilled.fund;
      } else {
        buttonData = unAuthBtnStrings.amountFilled.unauthenticated;
      }
    }
    setGiveButtonData(buttonData);
    return buttonData;
  }, [giveButtonStrings, getAccessToken, today, userGivingData]); // NOSONAR

  /**
   * Convenience function to store user-set and selected giving data.
   * Note: This function filters the supplied data and only stores valid items
   * to store that are pertinent to creating a donation, and stores the data to
   * local storage and the state to be accessed via the `userGivingData` object.
   */
  const storeUserGivingData = React.useCallback(
    (data) => {
      const cleansedData =
        JSON.parse(
          window.localStorage.getItem(LOCAL_STORAGE_KEYS.userGivingData),
        ) || {};
      Object.entries(data)
        .filter((entry) =>
          [
            'actionAddPaymentMethod',
            'amount',
            'campus',
            'donation',
            'frequency',
            'fund',
            'paymentDate',
            'paymentMethod',
            'queryParams',
            'token',
            'utmParams',
          ].includes(entry[0]),
        )
        .forEach((entry) => {
          [, cleansedData[entry[0]]] = entry;
        });
      const updatedUserData = {
        ...userGivingData,
        ...cleansedData,
      };
      setUserGivingData(() => {
        return updatedUserData;
      });
      window.localStorage.setItem(
        LOCAL_STORAGE_KEYS.userGivingData,
        JSON.stringify(updatedUserData),
      );

      // Trigger give button data update.
      calculateGiveButtonData();
    },
    [calculateGiveButtonData, userGivingData],
  );

  /**
   * Convenience function to call the Life.Church Giving API to retrieve data.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after data retrieval complete.
   */
  const fetchGivingData = React.useCallback(async ({ callback } = {}) => {
    const accessToken = getAccessToken();
    setStatus(STATUS_TYPES.pending);

    const apiPromises = [
      getFrequencies(),
      getFunds(),
      getGeolocation(),
      getCampuses({ accessToken }),
      accessToken ? getPaymentMethods({ accessToken }) : null,
      accessToken ? preflight({ accessToken }) : null,
    ];

    try {
      const [
        frequenciesResponse,
        fundsResponse,
        geolocationResponse,
        campusesResponse,
        paymentMethodsResponse,
      ] = await Promise.all(apiPromises);
      setFrequencies(
        frequenciesResponse
          ? frequenciesResponse.data
          : defaultFrequencies.data,
      );
      setFunds(fundsResponse ? fundsResponse.data : defaultFunds.data);
      setGeolocationData(geolocationResponse ?? defaultGeolocationData);
      setDefaultFrequency(
        frequenciesResponse?.data.length
          ? frequenciesResponse.data[0]
          : defaultFrequencies.data[0],
      );
      setDefaultFund(
        fundsResponse?.data?.length
          ? fundsResponse.data[0]
          : defaultFunds.data[0],
      );
      setDefaultPaymentDate(Math.floor(new Date().getTime() / 1000));
      setToday(new Date());
      if (accessToken) {
        const storedPaymentMethods = paymentMethods;
        setPaymentMethods(() => {
          return [
            ...(paymentMethodsResponse?.data &&
            Array.isArray(paymentMethodsResponse.data)
              ? paymentMethodsResponse.data
              : storedPaymentMethods),
          ];
        });
      }

      // Ensure that the giving data referenced is from localStorage, as there
      // is an edge case where stored userGivingData state isn't fully updated.
      const storedUserGivingData = JSON.parse(
        window.localStorage.getItem(LOCAL_STORAGE_KEYS.userGivingData),
      );

      // Set default payment method, if user is authenticated and has a default
      // payment method set in the returned Payment Methods response. Also set a
      // fallback default payment method if user is authenticated and no default
      // is found, ultimately setting to null if no methods exist.
      /**
       * Rules for payment method setting:
       * 1. Stored payment method (so long as it's in payment methods list).
       * 2. `is_default` from payment_methods response (if user authenticated).
       * 3. First item in payment_methods response (if user authenticated).
       * 4. Null.
       */
      const defaultPaymentMethod =
        accessToken &&
        storedUserGivingData &&
        !storedUserGivingData?.paymentMethod
          ? paymentMethodsResponse?.data?.filter((paymentMethod) => {
              return paymentMethod?.attributes?.is_default === true;
            })[0]
          : null;
      const fallbackDefaultPaymentMethod = paymentMethodsResponse?.data?.length
        ? paymentMethodsResponse.data[0]
        : null;
      const storedPaymentMethod =
        accessToken && storedUserGivingData
          ? storedUserGivingData?.paymentMethod
          : null;

      // See note above for logical chain of setting payment method to store.
      let finalPaymentMethodToStore = fallbackDefaultPaymentMethod;
      if (storedPaymentMethod) {
        const storedPaymentMethodFromFetchedList =
          paymentMethodsResponse?.data?.find((method) => {
            return method.id === storedPaymentMethod.id;
          });
        if (storedPaymentMethodFromFetchedList) {
          finalPaymentMethodToStore = storedPaymentMethod;
        }
      } else if (defaultPaymentMethod) {
        finalPaymentMethodToStore = defaultPaymentMethod;
      }

      const initDefaultFund = fundsResponse?.data?.length
        ? fundsResponse.data[0]
        : defaultFunds.data[0];
      const campusesResponseData = campusesResponse
        ? campusesResponse.data
        : defaultCampuses.data;
      storeUserGivingData({
        amount: storedUserGivingData?.amount ? storedUserGivingData.amount : '',
        campus: storedUserGivingData?.campus
          ? storedUserGivingData.campus
          : campusesResponseData?.filter((campus) => {
              return campus.attributes.preferred;
            })[0],
        frequency: storedUserGivingData?.frequency
          ? storedUserGivingData?.frequency
          : defaultFrequency,
        fund: storedUserGivingData?.fund
          ? storedUserGivingData.fund
          : initDefaultFund,
        paymentMethod: finalPaymentMethodToStore,
      });

      setCampuses(campusesResponseData);

      // Trigger give button data update.
      calculateGiveButtonData();

      // Update status.
      setStatus(STATUS_TYPES.idle);

      if (callback) {
        callback({ userGivingData });
      }
      // Add slight delay for smoother transition of UI loading status.
      setTimeout(() => {
        setIsApiDataRetrieved(true);
      }, 250);
    } catch (error) {
      const statusObject = {
        error,
        isAvailable: false,
        isMaintenance: true,
        isOutage: false,
      };
      setApiStatus(statusObject);
      setIsApiDataRetrieved(statusObject.isMaintenance);
      logError(error);

      // Update status.
      setStatus(STATUS_TYPES.error);

      if (callback) {
        callback({ error });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // NOSONAR

  /**
   * Convenience function to store user location position.
   *
   * @param {GeolocationPosition} position - The Position object attributed to the user.
   * @param {Function} callback - Optional callback function triggered after position is stored.
   */
  const storeUserPosition = React.useCallback((position, callback) => {
    setUserPosition(position);
    if (callback) {
      callback(position);
    }
  }, []);

  /**
   * Convenience function to calculate and store user position and proximity to campus locations.
   *
   * @param {object} params - The function params object.
   * @param {Function} [params.callback] - Optional callback function triggered after position is stored.
   * @param {Array<Campus>} params.campusList - List of Campus objects.
   * @param {GeolocationPosition} params.position - The Position object attributed to the user.
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Geolocation_API}.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates}.
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationCoordinates/accuracy}.
   */
  const calculateUserCampusProximity = React.useCallback(
    ({ callback, campusList, position }) => {
      const campusDistances = {};
      let closestProximity = null;
      let closestCampusId = null;
      Object.values(campusList).forEach((campusData) => {
        campusDistances[campusData.id] = {
          ...calculateDistanceFromGeolocationCoordinates({
            calculationMethod: CALCULATION_METHODS.cosine,
            coords1: position?.coords,
            coords2: {
              latitude: campusData?.attributes?.location?.latitude,
              longitude: campusData?.attributes?.location?.longitude,
            },
          }),
          accuracy: position?.coords?.accuracy || 0,
        };
        /**
         * Calculate whether or not the campus location is in range based on the
         * distance away (in meters) relative to which is greater: the position
         * accuracy or the predefined threshold (as stored in ENV variables).
         * Note that this uses Math.max() for figuring out whether to base the
         * calculation on position coords accuracy or predefined threshold,
         * since the accuracy is not always pinpoint-accurate for Web browsers.
         */
        const isWithinRange =
          campusDistances[campusData.id].m !== null
            ? campusDistances[campusData.id].m <
              Math.max(
                campusDistances[campusData.id].accuracy,
                APP_CONFIG.currentLocationThresholdMeters,
              )
            : false;
        // Calculate if location proximity is less than (closer to) any other
        // stored distance/proximity as stored in `closestProximity`, updating
        // that value as needed.
        if (
          isWithinRange &&
          (!closestCampusId ||
            campusDistances[campusData.id].m < closestProximity)
        ) {
          closestProximity = campusDistances[campusData.id].m;
          closestCampusId = campusData.id;
        }
        campusDistances[campusData.id].isWithinRange = false;
      });
      // Set range boolean on closest campus data object now that closest found.
      if (closestCampusId) {
        campusDistances[closestCampusId].isWithinRange = true;
      }
      position.campusProximity = campusDistances;
      storeUserPosition(position, callback);
    },
    [storeUserPosition],
  );

  /**
   * Convenience function to call the Life.Church Giving API to retrieve status.
   *
   * Note: In the case of unavailability, the appropriate status object values
   * are set to `true` to ensure loaders and other UI-related elements relying
   * on that status get updated and can show proper state.
   *
   * @param {Function} callback - Optional callback function triggered after data retrieval complete.
   */
  const getApiStatus = React.useCallback(async (callback) => {
    setStatus(STATUS_TYPES.pending);

    try {
      const statusResponse = await getStatus();
      const statusObject = {
        error: null,
        isAvailable: statusResponse?.is_available,
        isMaintenance: !statusResponse?.is_available, // Opposite of isAvailable, so will be truthy if not available.
        isOutage: false,
      };
      setApiStatus(statusObject);
      setIsApiDataRetrieved(statusObject.isMaintenance);

      // Update status.
      setStatus(STATUS_TYPES.idle);

      if (callback) {
        callback(statusObject);
      }
    } catch (error) {
      const statusObject = {
        error,
        isAvailable: false,
        isMaintenance: false,
        isOutage: true,
      };
      setApiStatus(statusObject);
      setIsApiDataRetrieved(statusObject.isOutage);
      logError(error);

      // Update status.
      setStatus(STATUS_TYPES.error);

      if (callback) {
        callback(statusObject);
      }
    }
  }, []);

  /**
   * Convenience function to retrieve a Fund object from the specified name.
   *
   * @returns {Fund} The Fund object.
   */
  const getFund = React.useCallback(
    (fundName) => {
      const targetFund = funds?.filter((fund) => {
        return (
          fund?.attributes?.name?.toLowerCase() === fundName?.toLowerCase()
        );
      })[0];
      return targetFund;
    },
    [funds],
  );

  /**
   * Convenience function to retrieve the default payment method.
   *
   * @returns {PaymentMethod} The default payment method object.
   */
  const getDefaultPaymentMethod = React.useCallback(() => {
    const defaultPaymentMethod =
      paymentMethods?.find((paymentMethod) => {
        return paymentMethod?.attributes?.is_default;
      }) || null;
    return defaultPaymentMethod;
  }, [paymentMethods]);

  /**
   * Convenience function to retrieve the preferred campus location.
   *
   * @returns {Campus} The preferred campus object.
   */
  const getPreferredCampus = React.useCallback(() => {
    const preferredCampus =
      campuses?.filter((campus) => {
        return campus?.attributes?.preferred;
      })[0] || null;
    return preferredCampus;
  }, [campuses]);

  /**
   * Convenience function to return a randomly-selected Bible verse.
   *
   * @returns {BibleVerse} The Bible verse object, with keys for reference and text.
   */
  const getProcessingBibleVerse = React.useCallback(() => {
    const randomNumber = Math.floor(Math.random() * STRINGS.bibleVerses.length);
    return STRINGS.bibleVerses[randomNumber];
  }, []);

  /**
   * Convenience function to set/store a new form error.
   *
   * @param {string} field - The form field attributed to the error.
   * @param {FormError} error - The FormError data object to store.
   */
  const addFormError = React.useCallback((field, error) => {
    setFormErrors((prevErrors) => {
      return {
        ...prevErrors,
        [field]: error,
      };
    });
  }, []);

  /**
   * Convenience function to remove a form error.
   *
   * @param {string} field - The form field attributed to the error.
   */
  const removeFormError = React.useCallback(
    (field) => {
      if (formErrors[field]) {
        const newFormErrors = {};
        Object.entries(formErrors).forEach(([f, e]) => {
          if (field !== f) {
            newFormErrors[f] = e;
          }
        });
        setFormErrors(newFormErrors);
      }
    },
    [formErrors],
  );

  /**
   * Convenience function to check the stored payment date, and update to the
   * current time if stored value is in the past.
   */
  const checkPaymentDate = React.useCallback(() => {
    const now = new Date().getTime() / 1000;
    const storedPaymentDate = userGivingData?.paymentDate;
    if (!storedPaymentDate || now > storedPaymentDate) {
      storeUserGivingData({
        paymentDate: now,
      });
    }
  }, [userGivingData?.paymentDate, storeUserGivingData]);

  /**
   * Convenience function to reset form errors.
   */
  const resetFormErrors = React.useCallback(() => {
    setFormErrors({});
  }, []);

  /**
   * Convenience function to reset form status.
   */
  const resetFormStatus = React.useCallback(() => {
    setFormStatus({
      hasSubmitted: false,
      submitSuccess: false,
    });
  }, []);

  /**
   * Convenience function to update the specified form status attribute with the specified value.
   */
  const updateFormStatus = React.useCallback((attribute, value) => {
    setFormStatus((prevStatus) => {
      return {
        ...prevStatus,
        [attribute]: value,
      };
    });
  }, []);

  /**
   * Convenience function to reset user-set and selected giving data.
   */
  const resetUserGivingData = React.useCallback(() => {
    storeUserGivingData({
      amount: '',
      donation: null,
      frequency: defaultFrequency,
      paymentDate: new Date().getTime() / 1000,
      token: null,
    });
    storeSmartPayUserData({
      ...defaultSmartPayUserData,
    });
  }, [defaultFrequency, storeSmartPayUserData, storeUserGivingData]);

  /**
   * Single-run convenience effect to get Giving API status and fetch giving
   * data on successful status check and return.
   */
  React.useEffect(() => {
    if (status !== STATUS_TYPES.pending) {
      getApiStatus((statusObject) => {
        if (statusObject.isAvailable) {
          setIsAndroid(
            navigator.userAgent.toLowerCase().indexOf('android') > -1,
          );
          checkPaymentDate();
          fetchGivingData();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run convenience effect to set recurring frequency.
   */
  React.useEffect(() => {
    setIsRecurringFrequency(
      userGivingData?.frequency?.attributes?.name
        .toLowerCase()
        .replace(' ', '') !== 'onetime',
    );
  }, [userGivingData]);

  const value = React.useMemo(
    () => ({
      addFormError,
      apiStatus,
      calculateGiveButtonData,
      calculateUserCampusProximity,
      campuses,
      checkPaymentDate,
      defaultFrequency,
      defaultFund,
      defaultPaymentDate,
      fetchGivingData,
      fetchGivingHistory,
      fetchGivingHistoryDownloads,
      financialConnectionsSessionToken,
      formErrors,
      formStatus,
      frequencies,
      funds,
      geolocationData,
      getDefaultPaymentMethod,
      getFund,
      getPreferredCampus,
      getProcessingBibleVerse,
      giveButtonData,
      history,
      historyDownloads,
      isAndroid,
      isApiDataRetrieved,
      isError,
      isIdle,
      isLoading,
      isRecurringFrequency,
      isSuccess,
      paymentMethods,
      redirectToMainPage,
      removeFormError,
      resetFormErrors,
      resetFormStatus,
      resetUserGivingData,
      smartPayProviderData,
      smartPayUserData,
      storeFinancialConnectionsSessionToken,
      storeSmartPayProviderData,
      storeSmartPayUserData,
      storeUserGivingData,
      storeUserPosition,
      today,
      updateFormStatus,
      userGivingData,
      userPosition,
    }),
    [
      addFormError,
      apiStatus,
      calculateGiveButtonData,
      calculateUserCampusProximity,
      campuses,
      checkPaymentDate,
      defaultFrequency,
      defaultFund,
      defaultPaymentDate,
      fetchGivingData,
      fetchGivingHistory,
      fetchGivingHistoryDownloads,
      financialConnectionsSessionToken,
      formErrors,
      formStatus,
      frequencies,
      funds,
      geolocationData,
      getDefaultPaymentMethod,
      getFund,
      getPreferredCampus,
      getProcessingBibleVerse,
      giveButtonData,
      history,
      historyDownloads,
      isAndroid,
      isApiDataRetrieved,
      isError,
      isIdle,
      isLoading,
      isRecurringFrequency,
      isSuccess,
      paymentMethods,
      redirectToMainPage,
      removeFormError,
      resetFormErrors,
      resetFormStatus,
      resetUserGivingData,
      smartPayProviderData,
      smartPayUserData,
      storeFinancialConnectionsSessionToken,
      storeSmartPayProviderData,
      storeSmartPayUserData,
      storeUserGivingData,
      storeUserPosition,
      today,
      updateFormStatus,
      userGivingData,
      userPosition,
    ],
  );

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <GivingContext.Provider value={value} {...props}>
      {children}
    </GivingContext.Provider>
  );
}
