/**
 * @module Amount
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import SmoothCollapse from 'react-smooth-collapse';
import useGiving from '../../hooks/useGiving';
import { SIZE_MAP, formatWithCommas } from '../../utils';
import { AmountInput } from '../Inputs/AmountInput';
import SelectLabel from '../SelectLabel';
import './Amount.scss';

/**
 * Represents a container for an Amount input field and fund selection component.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.isModalOpen] - Boolean flag denoting whether corresponding modal is open.
 * @param {Function} [props.onFundSelectClick] - Handler function for fund select component click event.
 * @param {Function} [props.onSubmit] - Handler function for input to trigger parent form submit.
 *
 * @returns {React.ReactElement} The Amount component.
 */
export function Amount({ isModalOpen, onFundSelectClick, onSubmit }) {
  const { userGivingData } = useGiving();
  const [isInputFocus, setIsInputFocus] = React.useState(false);
  const [isInputValid, setIsInputValid] = React.useState(true);
  const amountWrapRef = React.useRef();
  const [amountWrapData, setAmountWrapData] = React.useState({
    isChecked: false,
    layout: 'horizontal',
    sizes: {
      horizontal: {
        fundSelector: `0px`,
        input: {
          maxWidth: `100px`,
          width: `80px`,
        },
        inputSizer: `80px`,
        label: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.label}px`,
          height: `${SIZE_MAP.amount.maxFontSize.label + 16}px`,
          marginTop: `0px`,
        },
        labelAndInput: {
          left: '16px',
          marginLeft: '0',
          maxWidth: `100px`,
          width: `100px`,
        },
      },
      vertical: {
        fundSelector: `100px`,
        input: {
          maxWidth: `80px`,
          width: `80px`,
        },
        inputSizer: `80px`,
        label: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.label}px`,
          height: `${SIZE_MAP.amount.maxFontSize.label + 16}px`,
          marginTop: `0px`,
        },
        labelAndInput: {
          left: '50%',
          marginLeft: `-50px`,
          maxWidth: `100px`,
          width: `100px`,
        },
      },
    },
    style: {
      label: {
        paddingTop: 0,
      },
    },
    width: null,
  });

  /**
   * Handler function for amount input blur event.
   */
  function handleInputBlur() {
    /* istanbul ignore next */
    setIsInputFocus(false);
  }

  /**
   * Handler function for amount input focus event.
   */
  function handleInputFocus() {
    /* istanbul ignore next */
    setIsInputFocus(true);
  }

  /**
   * Handler function for amount input validation check.
   *
   * @param {boolean} isValid - Boolean flag denoting validity of input field.
   */
  function handleInputValidCheck(isValid) {
    /* istanbul ignore next */
    setIsInputValid(isValid);
  }

  /**
   * Convenience function to calculate and return sizes, dimensions, and values
   * for the various Amount UI elements.
   *
   * @param {object} data - Object of amount wrapper data, sizes, and values.
   *
   * @returns {object} Object with elements object and sizes object of sizes, dimensions, and values.
   */
  /* istanbul ignore next */
  function calculateSizes(data = amountWrapData) {
    const elements = {
      amountWrapper: document.getElementById('amount-wrapper'),
      input: document.getElementById('give-amount-input'),
      inputSizer: document.getElementById('give-amount-input-sizer'),
      label: document.getElementById('amount-input-label'),
      selectLabel:
        document.getElementById('amount-fund-selector-horizontal') ||
        document.getElementById('amount-fund-selector-vertical'),
    };

    const sideMargin = data.margin.left + data.margin.right;
    const sidePadding = data.padding.left + data.padding.right;
    const fundSelectorWidth = elements.selectLabel?.clientWidth;
    const inputSizerWidth = elements.inputSizer?.clientWidth
      ? elements.inputSizer.clientWidth + 16
      : 48; // Slightly larger than single-wide digit of input for blank scenarios.
    const labelWidth = elements.label?.clientWidth;
    const amountWrapperWidth = elements.amountWrapper?.clientWidth
      ? elements.amountWrapper.clientWidth - sideMargin - sidePadding
      : 100 - sideMargin - sidePadding;

    /**
     * Calculate various amount values, lengths, and sizes.
     * Note: Using Math.min() to ensure any edge cases that would cause there to
     * be values larger than 1 decimal, 2 decimal digits, or 7 dollar digits are
     * accounted for, caught, and properly handled.
     */
    const amountValue = elements.inputSizer?.innerHTML || '';
    const amountDollarsAndCents = amountValue.split('.');
    const amountData = {
      cents: parseInt(amountDollarsAndCents[1], 10) || 0,
      dollars: parseInt(amountDollarsAndCents[0].replace(/,/g, ''), 10),
      numDecimalDigits: Math.min(
        amountDollarsAndCents[1] ? amountDollarsAndCents[1].length : 0,
        2,
      ),
      numDecimals: Math.min(amountDollarsAndCents.length - 1, 1),
      numDollarDigits: Math.min(
        amountDollarsAndCents[0].replace(/,/g, '').length,
        7,
      ),
      value: amountValue,
      widthScale: 1,
    };
    amountData.maxWidth =
      SIZE_MAP.amount[amountData.numDollarDigits][amountData.numDecimals][
        amountData.numDecimalDigits
      ];
    // If actual character (client) width of numbers is less than max allowed,
    // reset the maxWidth value to be the character (client) width.
    if (
      elements.inputSizer?.clientWidth &&
      elements.inputSizer.clientWidth < amountData?.maxWidth
    ) {
      amountData.maxWidth = elements?.inputSizer?.clientWidth;
    }

    const sizes = {
      horizontal: {
        amountData: { ...amountData }, // Spread to avoid auto-update when calculating scale below.
        fundSelector: `${fundSelectorWidth}px`,
        input: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.input}px`,
          height: `${SIZE_MAP.amount.maxFontSize.input + 16}px`,
          maxWidth: `${amountWrapperWidth - labelWidth - fundSelectorWidth}px`,
          width: `${inputSizerWidth}px`,
        },
        inputSizer: `${inputSizerWidth}px`,
        label: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.label}px`,
          marginTop: `0px`,
        },
        labelAndInput: {
          left: '16px',
          marginLeft: '-16px',
          maxWidth: `${amountWrapperWidth - fundSelectorWidth}px`,
          width: `${inputSizerWidth + labelWidth}px`,
        },
      },
      vertical: {
        amountData: { ...amountData }, // Spread to avoid auto-update when calculating scale below.
        fundSelector: `${fundSelectorWidth}px`,
        input: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.input}px`,
          height: `${SIZE_MAP.amount.maxFontSize.input + 16}px`,
          maxWidth: `${amountWrapperWidth - labelWidth}px`,
          width: `${Math.min(
            inputSizerWidth,
            amountWrapperWidth - labelWidth,
          )}px`,
        },
        inputSizer: `${inputSizerWidth}px`,
        label: {
          fontSize: `${SIZE_MAP.amount.maxFontSize.label}px`,
          marginTop: `0px`,
        },
        labelAndInput: {
          left: '50%',
          marginLeft: `-${Math.min(
            inputSizerWidth + labelWidth,
            amountWrapperWidth,
          )}px`,
          maxWidth: `${amountWrapperWidth}px`,
          width: `${Math.min(
            inputSizerWidth + labelWidth,
            amountWrapperWidth,
          )}px`,
        },
      },
    };

    // Determine and set layout direction.
    const layout =
      inputSizerWidth + labelWidth >=
      amountWrapperWidth - fundSelectorWidth - 16 // 16px extra for buffer.
        ? 'vertical'
        : 'horizontal';

    // If vertical layout, calculate to check whether or not font size needs to
    // be adjusted to fit the entirety of the amount.
    if (layout === 'vertical') {
      if (amountWrapperWidth - labelWidth < amountData.maxWidth) {
        amountData.widthScale =
          (amountWrapperWidth - labelWidth) / amountData.maxWidth;
        sizes.vertical.input.fontSize = `${Math.floor(
          SIZE_MAP.amount.maxFontSize.input * amountData.widthScale,
        )}px`;
        sizes.vertical.label.fontSize = `${Math.floor(
          SIZE_MAP.amount.maxFontSize.label * amountData.widthScale,
        )}px`;
        sizes.vertical.label.marginTop = `-${
          parseInt(amountWrapData.style.label.paddingTop, 10) -
          parseInt(amountWrapData.style.label.paddingTop, 10) *
            amountData.widthScale
        }px`;
        sizes.vertical.amountData.widthScale = amountData.widthScale;
        sizes.vertical.input.width = `${parseInt(
          sizes.vertical.input.maxWidth,
          10,
        )}px`;
        sizes.vertical.labelAndInput.marginLeft = `-${amountWrapperWidth}px`;
        sizes.vertical.labelAndInput.width = amountWrapperWidth;
      }
    }
    return { ...data, elements, layout, sizes };
  }

  /**
   * Handler function for amount input field change event.
   */
  function handleAmountInputChange() {
    if (amountWrapData.isChecked) {
      setAmountWrapData(calculateSizes(amountWrapData));
    }
  }

  /**
   * Handler function for window resize event.
   */
  /* istanbul ignore next */
  function handleWindowResize() {
    setAmountWrapData(calculateSizes(amountWrapData));
  }

  /**
   * Single-run convenience effect to determine element layout.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    if (amountWrapRef?.current && !amountWrapData?.isChecked) {
      const labelElement = document.getElementById('amount-input-label');
      const amtWrapStyle = window.getComputedStyle
        ? getComputedStyle(amountWrapRef?.current, null)
        : amountWrapRef?.current?.currentStyle;
      const currencyLabelStyle = window.getComputedStyle
        ? getComputedStyle(labelElement)
        : labelElement?.currentStyle;
      const checkedData = {
        isChecked: true,
        margin: {
          left: parseInt(amtWrapStyle?.marginLeft, 10) || 0,
          right: parseInt(amtWrapStyle?.marginRight, 10) || 0,
        },
        padding: {
          left: parseInt(amtWrapStyle?.paddingLeft, 10) || 0,
          right: parseInt(amtWrapStyle?.paddingRight, 10) || 0,
        },
        style: {
          amountWrap: amtWrapStyle,
          label: currencyLabelStyle,
        },
      };
      setAmountWrapData(
        calculateSizes({
          ...amountWrapData,
          ...checkedData,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountWrapData.isChecked, amountWrapRef]);

  /**
   * Convenience effect triggering layout calculation for Fund selection change.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (amountWrapData?.isChecked) {
      setAmountWrapData(calculateSizes(amountWrapData));
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userGivingData?.fund]);

  /**
   * Single-run convenience effect to set window resize listener.
   */
  React.useEffect(() => {
    if (amountWrapData?.isChecked) {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
      return () => window.removeEventListener('resize', handleWindowResize);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountWrapData.isChecked]);

  return (
    <div
      className={[
        'amount-wrapper',
        /* istanbul ignore next */
        isInputFocus ? 'focus' : '',
        /* istanbul ignore next */
        isInputValid ? 'green' : 'error',
        /* istanbul ignore next */
        amountWrapData?.layout || '',
      ].join(' ')}
      data-testid="amount"
      id="amount-wrapper"
      ref={amountWrapRef}
    >
      <div className="row">
        <div
          className="giving-amount-height"
          style={{
            fontSize: `${
              amountWrapData?.sizes[amountWrapData?.layout]?.input?.fontSize
            }`,
            height: `${
              amountWrapData?.sizes[amountWrapData?.layout]?.input?.height
            }`,
            opacity: '0',
            pointerEvents: 'none',
          }}
        >
          {formatWithCommas(userGivingData?.amount || '0')}
        </div>
        <AmountInput
          id="give-amount-input"
          onBlur={handleInputBlur}
          onChange={handleAmountInputChange}
          onFocus={handleInputFocus}
          onSubmit={onSubmit}
          onValidCheck={handleInputValidCheck}
          sizingData={amountWrapData}
        />
        <SelectLabel
          className={['horizontal', isModalOpen ? 'open' : 'closed'].join(' ')}
          id="amount-fund-selector-horizontal"
          label={userGivingData?.fund?.attributes?.name}
          onClick={onFundSelectClick}
        />
      </div>
      <SmoothCollapse expanded={amountWrapData?.layout === 'vertical'}>
        <div className="row">
          <SelectLabel
            className={['vertical', isModalOpen ? 'open' : 'closed'].join(' ')}
            id="amount-fund-selector-vertical"
            label={userGivingData?.fund?.attributes?.name}
            onClick={onFundSelectClick}
          />
        </div>
      </SmoothCollapse>
    </div>
  );
}
