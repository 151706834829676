/**
 * @module PaymentMethodModal
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import {
  ButtonVariants,
  StyledButton,
} from '@io/web-tools-io/dist/components/global/Buttons/StyledButton';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { Log } from '@io/web-tools-io/dist/utils/helpers/browserLogger';
import { publicIpv4 } from 'public-ip';
import {
  createPaymentMethod,
  getFinancialConnectionsSession,
  verifyResponse,
} from '../../../api/giving';
import { AddBankAccountScreen } from './screens/AddBankAccountScreen';
import { AddCardScreen } from './screens/AddCardScreen';
import { AddPaymentMethodScreen } from './screens/AddPaymentMethodScreen';
import { SelectPaymentMethodScreen } from './screens/SelectPaymentMethodScreen';
import { Loading } from '../../../views/Loading';
// Important: Import BaseModal and ModalHeader separately to avoid dependency cycle.
import { BaseModal } from '../BaseModal';
import { ModalHeader } from '../ModalHeader';
import useAuth from '../../../hooks/useAuth';
import useGiving from '../../../hooks/useGiving';
import {
  ANALYTICS,
  APP_CONFIG,
  MODAL_MODES,
  PAYPAL_PAYMENT_METHOD_OBJECT,
  SMART_PAY_PROVIDERS,
  STRINGS,
  logError,
} from '../../../utils';
import '../Modal.scss';
import './PaymentMethod.scss';

const paymentMethodStrings = STRINGS.modals.paymentMethod;

/**
 * Represents a convenience wrapper component that returns the screen component related to the specified type.
 *
 * @param {object} props - The component props object.
 * @param {object} props.captchaElement - Google reCAPTCHA elements to render on the form (contains keys and corresponding HTML elements).
 * @param {string} [props.className] - Optional class name to attribute to the component returned.
 * @param {Function} [props.onBankFormFieldChange] - Handler function for Bank form field change events.
 * @param {Function} [props.onStripeError] - Handler function for Stripe error event.
 * @param {Function} [props.onStripeSuccess] - Handler function for Stripe success event.
 * @param {object} [props.references] - Object of React Ref objects to attribute to bank and card screens.
 * @param {boolean} [props.submitNewBankForm] - Boolean flag denoting when to trigger form submit for new bank account.
 * @param {'bank'|'card'} [props.type] - The component type to return.
 *
 * @returns {React.ReactElement} The AddNewScreen component, which represents either a `AddBankAccountScreen` or `AddCardScreen` component.
 */
/* istanbul ignore next */
function AddNewScreen({
  captchaElement,
  className,
  onBankFormFieldChange,
  onStripeError,
  onStripeSuccess,
  references,
  type,
}) {
  switch (type) {
    case 'bank':
      return (
        <AddBankAccountScreen
          captchaElement={captchaElement.bank}
          className={className}
          onFormFieldChange={onBankFormFieldChange}
          onStripeError={onStripeError}
          onStripeSuccess={onStripeSuccess}
          reference={references?.bank}
        />
      );
    case 'card':
      return (
        <AddCardScreen
          captchaElement={captchaElement.card}
          className={className}
          onStripeError={onStripeError}
          onStripeSuccess={onStripeSuccess}
          reference={references?.card}
        />
      );
    default:
      return null;
  }
}

/**
 * Represents the modal to show a user's Payment Methods.
 *
 * @param {object} props - The component props object.
 * @param {boolean} [props.includePayPal] - Boolean flag denoting whether or not to include PayPal button and logic (default: true).
 * @param {boolean} props.isOpen - Boolean flag denoting the visibility of the modal.
 * @param {('add'|'new-bank'|'new-card'|'select')} [props.mode] - The mode (state) of the modal (Default: 'select').
 * @param {Function} props.onClose - Handler function for modal close event.
 * @param {'braintree'|'express_checkout'} props.payPalMode - The mode/version of PayPal integration to use.
 *
 * @returns {React.ReactElement} The PaymentMethodModal component.
 */
export function PaymentMethodModal({
  includePayPal,
  isOpen,
  mode,
  onClose,
  payPalMode,
}) {
  const { getAccessToken, isAuthenticated, logIn } = useAuth();
  const {
    fetchGivingData,
    financialConnectionsSessionToken,
    paymentMethods,
    smartPayProviderData,
    storeFinancialConnectionsSessionToken,
    storeUserGivingData,
    userGivingData,
  } = useGiving();
  const stripe = useStripe();
  const [modalMode, setModalMode] = React.useState(MODAL_MODES.select);
  const [includeSelectMode, setIncludeSelectMode] = React.useState(
    paymentMethods?.length,
  );
  const [isValidBankForm, setIsValidBankForm] = React.useState(false);
  const addNewBankRef = React.createRef();
  const addNewCardRef = React.createRef();

  const [captchaElement] = React.useState({
    bank: (
      <div
        className="g-recaptcha new-bank"
        data-callback="onReCaptchaCallback"
        data-error-callback="onReCaptchaErrorOrExpired"
        data-expired-callback="onReCaptchaErrorOrExpired"
        data-sitekey={process.env.RECAPTCHA_SITE_KEY}
        id="id_grecaptcha_new-bank"
      ></div>
    ),
    card: (
      <div
        className="g-recaptcha new-card"
        data-callback="onReCaptchaCallback"
        data-error-callback="onReCaptchaErrorOrExpired"
        data-expired-callback="onReCaptchaErrorOrExpired"
        data-sitekey={process.env.RECAPTCHA_SITE_KEY}
        id="id_grecaptcha_new-card"
      ></div>
    ),
  });
  const [captchaToken, setCaptchaToken] = React.useState(null);
  const [isNetworkRequestProcessing, setIsNetworkRequestProcessing] =
    React.useState(false);

  // Convenience state to store new payment type, either 'bank' or 'card'.
  const [newPaymentType, setNewPaymentType] = React.useState('card');

  /**
   * Ensure specified mode is one supported by the modal.
   */
  React.useEffect(() => {
    if (Object.values(MODAL_MODES).includes(mode)) {
      setModalMode(mode);
    }
  }, [mode]);

  /**
   * Convenience effect to determine whether or not to enable including mode for
   * selecting payment method.
   *
   * Note: The logic of the conditional check here sets the select and modal
   * modes based on the availability/length of user payment methods and whether
   * or not any smart pay providers are available. It also checks for the
   * presence of the stored action for adding payment method, and sets the
   * modal mode to "add" with select include mode set to true, and clears out
   * the stored action for adding payment method to ensure it won't re-load on
   * a regular page reload or modal close/re-open scenario. Also, there is a
   * very necessary check for user isAuthenticated, to ensure the "Add" mode is
   * not shown for unauthenticated users, as is an edge case if a user triggers
   * the login flow from clicking "Add" and then users the browser Back button
   * to come back to the site without actually authenticating.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      !paymentMethods?.length &&
      Object.values(smartPayProviderData).every((item) => item === false)
    ) {
      setIncludeSelectMode(false);
      if (isAuthenticated) {
        setModalMode(MODAL_MODES.add);
      }
    } else if (userGivingData?.actionAddPaymentMethod) {
      setIncludeSelectMode(true);
      if (isAuthenticated) {
        setModalMode(MODAL_MODES.add);
      }
      storeUserGivingData({
        actionAddPaymentMethod: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethods, smartPayProviderData]);

  /**
   * Handler function for click event of Manually Add Bank Account as payment method.
   */
  /* istanbul ignore next */
  function handleAddBankAccountManualClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.bankAccountManual,
        screen: ANALYTICS.screens.names.addPaymentMethod,
      },
    });
    setNewPaymentType('bank');
    setModalMode(MODAL_MODES.newBank);
  }

  /**
   * Handler function for Stripe token generation success event.
   *
   * @param {object} params - The function params object.
   * @param {Array<StripeFinancialConnectionsSessionAccount>} params.connectedAccounts - The Stripe financial connection accounts data object array.
   * @param {number} params.current - The current account number in the array of accounts.
   * @param {number} params.total - The total account number in the array of accounts.
   */
  /* istanbul ignore next */
  async function saveFinancialConnectionsAccounts({
    connectedAccounts,
    current,
    total,
  }) {
    // Stripe token successfully generated. Store in user giving data.
    const connectedAccountData = connectedAccounts[current];

    // Submit create payment method API call.
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: connectedAccountData?.id,
      });

      /* istanbul ignore next */
      callSegmentTrack({
        event: ANALYTICS.events.paymentMethodCreated,
        properties: {
          context: ANALYTICS.contexts.oneScreen,
          value: result?.data?.attributes?.payment_method_type,
        },
      });

      // If there are more accounts to save, call self to keep recursion going.
      if (current < total - 1) {
        saveFinancialConnectionsAccounts({
          connectedAccounts,
          current: current + 1,
          total,
        });
      } else {
        // Re-fetch user giving data and set back to select mode.
        fetchGivingData({
          callback: ({ error }) => {
            if (!error) {
              Log.log(
                'Financial connection payment method(s) successfully added, with last being set to payment method.',
              );
              storeUserGivingData({
                ...userGivingData,
                paymentMethod: result?.data,
              });
            }
            setModalMode(MODAL_MODES.select);
            setIsNetworkRequestProcessing(false);
          },
        });
      }
    } catch (error) {
      setIsNetworkRequestProcessing(false);
      logError(error);

      /**
       * Calling logError() again, but with false values for bugsnag and
       * browserConsole so it only displays for user.
       */
      logError(
        new Error(STRINGS.modals.paymentMethod.add.financialConnectionError),
        {
          browserConsole: false,
          bugsnag: false,
          windowAlert: true,
        },
      );

      // Re-fetch user giving data and set back to select mode.
      fetchGivingData({
        callback: () => {
          setModalMode(MODAL_MODES.select);
          setIsNetworkRequestProcessing(false);
        },
      });
    }
  }

  /**
   * Function to collect and trigger Stripe financial connections account flow.
   *
   * @param {string} fcSession - The financial connections session token.
   *
   * @see {@link https://stripe.com/docs/financial-connections/other-data-powered-products?platform=web}.
   */
  /* istanbul ignore next */
  async function collectStripeFinancialConnectionsAccounts(fcSession) {
    try {
      const financialConnectionsSessionResult =
        await stripe.collectFinancialConnectionsAccounts({
          clientSecret: financialConnectionsSessionToken ?? fcSession,
        });
      /**
       * If the list of accounts is more than zero, kick off the recursive
       * function to save each of the connected accounts as payment methods in
       * the Giving API.
       */
      if (
        financialConnectionsSessionResult?.financialConnectionsSession?.accounts
          ?.length
      ) {
        setIsNetworkRequestProcessing(true);
        saveFinancialConnectionsAccounts({
          connectedAccounts:
            financialConnectionsSessionResult?.financialConnectionsSession
              ?.accounts,
          current: 0,
          total:
            financialConnectionsSessionResult?.financialConnectionsSession
              ?.accounts?.length,
        });
      } else {
        setIsNetworkRequestProcessing(false);
      }
    } catch (error) {
      logError(error);
      setIsNetworkRequestProcessing(false);
    }
  }

  /**
   * Handler function for click event of Sign in to Add Bank Account as payment method.
   */
  /* istanbul ignore next */
  async function handleAddBankAccountSignInClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.bankAccountSignIn,
        screen: ANALYTICS.screens.names.addPaymentMethod,
      },
    });
    try {
      const financialConnectionsSessionResponse =
        await getFinancialConnectionsSession({
          accessToken: getAccessToken(),
        });
      const fcSession = financialConnectionsSessionResponse?.fc_session;
      storeFinancialConnectionsSessionToken(fcSession);
      collectStripeFinancialConnectionsAccounts(fcSession);
    } catch (error) {
      logError(error);
    }
  }

  /**
   * Handler function for click event of Add payment method.
   */
  /* istanbul ignore next */
  function handleAddCardClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.creditDebitCard,
        screen: ANALYTICS.screens.names.addPaymentMethod,
      },
    });
    setNewPaymentType('card');
    setModalMode(MODAL_MODES.newCard);
  }

  /**
   * Handler function for click event of Add payment method.
   */
  /* istanbul ignore next */
  function handleAddPaymentMethodClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.add,
        screen: ANALYTICS.screens.names.givingPaymentMethod,
      },
    });
    // If user is authenticated, set to "add" mode. Otherwise, store the action
    // for adding payment method in user giving data and trigger login flow.
    if (isAuthenticated) {
      setModalMode(MODAL_MODES.add);
    } else {
      storeUserGivingData({
        actionAddPaymentMethod: true,
      });
      logIn();
    }
  }

  /**
   * Handler function for click event of Add payment method.
   */
  /* istanbul ignore next */
  function handlePayPalClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.payPal,
        screen: ANALYTICS.screens.names.addPaymentMethod,
      },
    });

    storeUserGivingData({
      ...userGivingData,
      paymentMethod: PAYPAL_PAYMENT_METHOD_OBJECT,
    });

    setModalMode(MODAL_MODES.select);
  }

  /**
   * Handler function for bank form field change events.
   *
   * @param {object} data - Form data, with key for `errors`, containing errors (or null) for accountNumber and routingNumber.
   */
  /* istanbul ignore next */
  function handleBankFormFieldChange(data) {
    let formHasErrors = false;
    Object.values(data.errors).forEach((value) => {
      if (value) {
        formHasErrors = true;
      }
    });
    setIsValidBankForm(formHasErrors);
  }

  /**
   * Handler function for modal close event.
   */
  /* istanbul ignore next */
  function handleModalClose() {
    if (!isNetworkRequestProcessing) {
      switch (modalMode) {
        case MODAL_MODES.add:
          if (includeSelectMode) {
            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.back,
                screen: ANALYTICS.screens.names.addPaymentMethod,
              },
            });
            setModalMode(MODAL_MODES.select);
          } else {
            /* istanbul ignore next */
            callSegmentTrack({
              event: ANALYTICS.events.buttonTap,
              properties: {
                context: ANALYTICS.contexts.oneScreen,
                label: ANALYTICS.labels.close,
                screen: ANALYTICS.screens.names.givingPaymentMethod,
              },
            });
            onClose();
          }
          break;
        case MODAL_MODES.newBank:
        case MODAL_MODES.newCard:
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.buttonTap,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.back,
              screen:
                modalMode === MODAL_MODES.newBank
                  ? ANALYTICS.screens.names.addBankAccount
                  : ANALYTICS.screens.names.addCreditCard,
            },
          });
          setModalMode(MODAL_MODES.add);
          break;
        default:
          /* istanbul ignore next */
          callSegmentTrack({
            event: ANALYTICS.events.buttonTap,
            properties: {
              context: ANALYTICS.contexts.oneScreen,
              label: ANALYTICS.labels.close,
              screen: ANALYTICS.screens.names.givingPaymentMethod,
            },
          });
          onClose();
          break;
      }
    }
  }

  /**
   * Handler function for modal footer click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleModalFooterButton(event) {
    if (event) {
      event.preventDefault();
    }
    switch (modalMode) {
      case MODAL_MODES.newBank:
        /* istanbul ignore next */
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            screen: ANALYTICS.screens.names.addBankAccount,
          },
        });
        setIsNetworkRequestProcessing(true);
        if (addNewBankRef?.current) {
          addNewBankRef.current.click();
        }
        break;
      case MODAL_MODES.newCard:
        /* istanbul ignore next */
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            screen: ANALYTICS.screens.names.addCreditCard,
          },
        });
        setIsNetworkRequestProcessing(true);
        if (addNewCardRef?.current) {
          addNewCardRef.current.click();
        }
        break;
      default:
        /* istanbul ignore next */
        callSegmentTrack({
          event: ANALYTICS.events.buttonTap,
          properties: {
            context: ANALYTICS.contexts.oneScreen,
            label: ANALYTICS.labels.done,
            screen: ANALYTICS.screens.names.givingPaymentMethod,
          },
        });
        onClose();
        break;
    }
  }

  /**
   * Handler function for Stripe token generation error event.
   *
   * @param {Error} error - The event Error object.
   *
   * @see {@link https://stripe.com/docs/api/errors}.
   */
  /* istanbul ignore next */
  function handleStripeError(error) {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.paymentMethodError,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        error: error.message,
        screen: ANALYTICS.screens.names.addPaymentMethod,
      },
    });

    setIsNetworkRequestProcessing(false);
    logError(error);

    // Calling again, but with false values for bugsnag and browserConsole so it
    // only displays for user.
    logError(new Error(STRINGS.modals.paymentMethod.add.errorHints.invalid), {
      browserConsole: false,
      bugsnag: false,
      windowAlert: true,
    });
  }

  /**
   * Handler function for Stripe token generation success event.
   *
   * @param {StripeToken} token - The Stripe token object.
   */
  /* istanbul ignore next */
  async function handleStripeSuccess(token) {
    // Stripe token successfully generated. Store in user giving data.
    storeUserGivingData({
      ...userGivingData,
      token,
    });

    // Submit create payment method API call.
    try {
      const result = await createPaymentMethod({
        accessToken: getAccessToken(),
        paymentToken: token?.id,
      });

      /**
       * Note: Two separate events, one for payment created, one for giving
       * value updated to track changed payment method.
       */
      /* istanbul ignore next */
      callSegmentTrack({
        event: ANALYTICS.events.paymentMethodCreated,
        properties: {
          context: ANALYTICS.contexts.oneScreen,
          value: result?.data?.attributes?.payment_method_type,
        },
      });

      /* istanbul ignore next */
      callSegmentTrack({
        event: ANALYTICS.events.givingValueUpdated,
        properties: {
          context: ANALYTICS.contexts.oneScreen,
          label: ANALYTICS.labels.paymentMethod,
          value: result?.data?.id,
        },
      });

      // Get the new payment method, re-fetch user giving data, set new method.
      fetchGivingData({
        callback: ({ error }) => {
          if (!error) {
            storeUserGivingData({
              ...userGivingData,
              paymentMethod: result?.data,
            });
          }
          setModalMode(MODAL_MODES.select);
          setIsNetworkRequestProcessing(false);
        },
      });
    } catch (error) {
      setIsNetworkRequestProcessing(false);
      logError(error);
    }
  }

  /**
   * Convenience function to reset state-stored reCAPTCHA token and trigger it
   * to be reset at the window level.
   */
  /* istanbul ignore next */
  function resetCaptcha() {
    setCaptchaToken(null);
    window.resetCaptcha();
  }

  /**
   * Verification function for reCAPTCHA. If failure, reset to force the user
   * to try again.
   */
  /* istanbul ignore next */
  const verifyCaptcha = React.useCallback(async () => {
    try {
      const remoteIp = await publicIpv4();
      const reCaptchaResponse = await verifyResponse({
        remoteIp,
        response: window.reCaptchaToken,
        siteKey: process.env.RECAPTCHA_SITE_KEY,
        type: APP_CONFIG.reCaptchaVersion,
      });
      if (!reCaptchaResponse?.success) {
        resetCaptcha();
      }
    } catch (error) {
      logError(error);
      resetCaptcha();
    }
  }, []);

  /**
   * Convenience effect to initialize reCAPTCHA, when on an appropriate mode.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    if (
      window.isReCaptchaLoaded &&
      [MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode)
    ) {
      window.initReCaptcha(modalMode);
    }
  }, [modalMode]);

  /**
   * Handler function for Google reCAPTCHA events.
   *
   * @param {Event} event - The Event object.
   */
  /* istanbul ignore next */
  function handleReCaptchaEvent(event) {
    if (event) {
      switch (event.detail) {
        case 'callback':
          setCaptchaToken(window.reCaptchaToken);
          verifyCaptcha();
          break;
        case 'errorOrExpired':
          setCaptchaToken(null);
          break;
        case 'reset':
          setCaptchaToken(null);
          break;
        default:
          break;
      }
    }
  }

  /**
   * Single-run convenience effect to add reCAPTCHA event listeners.
   */
  /* istanbul ignore next */
  React.useEffect(() => {
    window.addEventListener('onReCaptchaEvent', handleReCaptchaEvent);
    return () => {
      window.removeEventListener('onReCaptchaEvent', handleReCaptchaEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Single-run effect to trigger analytics event.
   */
  React.useEffect(() => {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.selectorPresented,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        count: paymentMethods?.length,
        label: ANALYTICS.labels.givingPaymentMethod,
        value: Object.keys(SMART_PAY_PROVIDERS).includes(
          userGivingData?.paymentMethod?.attributes?.payment_method_type,
        )
          ? SMART_PAY_PROVIDERS[
              userGivingData?.paymentMethod?.attributes?.payment_method_type
            ].attributes?.display_label
          : userGivingData?.paymentMethod?.attributes?.payment_type,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Convenience variables to store class names for the main parent content div.
  const contentClassName = 'animatable-content payment-methods thirds';
  let contentPlacementClass = 'active-1';
  let modalHeaderClassName = '';

  /* istanbul ignore next */
  if (modalMode === MODAL_MODES.add) {
    contentPlacementClass = 'active-2';
    if (includeSelectMode) {
      modalHeaderClassName = 'modal-header-mode-back';
    }
  } else if ([MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode)) {
    contentPlacementClass = 'active-3';
    modalHeaderClassName = 'modal-header-mode-back';
  }

  return (
    <BaseModal
      content={
        <>
          <div
            className={[contentClassName, contentPlacementClass].join(' ')}
            data-testid="payment-method-modal"
          >
            <SelectPaymentMethodScreen paymentMethods={paymentMethods} />
            <AddPaymentMethodScreen
              includePayPal={includePayPal}
              onAddBankAccountManualClick={handleAddBankAccountManualClick}
              onAddBankAccountSignInClick={handleAddBankAccountSignInClick}
              onAddCardClick={handleAddCardClick}
              onPayPalClick={handlePayPalClick}
              payPalMode={payPalMode}
            />
            <AddNewScreen
              captchaElement={captchaElement}
              className="form"
              onBankFormFieldChange={handleBankFormFieldChange}
              onStripeError={handleStripeError}
              onStripeSuccess={handleStripeSuccess}
              references={{
                bank: addNewBankRef,
                card: addNewCardRef,
              }}
              type={newPaymentType}
            />
          </div>
          {
            /* istanbul ignore next */ isNetworkRequestProcessing ? (
              <div className="loading-wrapper">
                <Loading />
              </div>
            ) : null
          }
        </>
      }
      contentClassName={['pt-none', 'animatable'].join(' ')}
      footer={
        modalMode !== MODAL_MODES.add ? (
          <StyledButton
            className="full-width ml-0 mr-0"
            disabled={
              /* istanbul ignore next */
              isNetworkRequestProcessing ||
              ([MODAL_MODES.newBank, MODAL_MODES.newCard].includes(modalMode) &&
                !captchaToken) ||
              (modalMode === MODAL_MODES.newBank && isValidBankForm)
            }
            onClick={handleModalFooterButton}
            variant={ButtonVariants.primary}
          >
            {
              /* istanbul ignore next */ isNetworkRequestProcessing ? (
                <div className="circular loader"></div>
              ) : (
                STRINGS.labels.done
              )
            }
          </StyledButton>
        ) : null
      }
      header={
        <ModalHeader
          className={modalHeaderClassName}
          endButton={
            modalMode === MODAL_MODES.select ? (
              <button
                data-testid="add-payment-method-button"
                onClick={handleAddPaymentMethodClick}
              >
                {paymentMethodStrings.add.labels.add}
              </button>
            ) : null
          }
          onCloseClick={handleModalClose}
          title={paymentMethodStrings[modalMode].title}
        />
      }
      isOpen={isOpen}
      onClose={handleModalClose}
    />
  );
}
