/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
/**
 * @module Footer
 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { callSegmentTrack } from '@io/web-tools-io/dist/utils/helpers/analytics';
import { ANALYTICS, STRINGS } from '../../utils';
import LockIcon from '../ui/LockIcon';
import './Footer.scss';

/**
 * Represents the global Footer used for Life.Church Web Giving.
 *
 * @param {object} params - The function params object.
 * @param {boolean} params.includeConsent - Boolean flag denoting whether or not to include Consent Manager (cookie preferences) link.
 * @param {boolean} params.includeHelp - Boolean flag denoting whether or not to include Help link.
 * @param {boolean} params.includeLogOut - Boolean flag denoting whether or not to include Log Out link.
 * @param {Function} params.onConsentLinkClick - Handler function for Consent Link (cookie preferences) link click event.
 * @param {Function} params.onHelpClick - Handler function for Help Team link click event.
 * @param {Function} params.onLogOut - Handler function for Log Out click event.
 * @returns {React.ReactElement} The Footer component.
 */
export function Footer({
  includeConsent,
  includeHelp,
  includeLogOut,
  onConsentLinkClick,
  onHelpClick,
  onLogOut,
}) {
  const {
    backToLifeChurchSite: lifeChurchLinks,
    contactHelpTeam: contactLinks,
    osanoConsent: osanoLinks,
    privacyPolicy: privacyLinks,
    termsOfUse: termsLinks,
    vulnerabilityDisclosureProgram: vulnerabilityLinks,
  } = STRINGS.footer.links;

  /**
   * Handler function for Help Team link click event.
   */
  function handleContactHelpTeamClick() {
    onHelpClick();
  }

  /**
   * Handler function for Back to Life.Church site click event.
   *
   * @param {Event} event - The Event object associated with the click.
   */
  /* istanbul ignore next */
  function handleBackToLifeChurchSiteClick(event) {
    event.preventDefault();

    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: lifeChurchLinks.title,
        screen: ANALYTICS.screens.names.givingForm,
      },
    });

    window.open(lifeChurchLinks.href, '_top');
  }

  /**
   * Handler function for Privacy Policy link click event.
   */
  function handlePrivacyPolicyClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.privacyPolicy,
        screen: ANALYTICS.screens.names.givingForm,
      },
    });
  }

  /**
   * Handler function for Terms of Use link click event.
   */
  function handleTermsOfUseClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.termsOfUse,
        screen: ANALYTICS.screens.names.givingForm,
      },
    });
  }

  /**
   * Handler function for Vulnerability Disclosure Program link click event.
   */
  function handleVulnerabilityClick() {
    /* istanbul ignore next */
    callSegmentTrack({
      event: ANALYTICS.events.buttonTap,
      properties: {
        context: ANALYTICS.contexts.oneScreen,
        label: ANALYTICS.labels.vulnerabilityDisclosureProgram,
        screen: ANALYTICS.screens.names.givingForm,
      },
    });
  }

  return (
    <footer className="footer" data-testid="footer">
      <div className="primary-content">
        <LockIcon />
        <p>
          {STRINGS.footer.privacyAndSecurity}
          <br />
          <a
            alt={privacyLinks.label}
            href={privacyLinks.href}
            onClick={handlePrivacyPolicyClick}
            rel="noreferrer"
            target="_blank"
            title={privacyLinks.title}
          >
            {privacyLinks.label}
          </a>
          {' | '}
          <a
            alt={termsLinks.label}
            href={termsLinks.href}
            onClick={handleTermsOfUseClick}
            rel="noreferrer"
            target="_blank"
            title={termsLinks.title}
          >
            {termsLinks.label}
          </a>
          <br />
          <a
            alt={vulnerabilityLinks.label}
            href={vulnerabilityLinks.href}
            onClick={handleVulnerabilityClick}
            rel="noreferrer"
            target="_blank"
            title={vulnerabilityLinks.title}
          >
            {vulnerabilityLinks.label}
          </a>
          {includeConsent ? (
            <>
              <br />
              <a
                alt={osanoLinks.label}
                data-testid="consent-link"
                href={osanoLinks.href}
                onClick={onConsentLinkClick}
                title={osanoLinks.title}
              >
                {osanoLinks.label}
              </a>
            </>
          ) : null}
        </p>
      </div>
      <div className="secondary-content">
        {includeHelp ? (
          <p>
            <a
              data-testid="modal-toggle"
              href={contactLinks.href}
              onClick={handleContactHelpTeamClick}
            >
              {contactLinks.label}
            </a>
          </p>
        ) : null}
        <p>
          <a
            className="bold no-decoration"
            href={lifeChurchLinks.href}
            onClick={handleBackToLifeChurchSiteClick}
          >
            {lifeChurchLinks.label}
          </a>
        </p>
        {includeLogOut ? (
          <p>
            <a data-testid="footer-log-out" href="" onClick={onLogOut}>
              {STRINGS.general.logOut}
            </a>
          </p>
        ) : null}
      </div>
    </footer>
  );
}

export default Footer;
