/**
 * @module Typedefs
 */

/**
 * @typedef {object} Address
 * @property {string} city - City value (e.g. 'Edmond').
 * @property {string} country - Two-letter country code (e.g. 'US').
 * @property {string} postal_code - Postal/zip code (e.g. '73034').
 * @property {string} state - Two-character state code (e.g. 'OK').
 * @property {string} street - Street value (e.g. '4600 E. 2nd St.').
 */

/**
 * @typedef {object} ApiStatus
 * @property {Error} error - Error object, if encountered, for an error in retrieving API status.
 * @property {boolean} isAvailable - Boolean flag denoting availability status.
 * @property {boolean} isMaintenance - Boolean flag denoting maintenance mode status.
 * @property {boolean} isOutage - Boolean flag denoting outage mode status.
 */

/**
 * @typedef {object} AuthResult
 * @property {string} accessToken - Access token value attributes to the authentication result.
 * @property {string} appState - The app state for the authentication flow.
 * @property {number} expiresIn - The number of seconds set for an expiration on the token.
 * @property {string} idToken - ID token value attributes to the authentication result.
 * @property {object} idTokenPayload - The token payload data object.
 * @property {string} refreshToken - The refresh token for the authentication flow.
 * @property {string} scope - The scope set on the authentication request (Example: 'openid profile').
 * @property {string} state - The app state for the authentication flow.
 * @property {string} tokenType - The token type (Example: 'Bearer').
 */

/**
 * @typedef {object} BibleVerse
 * @property {string} reference - The Bible verse reference (e.g. 'Proverbs 11:25').
 * @property {string} text - The Bible verse text.
 */

/**
 * @typedef {object} CampusAttributes
 * @property {string} code - The campus code.
 * @property {string} name - The campus name.
 * @property {boolean} preferred - Boolean flag denoting preferred status.
 */

/**
 * @typedef {object} Campus
 * @property {CampusAttributes} attributes - The campus attributes object.
 * @property {string} id - The campus id.
 * @property {string} type - The campus type.
 */

/**
 * @typedef {object} CurrencyFormat
 * @property {string} display - The currency value to display.
 * @property {string} number - The number value, without the currency symbol.
 * @property {string} symbol - The currency symbol.
 * @property {('before'|'after')} symbol_placement - Placement of currency symbol.
 */

/**
 * @typedef {object} DonationDataSubscription
 * @property {boolean} active - Boolean flag denoting whether or not the subscription is active.
 * @property {number|string} financial_gateway_id - Financial gateway id.
 * @property {string} foreign_key - Unique foreign key value (e.g. 'cus_abc123').
 * @property {string} frequency - String value of the donation frequency (e.g. 'Bi-Weekly').
 * @property {number|string} gateway_schedule_id - Unique gateway schedule id value.
 * @property {number} id - Unique id value.
 * @property {string} next_payment_date - Next payment date value (e.g. '2023-01-01T00:00:00').
 * @property {object} payment_details - Data object of payment details.
 * @property {number} person_id - The user's person id value.
 * @property {string} start_date - The start date value (e.g. '2023-01-01T00:00:00').
 * @property {string} transaction_code - Unique transaction code.
 * @property {Array} transaction_details - Array of transaction details.
 */

/**
 * @typedef {object} DonationDataAttributes
 * @property {string} body - Body message with confirmation message.
 * @property {string} email - The user's email address.
 * @property {string} email_body - The email body text with email address included.
 * @property {string} email-format - Formatted string with variable placeholders included.
 * @property {string} format - Formatted string with variable placeholders included.
 * @property {DonationDataSubscription} subscription - The subscription data object.
 * @property {string} title - The title value.
 * @property {number} transaction_id - The unique transaction id.
 * @property {object} values - Data object of value variables.
 */

/**
 * @typedef {DonationDataAttributes} DonationData
 * @property {object} attributes - The donation data attributes object.
 */

/**
 * @typedef {object} ErrorLoggerOptions
 * @property {boolean} browserConsole - Boolean flag denoting whether to log the error to the browser console.
 * @property {boolean} bugsnag - Boolean flag denoting whether to track and notify with Bugsnag.
 * @property {boolean} windowAlert - Boolean flag denoting whether to call `alert` to display the Error message.
 */

/**
 * @typedef {object} FormError
 * @property {string} field - The form field name.
 * @property {string} message - The error message.
 */

/**
 * @typedef {object} FormStatus
 * @property {boolean} hasSubmitted - Boolean flag denoting whether or not the giving form has been submitted or attempted to be submitted.
 * @property {boolean} submitSuccess - Boolean flag denoting whether or not the giving form was successfully submitted.
 */

/**
 * @typedef {object} FrequencyAttributes
 * @property {string} code - The frequency code.
 * @property {string} name - The frequency name.
 */

/**
 * @typedef {object} Frequency
 * @property {FrequencyAttributes} attributes - The frequency attributes object.
 * @property {string} id - The frequency id.
 * @property {string} type - The frequency type.
 */

/**
 * @typedef {object} FundAttributes
 * @property {string} code - The fund code.
 * @property {string} description_text - The fund description.
 * @property {string} icon - The fund icon (Note: This is a URL value).
 * @property {string} name - The fund name.
 * @property {boolean} tithe - Boolean flag denoting tithe status.
 */

/**
 * @typedef {object} Fund
 * @property {FundAttributes} attributes - The fund attributes object.
 * @property {string} id - The fund id.
 * @property {string} type - The fund type.
 */

/**
 * @typedef {object} LcGeolocationData
 * @property {string} city - The city value (e.g. 'edmond').
 * @property {boolean} cpa - Boolean flag denoting a CPA (Colorado Privacy Act) location.
 * @property {string} country_code - The two-letter country code (e.g. 'US').
 * @property {string} country_name - The country name (e.g. 'united states').
 * @property {boolean} gdpr - Boolean flag denoting a GDPR (General Data Protection Regulation) location.
 * @property {string} ip - The IP address value (e.g. '68.55.100.123').
 * @property {string} latitude - The latitude value (e.g. '35.649679').
 * @property {string} longitude - The longitude value (e.g. '-97.422060').
 * @property {string} region - The region/state/territory value (e.g. 'OK').
 */

/**
 * @typedef {object} GeolocationCalculationMethod
 * @property {string} cosine - The cosine calculation method.
 * @property {string} haversine - The haversine calculation method.
 */

/**
 * @typedef {object} GeolocationCoordinates
 * @property {number} accuracy - The accuracy of position.
 * @property {object} altitude - The altitude in meters above the mean sea level.
 * @property {object} altitudeAccuracy - The altitude accuracy of position.
 * @property {object} heading - The heading as degrees clockwise from North.
 * @property {number} latitude - The latitude as a decimal number.
 * @property {number} longitude - The longitude as a decimal number.
 * @property {object} speed - The speed in meters per second.
 */

/**
 * @typedef {object} GeolocationPosition
 * @property {object} campusProximity - Data object of proximity values of user position to each campus location.
 * @property {GeolocationCoordinates} coords - The position coordinates data object.
 * @property {number} timestamp - The date/time of the response.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPosition}.
 */

/**
 * @typedef {object} GeolocationPositionError
 * @property {1|2|3} code - An unsigned short representing the error code (1 = PERMISSION_DENIED, 2 = POSITION_UNAVAILABLE, 3 = TIMEOUT).
 * @property {string} message - A human-readable string describing the details of the error.
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/GeolocationPositionError}.
 */

/**
 * @typedef {object} LatitudeLongitude
 * @property {number} latitude - The latitude as a decimal number.
 * @property {number} longitude - The longitude as a decimal number.
 */

/**
 * @typedef {object} GiveButtonData
 * @property {string} className - The class name to attribute to the button.
 * @property {string} label - The label text to use for the button.
 * @property {object} onClick - Object containing Function name ('fn'), modal id ('modal'), or submit boolean ('submit').
 */

/**
 * @typedef {object} HistoryByYear
 * @property {string} downloadUrl - The download URL for the given year history.
 * @property {Array<HistoryDonation>} entries - Array of HistoryDonation objects.
 * @property {boolean} isDownloadRetrieving - Boolean flag denoting retrieval status of download PDF.
 * @property {boolean} isRetrieved - Boolean flag denoting success status of download retrieval.
 * @property {number} retrievedTimestamp - The timestamp at which the history was last retrieved.
 * @property {number|string} year - The year for which the history includes.
 */

/**
 * @typedef {object} HistoryDonationAttributes
 * @property {number} payment_date - The epoch unix timestamp of the payment date, in seconds (e.g. 1672567411).
 * @property {string} title - The title of the campus to which the donation was made (e.g. 'Edmond').
 * @property {string} attributed_to - Name of the person to which the donation is attributed.
 * @property {string} frequency - The frequency value (e.g. 'Weekly').
 * @property {string} frequency_type - The type of frequency (e.g. 'recurring').
 * @property {string} fund - The fund name (e.g. 'Tithe').
 * @property {string} campus - The campus name (e.g. 'Edmond').
 * @property {string} currency - The currency value (e.g. 'USD').
 * @property {string} payment_amount - The amount of the donation (e.g. '42.00').
 * @property {PaymentMethod} payment_method - PaymentMethod data object.
 */

/**
 * @typedef {object} HistoryDonation
 * @property {HistoryDonationAttributes} attributes - Data object of donation attributes.
 * @property {string} id - Unique id value.
 * @property {string} type - The donation type (e.g. 'donation').
 */

/**
 * @typedef {object} History
 * @property {object} attributes - The history attributes object.
 * @property {string} id - The history id.
 * @property {object} relationships - The history relationships object.
 * @property {string} type - The history type.
 */

/**
 * @typedef {object} LocationAddress
 * @property {string} '@id' - The address id (uuid) value (e.g. 'dc9afdf4-76d2-4380-ae26-d9a5de5e6270').
 * @property {string} '@name' - The address name (e.g. 'primaryAddress').
 * @property {Array} '@nodes' - Array of address nodes.
 * @property {string} '@nodeType' - The address node type (e.g. 'mgnl:contentNode').
 * @property {string} '@path' -  The address path (e.g. '/Oklahoma/-Broadway-and-Britton/primaryAddress').
 * @property {string} cityOrTown - The address city/town value (e.g. '-Oklahoma City').
 * @property {string} 'jcr:created' - The address created data time.
 * @property {string} 'jcr:createdBy' - The address created-by value (e.g. 'system').
 * @property {string} latitude - The address latitude.
 * @property {string} longitude - The address longitude.
 * @property {boolean} 'mgnl:activationStatus' - Boolean flag denoting activation status.
 * @property {string} 'mgnl:created' - The address created date time.
 * @property {string} 'mgnl:createdBy' - The user who created the address (e.g. 'john.doe').
 * @property {string} 'mgnl:lastActivated' - The address last-activated date time.
 * @property {string} 'mgnl:lastActivatedBy' - The username who last activated the address (e.g. 'cmichael').
 * @property {string} 'mgnl:lastModified' - The address last modified date time.
 * @property {string} 'mgnl:lastModifiedBy' - User who last modified the address (e.g. 'john.doe').
 * @property {string} state - The address state abbreviation.
 * @property {string} street1 - The address street value (e.g. '-9001 N. Broadway Extension').
 * @property {string} zip - The address zip code.
 */

/**
 * @typedef {object} Location
 * @property {string} '@id' - The location id (uuid) value (e.g. '025a83bf-8785-4565-907a-b2dd0d1bfd8c').
 * @property {string} '@name' - The hyphenated location name (e.g. '-Broadway-and-Britton').
 * @property {Array} '@nodes' - Array of node values (e.g. ['eventTimes', 'hasSpecialEvent', 'phase', 'primaryAddress', 'secondaryAddress']).
 * @property {string} '@nodeType' - The node type (e.g. 'mgnl:content').
 * @property {string} '@path' - The hyphenated path value (e.g. '/Oklahoma/-Broadway-and-Britton').
 * @property {string} additionalInfo - Location additional information HTML markup.
 * @property {string} campusCode - The location campus code (e.g. 'bnb').
 * @property {string} campusImage - URL path for campus image.
 * @property {string} displayAddress - The location display address (e.g. '9001 N. Broadway Extension Oklahoma City, OK').
 * @property {string} email - The location email address.
 * @property {object} eventTimes - Data object of location event times.
 * @property {string} facebook - The location Facebook id.
 * @property {object} hasSpecialEvent - Data object of location special event.
 * @property {string} instagram - The location Instagram account (e.g. 'lifechurchbnb').
 * @property {string} 'jcr:created' - Created date time (e.g. '2022-07-05T14:49:35.208Z').
 * @property {string} 'jcr:createdBy' - Created-by value (e.g. 'system').
 * @property {boolean} 'jcr:mixinTypes' - Array of mixin types (e.g. ['mgnl:hasVersion']).
 * @property {string} latitude - The location latitude (e.g. '-97.51501023320627').
 * @property {string} legacyId - The location legacy id (e.g. '1').
 * @property {string} longitude - The location longitude (e.g. '-97.51501023320627').
 * @property {boolean} 'mgnl:activationStatus' - Boolean flag denoting activation status.
 * @property {string} 'mgnl:created' - Created date time (e.g. '2021-01-04T08:25:03.891Z').
 * @property {string} 'mgnl:createdBy' - Username who created the location (e.g. 'superuser').
 * @property {string} 'mgnl:lastActivated' - Last activated date time (e.g. '2022-07-05T14:50:51.360Z').
 * @property {string} 'mgnl:lastActivatedBy' - Username who last activated the location (e.g. 'cmichael').
 * @property {string} 'mgnl:lastActivatedVersion' - Last activated version (e.g. '1.5').
 * @property {string} 'mgnl:lastActivatedVersionCreated' - Last activated date time (e.g. '2022-07-05T14:50:23.694Z').
 * @property {string} 'mgnl:lastModified' - Last modified date time (e.g. '2022-06-28T16:00:56.494Z').
 * @property {string} 'mgnl:lastModifiedBy' - Username who last modified the location (e.g. 'john.doe').
 * @property {string} name - The location name.
 * @property {string} pastorImage - URL path for pastor image.
 * @property {string} pastorName - The pastor name.
 * @property {object} phase - Data object of location phase information.
 * @property {string} phone - The location phone number.
 * @property {LocationAddress} primaryAddress - Data object of location primary address.
 * @property {boolean} public - Boolean flag denoting public status of the location.
 * @property {LocationAddress} secondaryAddress - Data object of location secondary address.
 * @property {Array} servingOpportunities - Array of location serving opportunity objects.
 * @property {string} slug - The location slug (e.g. 'broadwayandbritton').
 * @property {Array} tag - Array of tag values.
 */

/**
 * @typedef {object} OsanoConsentManagerParams
 * @property {string} accountId - The Osano organization account id value (e.g. '16CVGXU84j0kF7gvw').
 * @property {string} configId - The Osano consent manager configuration id value (e.g. '3fecfc2b-b098-444d-a523-28288afda579').
 */

/**
 * @typedef {object} PaymentMethod
 * @property {object} attributes - The payment method attributes object.
 * @property {string} id - The payment method id.
 * @property {string} type - The payment method type.
 */

/**
 * @typedef {object} SmartPayProvider
 * @property {boolean} applePay - Boolean flag denoting whether or not Apple Pay is available.
 * @property {boolean} googlePay - Boolean flag denoting whether or not Google Pay is available.
 * @property {boolean} link - Boolean flag denoting whether or not Link is available.
 */

/**
 * @typedef {object} SmartPayUser
 * @property {Address} address - The user's address data.
 * @property {string} amount - The amount of the donation (e.g. '23.00').
 * @property {string} campus - The campus to which to attribute the donation (e.g. 'OKC').
 * @property {string} currency - The currency code value (e.g. 'USD').
 * @property {string} email - The user's email address.
 * @property {string} frequency - The frequency of the donation (e.g. '2weeks').
 * @property {string} fund - The fund to which to attribute the donation (e.g. 'Campus Giving').
 * @property {string} last4 - The last four digits of the user's credit card (e.g. '4242').
 * @property {string} name - The user's name (e.g. 'John Doe').
 * @property {number} paymentDate - The Unix timestamp of the donation (e.g. 1499878800).
 * @property {string} paymentMethod - The payment method id to which to attribute the donation (e.g. 6778).
 * @property {string} paymentToken - The payment token value (e.g. 'pm_abc123').
 * @property {string} smsPhoneNumber - The user's mobile phone number (e.g. '4156885433').
 * @property {string} tokenizationMethod - The tokenization method used (e.g. 'apple_pay').
 */

/**
 * @typedef {object} StripeBankAccount
 * @property {string} account_holder_name - The name of the person or business that owns the bank account.
 * @property {string} account_holder_type - The type of entity that holds the account. This can be either individual or company.
 * @property {string} account_type - The bank account type. This can only be checking or savings in most countries. In Japan, this can only be futsu or toza.
 * @property {string} bank_name - Name of the bank associated with the routing number (e.g., WELLS FARGO).
 * @property {string} country - Two-letter ISO code representing the country the bank account is located in.
 * @property {string} currency - Three-letter ISO code for the currency paid out to the bank account.
 * @property {string} fingerprint - Uniquely identifies this particular bank account. You can use this attribute to check whether two bank accounts are the same.
 * @property {string} id - Unique identifier for the object.
 * @property {string} last4 - The last four digits of the bank account number.
 * @property {string} object - String representing the object’s type. Objects of the same type share the same value (e.g. Bank_account).
 * @property {string} routing_number - The routing transit number for the bank account.
 * @property {string} status - For bank accounts, possible values are new, validated, verified, verification_failed, or errored. A bank account that hasn’t had any activity or validation performed is new. If Stripe can determine that the bank account exists, its status will be validated. Note that there often isn’t enough information to know (e.g., for smaller credit unions), and the validation is not always run. If customer bank account verification has succeeded, the bank account status will be verified. If the verification failed for any reason, such as microdeposit failure, the status will be verification_failed. If a transfer sent to this bank account fails, we’ll set the status to errored and will not continue to send transfers until the bank details are updated. For external accounts, possible values are new and errored. Validations aren’t run against external accounts because they’re only used for payouts. This means the other statuses don’t apply. If a transfer fails, the status is set to errored and transfers are stopped until account details are updated.
 *
 * @see {@link https://stripe.com/docs/api/tokens/object#token_object-bank_account}.
 */

/**
 * @typedef {object} StripeCard
 * @property {string} address_city - City/District/Suburb/Town/Village.
 * @property {string} address_country - Billing address country, if provided when creating card.
 * @property {string} address_line1 - If address_line1 was provided, results of the check: pass, fail, unavailable, or unchecked.
 * @property {string} address_line1_check - The check value for the address line 1 value of the associated address.
 * @property {string} address_line2 - Address line 2 (Apartment/Suite/Unit/Building).
 * @property {string} address_state - State/County/Province/Region.
 * @property {string} address_zip - ZIP or postal code.
 * @property {string} address_zip_check - If address_zip was provided, results of the check: pass, fail, unavailable, or unchecked.
 * @property {string} brand - Card brand. Can be American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
 * @property {string} country - Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you’ve collected.
 * @property {string} cvc_check - If a CVC was provided, results of the check: pass, fail, unavailable, or unchecked. A result of unchecked indicates that CVC was provided but hasn’t been checked yet. Checks are typically performed when attaching a card to a Customer object, or when creating a charge. For more details, see Check if a card is valid without a charge.
 * @property {string} currency - Three-letter ISO currency code, in lowercase. Must be a supported currency.
 * @property {string} dynamic_last4 - (For tokenized numbers only.) The last four digits of the device account number.
 * @property {number} exp_month - Two-digit number representing the card’s expiration month.
 * @property {number} exp_year - Four-digit number representing the card’s expiration year.
 * @property {string} fingerprint - Uniquely identifies this particular card number. You can use this attribute to check whether two customers who’ve signed up with you are using the same card number, for example. For payment methods that tokenize card information (Apple Pay, Google Pay), the tokenized number might be provided instead of the underlying card number.
 * @property {string} funding - Card funding type. Can be credit, debit, prepaid, or unknown.
 * @property {string} id - Unique identifier for the object.
 * @property {string} last4 - The last four digits of the card.
 * @property {object} metadata - Set of key-value pairs that you can attach to an object. This can be useful for storing additional information about the object in a structured format.
 * @property {string} name - Cardholder name.
 * @property {string} object - String representing the object’s type. Objects of the same type share the same value (e.g. 'card').
 * @property {string} tokenization_method - If the card number is tokenized, this is the method that was used. Can be android_pay (includes Google Pay), apple_pay, masterpass, visa_checkout, or null.
 *
 * @see {@link https://stripe.com/docs/api/tokens/object#token_object-card}.
 */

/**
 * @typedef {object} StripeSmartPayEvent
 * @property {Function} complete - Function to trigger completion flow.
 * @property {string} methodName - The smart pay method type name (e.g. 'apple-pay' or 'google-pay').
 * @property {string} payerEmail - The payer email address.
 * @property {string} payerName - The payer name.
 * @property {string} payerPhone - The payer phone number.
 * @property {object} shippingAddress - The payer shipping address.
 * @property {object|string} shippingOption - The shipping option.
 * @property {StripeSmartPayToken} token - The event token data object.
 * @property {string} walletName - The wallet name (e.g. 'applePay' or 'googlePay').
 */

/**
 * @typedef {object} StripeSmartPayWallet
 * @property {object} [apple_pay] - The Apple Pay wallet object.
 * @property {object} [google_pay] - The Google Pay wallet object.
 * @property {'apple_pay'|'google_pay'} type - The wallet type.
 */

/**
 * @typedef {object} StripeSmartPayToken
 * @property {StripeSmartPayTokenCard} card - The token card data object.
 * @property {string} client_ip - The client IP address.
 * @property {number|string} created - The created date timestamp value (e.g. 1681409623).
 * @property {string} id - The unique token ID value.
 * @property {boolean} livemode - Has the value true if the object exists in live mode or the value false if the object exists in test mode.
 * @property {string} object - Object type (e.g. 'token').
 * @property {string} type - The token type (e.g. 'card').
 * @property {boolean} used - Boolean flag denoting whether or not the token has been used.
 */

/**
 * @typedef {object} StripeSmartPayTokenCard
 * @property {string} address_city - City/District/Suburb/Town/Village.
 * @property {string} address_country - Billing address country, if provided when creating card.
 * @property {string} address_line1 - If address_line1 was provided, results of the check: pass, fail, unavailable, or unchecked.
 * @property {string} address_line1_check - The check value for the address line 1 value of the associated address.
 * @property {string} address_line2 - Address line 2 (Apartment/Suite/Unit/Building).
 * @property {string} address_state - State/County/Province/Region.
 * @property {string} address_zip - ZIP or postal code.
 * @property {string} address_zip_check - If address_zip was provided, results of the check: pass, fail, unavailable, or unchecked.
 * @property {string} brand - Card brand. Can be American Express, Diners Club, Discover, JCB, MasterCard, UnionPay, Visa, or Unknown.
 * @property {string} country - Two-letter ISO code representing the country of the card. You could use this attribute to get a sense of the international breakdown of cards you’ve collected.
 * @property {string} cvc_check - If a CVC was provided, results of the check: pass, fail, unavailable, or unchecked. A result of unchecked indicates that CVC was provided but hasn’t been checked yet. Checks are typically performed when attaching a card to a Customer object, or when creating a charge. For more details, see Check if a card is valid without a charge.
 * @property {string} dynamic_last4 - (For tokenized numbers only.) The last four digits of the device account number.
 * @property {number} exp_month - Two-digit number representing the card’s expiration month.
 * @property {number} exp_year - Four-digit number representing the card’s expiration year.
 * @property {string} funding - Card funding type. Can be credit, debit, prepaid, or unknown.
 * @property {string} id - Unique identifier for the object.
 * @property {string} last4 - The last four digits of the card.
 * @property {string} name - Cardholder name.
 * @property {string} object - String representing the object’s type. Objects of the same type share the same value (e.g. 'card').
 * @property {string} tokenization_method - If the card number is tokenized, this is the method that was used. Can be android_pay (includes Google Pay), apple_pay, masterpass, visa_checkout, or null.
 * @property {string} wallet - If the card number is tokenized, this is the method that was used. Can be android_pay (includes Google Pay), apple_pay, masterpass, visa_checkout, or null.
 *
 * @see {@link https://stripe.com/docs/api/tokens/object#token_object-card}.
 */

/**
 * @typedef {object} StripeToken
 * @property {StripeBankAccount} bank_account - Hash describing the bank account.
 * @property {StripeCard} card - Hash describing the card used to make the charge.
 * @property {string} client_ip - IP address of the client that generated the token.
 * @property {number} created - Time at which the object was created. Measured in seconds since the Unix epoch.
 * @property {string} id - Unique identifier for the object.
 * @property {boolean} livemode - Has the value true if the object exists in live mode or the value false if the object exists in test mode.
 * @property {string} object - String representing the object’s type. Objects of the same type share the same value (e.g. 'token').
 * @property {string} type - Type of the token: account, bank_account, card, or pii.
 * @property {boolean} used - Whether this token has already been used (tokens can be used only once).
 *
 * @see {@link https://stripe.com/docs/api/tokens/object}.
 */

/**
 * @typedef {object} StripeFinancialConnectionsSessionAccount
 * @property {string} category - Account category (e.g. 'Checking').
 * @property {string} display_name - Account display name (e.g. 'Premium Checking').
 * @property {string} id - Unique identifier for the account (e.g. 'fca_456').
 * @property {string} institution_name - Account institution name (e.g. 'Test Bank').
 * @property {string} last4 - The last four digits of the account (e.g. '4242').
 * @property {string} object - Account type (e.g. 'financial_connections.account').
 */

/**
 * @typedef {object} TitleProps
 * @property {boolean} primary - Boolean flag denoting whether the title should have additional .primary class attributed to it.
 */

/**
 * @typedef {object} AppMetadata
 * @property {string} address - User street address.
 * @property {string} address_2 - User street address (continued).
 * @property {string} campus - Campus value.
 * @property {string} campus_code - Campus code.
 * @property {string} city - User city.
 * @property {string} country - User country.
 * @property {string} date_of_birth - User date of birth.
 * @property {boolean} deleted - Boolean flag denoting whether or not deletion has occurred.
 * @property {string} deleted_on - Date/Time value of deletion.
 * @property {string} f1_barcode - Custom field value.
 * @property {string} f1_hh_id - Custom field value.
 * @property {string} f1_id - Custom field value.
 * @property {string} f1_organization_id - Custom field value.
 * @property {string} family_name - User family (last) name.
 * @property {string} former_name - User former name.
 * @property {string} gender - User gender.
 * @property {string} given_name - User given name.
 * @property {boolean} is_organization - Boolean flag denoting organization.
 * @property {string} middle_name - User middle name.
 * @property {string} organization_name - Organization name value.
 * @property {Array} phones - Array of user phone numbers.
 * @property {string} postal - User address postal code.
 * @property {string} prefix - User name prefix.
 * @property {string} rock_person_alias_id - Rock RMS person alias id.
 * @property {string} state - User address state.
 * @property {string} suffix - User name suffix.
 * @property {boolean} verification_email_sent - Boolean flag denoting status of verification email sent.
 */

/**
 * @typedef {object} UserMetadata
 * @property {string} can_email - String-based boolean value of user email preference (e.g. 'false').
 * @property {string} first_name - User first name (e.g. 'John').
 * @property {string} last_name - User last name (e.g. 'Doe').
 */

/**
 * @typedef {object} User
 * @property {string} family_name - User family (last) name (e.g. 'Doe').
 * @property {string} given_name - User given (first) name (e.g. 'John').
 * @property {AppMetadata} app_metadata [https://www.life.church/app_metadata] - App metadata object.
 * @property {number} rock_person_alias_id [https://www.life.church/rock_person_alias_id] - Rock RMS person alias id (e.g. 8675309).
 * @property {UserMetadata} user_metadata [https://www.life.church/user_metadata] - User metadata object.
 * @property {string} name - User display name (e.g. 'John Doe').
 * @property {string} nickname - User nickname value (e.g. 'john.doe').
 * @property {string} picture - URL string of user picture (e.g. 'https://s.gravatar.com/avatar/abc123').
 * @property {string} sub - Authentication user id value (e.g. 'auth0|8jj67w5c3irt0j9j8j067wci').
 * @property {string} updated_at - Date/Time value of last updated (e.g. '2023-01-01T12:00:00.000Z').
 */

/**
 * @typedef {object} UserGivingData
 * @property {string} amount - The amount value.
 * @property {Campus} campus - The campus object.
 * @property {Frequency} frequency - The frequency object.
 * @property {Fund} fund - The fund object.
 * @property {number} paymentDate - The payment process date in Unix epoch time (Example: 1676172250).
 * @property {PaymentMethod} paymentMethod - The payment method object.
 * @property {object} queryParams - Object of supported query parameters.
 * @property {StripeToken} token - The Stripe token object.
 * @property {object} utmParams - UTM parameters object.
 */

/**
 * Convenience object of types, not all-inclusive of all listed typedefs above,
 * but only exposing those most beneficial (can be updated as needed for more
 * typedefs to have their properties exposed for reference).
 */

/* istanbul ignore next */
export const TYPES = {
  user: {
    familyName: 'family_name',
    givenName: 'given_name',
    lcAppMetadata: 'https://www.life.church/app_metadata',
    lcRockPersonAliasId: 'https://www.life.church/rock_person_alias_id',
    lcUserMetadata: 'https://www.life.church/user_metadata',
    name: 'name',
    nickname: 'nickname',
    picture: 'picture',
    sub: 'sub',
    updatedAt: 'updated_at',
  },
};
